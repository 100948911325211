.WorkMaintenanceToolbar {
    height: auto;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
    padding: 0 !important;

    .toolbarWrapper {
        flex: 1;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        background: $bgGray;
        text-align: center;

        .toolbarButton {
            cursor: pointer;

            .toolbarButtonContainer {
                font-size: $font__size_small;
                padding: 8px 11px;

                &:hover {
                    background-color: $lighGray;
                }

                &.IconTextButton {
                    .disabledIcon {
                        color: $mediumGray !important;
                    }
                }

                &.disabled {
                    color: $mediumGray !important;
                    cursor: not-allowed;
                }
            }

            &.disabled {
                :hover {
                    background-color: transparent;
                }
            }
        }

        .saveButton {
            display: flex;
            flex: 1 1;
            flex-direction: row;
            justify-content: flex-end;

            .saveButtonContainer {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                button {
                    border: none;
                    display: flex;
                    white-space: nowrap;
                    align-items: center;
                    padding: 8px 11px;

                    &.disabledSaveButton {
                        background-color: transparent;
                        color: $mediumGray;
                    }

                    &.saveButton {
                        background-color: $primary-color;
                        color: $white;

                        &:hover {
                            @include gradientOneHover;
                            @include btnTransition;
                        }
                    }
                }

                .saveButtonText {
                    border: none;
                    white-space: nowrap;
                    font-size: $font__size_small;
                    text-align: center;
                    width: 100%;
                }

                button[disabled] {
                    cursor: default;
                }

                .dropdown-toggle {
                    height: 100%;
                    margin-left: 1px;

                    & + .dropdown-menu {
                        right: 0 !important;
                        left: auto !important;
                    }
                }
            }
        }

        &.fixedToolbar {
            position: fixed;
            height: auto;
            z-index: 99;
            top: 115px;
            @include shadow;
        }
    }
}

.toolbar-toggle {
    display: none;
}

@media only screen and (max-width: 950px) {
    .WorkMaintenanceToolbar {
        height: auto !important;
        position: fixed;
        z-index: 9998;
        bottom: 0;
        margin: 0;
        background: rgba(black, 0.5);
        left: 0;
        top: 0;

        .toolbarWrapper {
            display: block;
            height: auto;
            position: absolute;
            top: 120px;
            left: 20px;
            right: 20px;
            border-radius: 6px;
            @include shadow;

            .toolbarButton {

                .toolbarButtonContainer {
                    padding: 11px;
                }
            }

            .saveButton {
                display: block;
                display: block;
                padding: 11px;

                .saveButtonContainer {
                    button {
                        padding: 11px;

                        &.disabledSaveButton {
                            border: 1px solid $mediumGray;
                        }
                    }
                }
            }
        }

        &.collapsing {
            overflow: hidden;
            -webkit-transition-timing-function: ease;
            -o-transition-timing-function: ease;
            transition-timing-function: ease;
            -webkit-transition-duration: .3s;
            -o-transition-duration: .3s;
            transition-duration: .3s;
            -webkit-transition-property: visibility;
            -o-transition-property: visibility;
            transition-property: visibility;
        }
    }

    .toolbar-toggle {
        border-radius: 50px;
        top: 260px;
        display: flex;
        align-items: center;
        position: fixed;
        padding: 0;
        right: 10px;
        margin-bottom: 10px;
        background-color: $primary-color;
        background-image: none;
        border: 1px solid transparent;
        width: 42px;
        height: 42px;
        z-index: 99;
        @include shadow;

        .icon {
            color: $white;
            font-size: 22px;
            margin: auto;
        }

        &:focus {
            outline: none;
        }

        &[aria-expanded="true"] {
            z-index: 9999;
        }
    }
}

@media only screen and (min-width: 950px) {


    .closeSecondPane {
        border-radius: 50px;
        top: 260px;
        display: flex;
        align-items: center;
        padding: 0;
        right: 10px;
        margin-bottom: 10px;
        background-color: $primary-color;
        background-image: none;
        border: 1px solid transparent;
        width: 42px;
        height: 42px;
        z-index: 99;
        background-color: white;
        position: absolute;
        top: -5px;   
        @include shadow;

        .icon {
            color: $white;
            font-size: 22px;
            margin: auto;
        }

        &:focus {
            outline: none;
        }

        &[aria-expanded="true"] {
            z-index: 9999;
        }
    }
}

@media only screen and (min-width: 951px) {
    
    .WorkMaintenanceToolbar {
        display: block;
        height: auto !important;
    }

    .compactView .closed-distribution-checkbox-container{
        margin-top: -30px;
      }
    .closed-distribution-checkbox-container{
        margin-top: -52px;
      }

}

.DataIngestionToolbar {
    display: block;
    height: auto !important;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
    padding: 0 !important;

    .toolbarWrapper {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        background: $bgGray;
        text-align: center;

        .toolbarButton {
            cursor: pointer;

            .toolbarButtonContainer {
                font-size: $font__size_small;
                padding: 8px 11px;

                &:hover {
                    background-color: $lighGray;
                }

                &.IconTextButton {
                    .disabledIcon {
                        color: $mediumGray !important;
                    }
                }

                &.disabled {
                    color: $mediumGray !important;
                    cursor: not-allowed;
                }
            }

            &.disabled {
                :hover {
                    background-color: transparent;
                }
            }
        }

        .saveButton {
            display: flex;
            flex: 1 1;
            flex-direction: row;
            justify-content: flex-end;

            .saveButtonContainer {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                button {
                    border: none;
                    display: flex;
                    white-space: nowrap;
                    align-items: center;
                    padding: 8px 11px;

                    &.disabledSaveButton {
                        background-color: transparent;
                        color: $mediumGray;
                    }

                    &.saveButton {
                        background-color: $primary-color;
                        color: $white;

                        &:hover {
                            @include gradientOneHover;
                            @include btnTransition;
                        }
                    }
                }

                .saveButtonText {
                    border: none;
                    white-space: nowrap;
                    font-size: $font__size_small;
                    text-align: center;
                    width: 100%;
                }

                button[disabled] {
                    cursor: default;
                }

                .dropdown-toggle {
                    height: 100%;
                    margin-left: 1px;

                    & + .dropdown-menu {
                        right: 0 !important;
                        left: auto !important;
                    }
                }
            }
        }

        &.fixedToolbar {
            position: fixed;
            height: auto;
            z-index: 99;
            top: 115px;
            @include shadow;
        }
    }

    .toolbarWrapper {
        background: #ffffff;
    }
}
