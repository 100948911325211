$primary-color: #38429C;
$secondary-color: #2e6bcd;
$third-color: #41a1e4;
$green-color: #65c36c;
$main-background-color: #F6F7FA;

$white: #ffffff;
$lighGray: #e5e5e5;
$mediumGray: #B1B1B1;
$bgGray: #f5f5f5;
$darkGray: #3d3d3d;
$red-alerts: #cd0303;
$disabled-color: #8e8d8d;

//Alerts
$color-alert-success: #008f59;
$color-alert-danger: #ff6070;
$color-alert-warning: #ff9800;

@mixin gradientOne {
	background: #2e6bcd; /* Old browsers */
	background: -moz-linear-gradient(-45deg, #2e6bcd 12%, #5e2eab 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg, #2e6bcd 12%,#5e2eab 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, #2e6bcd 12%,#5e2eab 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2e6bcd', endColorstr='#5e2eab',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

@mixin gradientOneHover {
	background: #2e6bcd; /* Old browsers */
	background: -moz-linear-gradient(-45deg, #5e2eab 12%, #2e6bcd 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg, #5e2eab 12%,#2e6bcd 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, #5e2eab 12%, #2e6bcd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='##5e2eab', endColorstr='#2e6bcd',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}


// Text
$color__text-main: #202020;
$color__text-light: #707070;
$color__text-hover: lighten( #111, 22.5% );
$color__text-screen: #21759b;
$color__text-input: #666;
$color__text-input-focus: #111;

// Links
$color__link: #4263bf;
$color__link-visited: #4263bf;
$color__link-hover: #f47c6f;

// Borders
$color__border: #E5E5E5;
$color__border-link: #4263bf;
$color__border-link-hover: darken( $color__link, 10% );
$color__border-input: $color__border;
$color__border-abbr: #666;
