.buttonsCarouselView {
    margin: 1.5rem 0;

    .CarouselContainer {
        .CarouselContents {
            .carouselButton {
                border-width: 1px 1px 1px 0;
                border-color: $primary-color;
                border-style: solid;
                cursor: pointer;
                padding: 8px 7px;
                display: flex;
                align-items: center;
                white-space: nowrap;
            }

            &:first-of-type {
                border-left: 1px solid $primary-color;
            }

            & .activeItem {
                background-color: $primary-color;
                color: $white;
            }
        }
    }
}
