
.selectorView {
    .selectorTextDiv {
        font-size: 13px;
        font-weight: bold;
    }

    .selectorRadioButtonsDiv {
        display: flex;
        flex-direction: row;
        border: 1px solid $primary-color;
        width: 100%;
        margin-right: 5px;
        margin-top: 2%;


        .selectorRadioButtonsLeftSubDiv {
            display: flex;
            flex-direction: row;
            width: 50%;
        }

        .selectorRadioButtonsRightSubDiv {
            display: flex;
            flex-direction: row;
            width: 50%;
        }

        .selectorRadioButtonsText {
            font-size: 12px;
            text-align: center;
        }

        .selectorRadioButtonSelected {
            background-color: $primary-color;
            height: 35px;
            ;
            color: $white;
            width: 100%;
            border: none;
            padding: 8px;
        }

        .selectorRadioButtonUnSelected {
            background-color: $white;
            color: $primary-color;
            height: 35px;
            width: 100%;
            border: none;
            padding: 8px;
        }
    }
}

.selectorViewDisabled {
    .selectorTextDiv {
        font-size: 13px;
        font-weight: bold;
    }

    .selectorRadioButtonsDiv {
        display: flex;
        flex-direction: row;
        border: 1px solid $mediumGray;
        width: 100%;
        margin-right: 5px;
        margin-top: 2%;


        .selectorRadioButtonsLeftSubDiv {
            display: flex;
            flex-direction: row;
            width: 50%;
        }

        .selectorRadioButtonsRightSubDiv {
            display: flex;
            flex-direction: row;
            width: 50%;
        }

        .selectorRadioButtonsText {
            font-size: 12px;
            text-align: center;
        }

        .selectorRadioButtonSelected {
            background-color: $mediumGray;
            height: 35px;
            ;
            color: $white;
            width: 100%;
            border: none;
            padding: 8px;
        }

        .selectorRadioButtonUnSelected {
            background-color: $white;
            color: $mediumGray;
            height: 35px;
            width: 100%;
            border: none;
            padding: 8px;
        }
    }
}

.noTopMargin{
    margin-top: 0% !important;
}

    @media only screen and (max-width: 1150px) {
        .selectorsView {
            width: 100%;
            align-items: center;

            .selectorRadioButtonsDiv {
                width: 96%;
                justify-content: space-between;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .selectorRadioButtonsText {
            font-size: calc(0.4em + 1vmin);
        }
    }
