﻿.yesNoPromptView {
    margin: 10px;
    width: -webkit-fill-available;  
}

@-moz-document url-prefix() {
    .yesNoPromptView {
        width: -moz-available;
    }
}
