@charset "UTF-8";
/*
Theme Name: Portal Matching Engine
Author: Ana Villegas
Description: Global styles
Text Domain: MatchingEngine
Version: 0.1.2
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------

# Normalize -Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal https://necolas.github.io/normalize.css/


# General
# Typography
# Site
    ## Headings
    ## Sidebar Navigation


--------------------------------------------------------------*/
/* Normalize */
/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none;
}

/* General */
@font-face {
  font-family: "montserratbold";
  src: url("styles/fonts/montserrat-bold-webfont.woff2") format("woff2"), url("styles/fonts/montserrat-bold-webfont.woff") format("woff");
  font-style: normal;
}
@font-face {
  font-family: "montserratmedium";
  src: url("styles/fonts/montserrat-medium-webfont.woff2") format("woff2"), url("styles/fonts/montserrat-medium-webfont.woff") format("woff");
  font-style: normal;
}
@font-face {
  font-family: "montserratregular";
  src: url("styles/fonts/montserrat-regular-webfont.woff2") format("woff2"), url("styles/fonts/montserrat-regular-webfont.woff") format("woff");
  font-style: normal;
}
.title {
  display: block;
  font-family: "montserratbold", sans-serif;
  font-size: 20px;
  margin: 0 0 1rem;
}

.subtitle {
  display: block;
  font-family: "montserratmedium", sans-serif;
  font-size: 20px;
  margin: 2rem 0 2rem;
}

.heading {
  font-family: "montserratbold", sans-serif;
  margin: 0 0 15px;
}

h1 {
  font-size: 18px;
  font-family: "montserratmedium", sans-serif;
}

h3 {
  font-family: "montserratmedium", sans-serif;
  font-size: 16px;
}

.text-danger {
  font-size: 10px;
  margin: 5px 0 0 0;
}

/* Shadows */
body {
  background: #F6F7FA;
  padding-top: 105px;
  overflow-x: hidden;
}

.container-full {
  margin: 0 auto;
  width: 100%;
}

.flexContent {
  align-items: center;
  display: flex;
}

code {
  background-color: #F6F7FA;
  color: #38429C;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

a {
  cursor: pointer;
  color: #38429C;
}

hr {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  border-top: 1px solid #e5e5e5 !important;
}

.verticalDivider {
  height: 20px;
  border-left: 1px solid #e5e5e5 !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.button {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 12px 6px;
  height: auto !important;
  margin-top: 15px;
  letter-spacing: 0.5px;
  width: 100%;
  -webkit-transition: all 0.3s ease; /* Safari */
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.button:hover {
  -webkit-transition: all 0.3s ease; /* Safari */
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
}
.button.noMargin {
  margin-top: 0 !important;
}

.editBtn, .pageContent .refreshBtn, .pageContent .contributorGearBtn, .refreshBtn {
  border-radius: 4px;
  background-color: transparent;
  color: #3d3d3d;
}
.editBtn:hover, .pageContent .contributorGearBtn:hover, .refreshBtn:hover {
  background-color: #3d3d3d;
  color: #ffffff;
  -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  -webkit-transition: all 0.3s ease; /* Safari */
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.editBtn .glyphicon, .pageContent .contributorGearBtn .glyphicon, .refreshBtn .glyphicon {
  margin: 0 3px;
}

.position-right {
  right: -5px !important;
}

.btn-confirm,
button.btn-confirm {
  white-space: pre-line;
}

.btn-defaultPrimary,
button.btn-defaultPrimary {
  background: #2e6bcd; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #2e6bcd 12%, #5e2eab 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #2e6bcd 12%, #5e2eab 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #2e6bcd 12%, #5e2eab 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#2e6bcd", endColorstr="#5e2eab",GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  color: #ffffff;
  -webkit-transition: all 0.3s ease; /* Safari */
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.btn-defaultPrimary:hover,
button.btn-defaultPrimary:hover {
  background: #2e6bcd; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #5e2eab 12%, #2e6bcd 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #5e2eab 12%, #2e6bcd 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #5e2eab 12%, #2e6bcd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="##5e2eab", endColorstr="#2e6bcd",GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  -webkit-transition: all 0.3s ease; /* Safari */
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.btn-defaultPrimary:disabled,
button.btn-defaultPrimary:disabled {
  background: #8e8d8d;
}
.btn-defaultPrimary:disabled:hover,
button.btn-defaultPrimary:disabled:hover {
  box-shadow: none;
  -webkit-transition: all 0.3s ease; /* Safari */
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.btn-defaultSecondary,
button.btn-defaultSecondary {
  border: 1px solid #3d3d3d;
  background: transparent;
  color: #3d3d3d;
}
.btn-defaultSecondary:hover,
button.btn-defaultSecondary:hover {
  background: #3d3d3d;
  color: #ffffff;
  -webkit-transition: all 0.3s ease; /* Safari */
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.btn-defaultSecondary:disabled,
button.btn-defaultSecondary:disabled {
  border: 1px solid #8e8d8d;
  color: #8e8d8d;
}
.btn-defaultSecondary:disabled:hover,
button.btn-defaultSecondary:disabled:hover {
  background: transparent;
  box-shadow: none;
}

.splitButton .button {
  border-radius: 4px !important;
  display: inline-block;
  margin-top: 0;
  padding: 10px 8px;
  width: auto;
}
.splitButton .button.dropdown-toggle {
  width: 35px;
}
.splitButton .dropdown-menu {
  position: relative;
  float: none;
  width: auto;
  min-width: auto;
}

.mergeButton,
button.btn-defaultSecondary.mergeButton {
  display: flex;
  margin-top: 1px;
  width: 70%;
}

.shadow {
  -webkit-box-shadow: 0px 9px 27px -6px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 9px 27px -6px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 9px 27px -6px rgba(0, 0, 0, 0.4);
}

.wrapperLogin {
  position: relative;
  margin: auto;
  padding: 0 2rem;
}
.wrapperLogin .bgLogin {
  background: #2e6bcd; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #2e6bcd 12%, #5e2eab 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #2e6bcd 12%, #5e2eab 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #2e6bcd 12%, #5e2eab 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#2e6bcd", endColorstr="#5e2eab",GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.wrapperLogin .pageContent .logo {
  text-align: center;
  border-bottom: 1px solid #E5E5E5;
  padding: 2rem 15px;
}
.wrapperLogin .pageContent .logo img {
  margin: auto;
}
.wrapperLogin .pageContent h2 {
  margin: 30px auto 25px;
  font-weight: 700;
  font-size: 22px;
}

ul {
  padding-left: 35px;
}
ul li {
  margin-bottom: 6px;
}

.margin-bottomSmall {
  margin-bottom: 10px;
}

.margin-bottomMedium {
  margin-bottom: 25px;
}

.margin-topSmall {
  margin-top: 10px;
}

.margin-topMedium {
  margin-top: 25px;
}

.jobsProcessModal {
  overflow-y: auto;
  min-height: 100px;
  max-height: 600px;
}

.jobsProgressHeader {
  background-color: white;
  color: #38429C;
  display: flex;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}

.modal-dialog.codeModal {
  height: 90%;
}
.modal-dialog.codeModal .modal-content {
  height: 100%;
}
.modal-dialog.codeModal .modal-content .modal-header button {
  opacity: 1 !important;
}
.modal-dialog.codeModal .modal-content .modal-header button .icon {
  color: #38429C !important;
  opacity: 1;
}
.modal-dialog.codeModal .modal-content .modal-body {
  height: 93%;
}
.modal-dialog.codeModal .modal-content .modal-body .codeContainer {
  height: 100%;
}
.modal-dialog.codeModal .modal-content .modal-body .codeContainer .codeHeader {
  align-items: center;
  background-color: #e5e5e5;
  display: flex;
}
.modal-dialog.codeModal .modal-content .modal-body .codeContainer .codeHeader .language {
  flex-grow: 1;
  padding: 5px 8px;
}
.modal-dialog.codeModal .modal-content .modal-body .codeContainer .codeHeader .action {
  cursor: pointer;
  font-size: 12px;
  display: flex;
  padding: 5px 8px;
  align-items: center;
}
.modal-dialog.codeModal .modal-content .modal-body .codeContainer .codeHeader .action .icon {
  color: #38429C;
  font-size: 15px;
  padding: 0 5px;
}
.modal-dialog.codeModal .modal-content .modal-body .codeContainer .codeHeader .action:hover {
  background-color: #f5f5f5;
}
.modal-dialog.codeModal .modal-content .modal-body .codeContainer pre {
  background-color: #f5f5f5;
  border-radius: 0 !important;
  border: 0;
  height: 90%;
}

.copyMessage {
  position: relative;
  display: inline-block;
}
.copyMessage .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}
.copyMessage .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.copyMessage:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.dropBox {
  color: #c7c7c7;
  border-radius: 4px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 10px;
}
.dropBox .dropBoxContent {
  border-radius: 4px;
  border-style: dashed;
  padding: 60px;
  height: 100%;
  width: 100%;
  border-width: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f7f7f7;
}
.dropBox .dropBoxContent .dopBoxContent-Icon {
  font-size: 3rem;
  margin-bottom: 2rem;
}
.dropBox .dropBoxContent .dopBoxContent-Heading {
  font-family: "montserratbold", sans-serif;
  font-size: 16px;
}

/*Typography*/
html {
  font-size: 13px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #202020 !important;
  font-family: "montserratregular", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.2;
  margin: 0;
  text-rendering: optimizeLegibility;
}

button,
input,
select,
optgroup,
textarea {
  color: #202020;
  font-family: "montserratregular", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  text-rendering: optimizeLegibility;
}

input[type=text] {
  line-height: 5.5;
}

/* Content */
/*--------------------------------------------------------------
## Header
--------------------------------------------------------------*/
/* top Main nav */
header .navbar {
  border-width: 0;
}
header .navbar .topSection {
  background-color: #ffffff;
  height: 60px;
  -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
}
header .navbar .topSection .navbar-header {
  height: 60px;
}
header .navbar .topSection .navbar-header .navbar-brand {
  height: 100%;
  padding: 8px 30px 8px 20px;
}
header .navbar .topSection .navbar-header .navbar-brand img {
  height: 100%;
  width: auto;
}
header .navbar .topSection .navbar-header .navbar-toggle {
  padding: 9px 5px;
  width: 40px;
  height: 40px;
  margin-top: 10px;
  margin-right: 0px;
}
header .navbar .topSection .navbar-header .navbar-toggle .icon-bar {
  background-color: #38429C;
  height: 3px;
  width: 100%;
}
header .navbar .topSection .navbar-collapse {
  background-color: #ffffff !important;
  max-height: inherit;
  z-index: 1000;
}
header .navbar .topSection .navbar-collapse .navbar-nav {
  margin: 0 -15px;
  z-index: 1000;
}
header .navbar .topSection .navbar-collapse .navbar-nav li {
  background-color: #ffffff;
  font-size: 13px;
}
header .navbar .topSection .navbar-collapse .navbar-nav li a {
  color: #202020;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
}
header .navbar .topSection .navbar-collapse .navbar-nav li a svg {
  height: 15px;
  margin: 0 5px 0 0;
  fill: #38429C;
}
header .navbar .topSection .navbar-collapse .navbar-nav li a:hover {
  color: #38429C;
}
header .navbar .topSection .navbar-collapse .navbar-nav li a .glyphicon {
  color: #38429C;
  font-size: 16px;
  margin: 0 5px 0 0;
}
header .navbar .topSection .navbar-collapse .navbar-nav li a .icon {
  color: #38429C;
  font-size: 18px;
  margin: 0 5px 0 0;
}
header .navbar .topSection .navbar-collapse .navbar-nav li a.active {
  color: #38429C;
}
header .navbar .topSection .navbar-collapse .navbar-nav li.dropdown a {
  background-color: transparent !important;
  color: #202020;
  background-image: none !important;
  z-index: 1000;
}
header .navbar .topSection .navbar-collapse .navbar-nav li.dropdown a:hover {
  color: #38429C;
}
header .navbar .topSection .navbar-collapse .navbar-nav li.dropdown a.active {
  color: #38429C;
}
header .navbar .topSection .navbar-collapse .navbar-nav li.dropdown ul li a {
  padding: 4px 20px;
}
header .navbar .topSection .navbar-collapse .navbar-nav li.dropdown .divider {
  background-color: #e5e5e5;
}
header .navbar .topSection .navbar-collapse .navbar-nav li.dropdown:hover > .dropdown-menu {
  border-radius: 4px;
  display: block;
}
header .navbar .titleSection {
  background-color: #38429C;
  color: #ffffff;
  height: 45px;
  font-size: 16px;
  line-height: 40px;
  display: flex;
  align-items: center;
}
header .navbar .titleSection span {
  display: flex;
  align-items: center;
}
header .navbar .titleSection .glyphicon {
  margin: 0 15px 0 0;
  font-size: 24px;
}
header .navbar .titleSection .icon {
  margin: 0 15px 0 0;
  font-size: 25px;
}

@media (min-width: 1101px) {
  .navbar-header .navbar-toggle {
    display: none !important;
  }
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
}
@media only screen and (max-width: 1100px) {
  .navbar-header {
    width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    float: none !important;
  }
  .navbar-header .navbar-toggle {
    display: block !important;
  }
  .navbar-collapse {
    background-color: #ffffff !important;
    -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
    -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
    box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
    margin-right: -15px !important;
    margin-left: -15px !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    border-top: 1px solid transparent;
  }
  .navbar-collapse .navbar-nav {
    float: none !important;
  }
  .navbar-collapse .navbar-nav li {
    float: none !important;
  }
  .navbar-collapse .navbar-nav li .dropdown-menu {
    display: block !important;
    position: relative !important;
    box-shadow: none !important;
    border: none !important;
    width: 100% !important;
    padding: 0 0 15px !important;
  }
  .navbar-collapse.collapse {
    display: none !important;
    overflow-y: auto !important;
  }
  .navbar-collapse.collapse.in {
    display: block !important;
  }
}
/* Small Devices, Tablets LOGIN PAGE */
@media only screen and (max-width: 767px) {
  /* /. top Main nav */
  .wrapperLogin {
    padding: 0 1.5rem;
  }
}
.titleSection {
  padding-right: 15px;
  padding-left: 15px;
}

/*--------------------------------------------------------------
## Sidebar Navigation
--------------------------------------------------------------*/
.wrapperPageLateralmenu {
  /* Sidebar navigation */
  /* /. Sidebar navigation */
}
.wrapperPageLateralmenu .sidebarWrapper {
  width: 265px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  transition: all 0.3s;
  padding-top: 110px;
  /*border-right: 1px solid $color__border;*/
  box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  padding-right: 2px;
  padding-left: 2px;
  background-color: #ffffff;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav {
  font-size: 13.5px;
  top: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav li {
  line-height: 18px;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav li a {
  color: #202020 !important;
  display: block;
  text-decoration: none;
  padding: 0.6rem 1rem;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav li a:hover {
  background: #f5f5f5;
  border-radius: 4px;
  text-decoration: none;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav li a:active {
  text-decoration: none;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav li a:focus {
  text-decoration: none;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav li ul {
  list-style: none;
  padding: 0 0 0 2rem;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav li ul li {
  margin: 0 0 0.5rem;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav li ul li ul li {
  margin: 0;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav li.active > a {
  background: #38429C;
  color: #ffffff !important;
  border-radius: 4px;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav li.active > ul {
  border-left: 1px solid #E5E5E5;
  margin: 0 0 0 2rem;
  padding: 0;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav.editableOn li .editIcon {
  margin-right: 8px;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav.editableOn li a {
  display: flex;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav.editableOn li .editBox {
  background-color: #ffffff;
  margin: 0 2rem 2rem;
  border-radius: 6px;
  -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav.editableOn li .editBox ul {
  padding: 0;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav.editableOn li .editBox ul li {
  border-bottom: 1px solid #e5e5e5;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav.editableOn li .editBox ul li a {
  color: #202020;
  transition: all 0.3s;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav.editableOn li .editBox ul li a:hover {
  padding-left: 1.5rem;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav.editableOn li .editBox ul li a.deleteText {
  color: #cd0303;
}
.wrapperPageLateralmenu .sidebarWrapper .sidebar-nav.editableOn li .editBox ul li:last-of-type {
  border-bottom: none;
}
.wrapperPageLateralmenu .sidebarWrapper .dropdownSelect {
  margin: 10px 15px 15px;
}
.wrapperPageLateralmenu .sidebarWrapper .dropdownSelect label {
  font-family: "montserratmedium", sans-serif;
}
.wrapperPageLateralmenu .sidebarWrapper .dropdownSelect select {
  color: #202020;
  padding: 8px;
  width: 100%;
}
.wrapperPageLateralmenu .sidebarWrapper .fixed-bottom {
  border-top: 1px solid #E5E5E5;
  display: block;
}
.wrapperPageLateralmenu .sidebarWrapper .fixed-bottom a.editBtn, .wrapperPageLateralmenu .sidebarWrapper .fixed-bottom .pageContent a.contributorGearBtn, .pageContent .wrapperPageLateralmenu .sidebarWrapper .fixed-bottom a.contributorGearBtn, .wrapperPageLateralmenu .sidebarWrapper .fixed-bottom a.refreshBtn {
  margin: 10px;
  padding: 10px 0 10px;
  text-align: center;
  display: block;
  width: auto;
  -webkit-transition: all 0.3s ease; /* Safari */
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.wrapperPageLateralmenu .sidebarWrapper .fixed-bottom a.editBtn:hover, .wrapperPageLateralmenu .sidebarWrapper .fixed-bottom .pageContent a.contributorGearBtn:hover, .pageContent .wrapperPageLateralmenu .sidebarWrapper .fixed-bottom a.contributorGearBtn:hover, .wrapperPageLateralmenu .sidebarWrapper .fixed-bottom a.refreshBtn:hover {
  background: #38429C;
  text-decoration: none;
  color: #ffffff;
}
.wrapperPageLateralmenu .sidebarWrapper .button {
  font-size: 12.5px;
}
.wrapperPageLateralmenu .sidebarWrapper .btn-defaultPrimary, .wrapperPageLateralmenu .sidebarWrapper button.btn-defaultPrimary {
  -webkit-transition: all 0.3s ease; /* Safari */
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.wrapperPageLateralmenu .sidebarWrapper .btn-defaultPrimary:hover, .wrapperPageLateralmenu .sidebarWrapper button.btn-defaultPrimary:hover {
  color: #ffffff;
}
.wrapperPageLateralmenu .sidebarWrapper .btn-defaultPrimary:disabled, .wrapperPageLateralmenu .sidebarWrapper button.btn-defaultPrimary:disabled {
  background: #8e8d8d;
  -webkit-transition: all 0.3s ease; /* Safari */
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.wrapperPageLateralmenu .sidebarWrapper .btn-defaultPrimary:disabled:hover, .wrapperPageLateralmenu .sidebarWrapper button.btn-defaultPrimary:disabled:hover {
  box-shadow: none;
}
.wrapperPageLateralmenu .sidebarWrapper .btn-defaultSecondary:hover, .wrapperPageLateralmenu .sidebarWrapper button.btn-defaultSecondary:hover {
  border: 1px solid #3d3d3d;
  background: #3d3d3d;
  color: #ffffff;
}
.wrapperPageLateralmenu .sidebarWrapper .btn-defaultSecondary:disabled, .wrapperPageLateralmenu .sidebarWrapper button.btn-defaultSecondary:disabled {
  border: 1px solid #8e8d8d;
  color: #8e8d8d;
}
.wrapperPageLateralmenu .sidebarWrapper .btn-defaultSecondary:disabled:hover, .wrapperPageLateralmenu .sidebarWrapper button.btn-defaultSecondary:disabled:hover {
  background: transparent;
  box-shadow: none;
}

.sidebarToggle {
  background: #ffffff;
  padding: 10px;
  position: fixed;
  border-radius: 0 6px 6px 0;
  left: 260px;
  top: 115px;
  border: none;
  box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  z-index: 1;
}
.sidebarToggle.closed {
  left: 0px;
}
.sidebarToggle.closed .icon {
  transform: rotate(180deg);
}

@media only screen and (max-width: 768px) {
  .sidebarToggle {
    left: 0px;
  }
  .sidebarToggle .icon {
    transform: rotate(180deg);
  }
}
.treeview {
  font-size: 12px;
  line-height: 1.5;
}
.treeview span.indent {
  margin-left: 10px;
  margin-right: 10px;
}
.treeview .list-group {
  box-shadow: none;
}
.treeview .list-group .list-group-item {
  border-radius: 4px !important;
  cursor: pointer;
  margin-bottom: 1px;
  padding: 10px 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.treeview .list-group .list-group-item:first-child {
  border-radius: 4px !important;
}
.treeview .list-group .list-group-item:last-child {
  border-radius: 4px !important;
}
.treeview .list-group .list-group-item.node-selected {
  background-color: #38429C !important;
}
.treeview .list-group .list-group-item span.icon {
  margin-right: 4px;
  width: 12px;
}
.treeview .list-group .list-group-item span.icon.chevron {
  font-size: 10px;
  width: 12px;
}
.treeview .list-group .list-group-item span.indent {
  margin-left: 6px;
  margin-right: 6px;
}
.treeview .list-group .list-group-item span.badge {
  background-color: #2e6bcd;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-left: auto;
  min-width: auto;
}
.treeview .list-group .list-group-item span.badge .icon {
  margin: 0 0 0 3px;
  font-weight: 600;
}
.treeview .list-group .list-group-item span.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.treeview .list-group .list-group-item a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.treeview .node-disabled {
  color: silver;
  cursor: not-allowed;
}

.ms-Icon--SyncOccurence {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: -ms-10px;
  height: -ms-10px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*--------------------------------------------------------------
## Page Content
--------------------------------------------------------------*/
.pageContent {
  padding: 30px 30px;
  /* Lists boxes */
  /*Headings */
}
.pageContent .navbar-toggle.btnLateralmenu {
  float: none;
  margin: 0 0 20px;
  height: 45px;
  border: none;
}
.pageContent .navbar-toggle.btnLateralmenu .glyphicon {
  margin-right: 10px;
}
.pageContent .dataRow {
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 50px;
}
.pageContent .breadcrumb {
  margin: 0;
  line-height: normal;
  padding: 0 0 8px;
  margin: 0 0 1.5rem;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
}
.pageContent .breadcrumb .glyphicon {
  color: #B1B1B1;
  margin: 0 5px;
  font-size: 12px;
}
.pageContent .breadcrumb .icon {
  color: #38429C;
  font-size: 16px;
  margin: 0 4px 0 0;
}
.pageContent .breadcrumb .icon-separator {
  color: #B1B1B1;
  font-size: 10px;
  margin: 0 4px;
}
.pageContent .breadcrumb a {
  display: flex;
  align-items: center;
}
.pageContent .breadcrumb a:hover {
  color: #202020;
  text-decoration: none;
}
.pageContent p {
  margin: 0 0 18px;
}
.pageContent .editBtn, .pageContent .refreshBtn, .pageContent .contributorGearBtn {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 25px;
  border: none;
  font-size: 15.5px;
  height: 35px;
  width: 35px;
  padding: 0;
  text-align: center;
  -webkit-transition: all 0.3s; /* Safari */
  transition: all 0.3s;
}
.pageContent .editBtn .glyphicon, .pageContent .refreshBtn .glyphicon, .pageContent .contributorGearBtn .glyphicon {
  line-height: inherit;
}
.pageContent .contributorGearBtn {
  position: relative;
}
.pageContent .ant-row-editBtn {
  border-radius: 25px;
  border: none;
  font-size: 15.5px;
  height: 35px;
  width: 35px;
  padding: 0;
  text-align: center;
  -webkit-transition: all 0.3s; /* Safari */
  transition: all 0.3s;
  background-color: #ffffff;
}
.pageContent .flex-end-Content {
  display: flex;
  justify-content: flex-end;
}
.pageContent .refreshBtn {
  right: 30px;
}
.pageContent .navbar-toggle.btnLateralmenu {
  color: #ffffff;
  background-color: #38429C;
}
.pageContent .membersPortalContainer {
  position: relative;
  max-height: 370px;
}
.pageContent .membersPortalPanel {
  float: left;
  position: absolute;
  left: 7%;
  top: 39%;
  width: 86%;
  height: 40%;
  z-index: 1000;
  background-color: #ffffff;
  padding: 5px;
  font-weight: bold;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
}
.pageContent .membersPortalPanel .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  bottom: 2vw;
}
.pageContent .membersPortalPanel .row .cardSubText {
  font-family: "montserrat";
  font-size: 2vw;
  position: relative;
  right: 4vw;
}
.pageContent .membersPortalPanel .row .cardMainText {
  font-size: 2.2vw;
  font-family: "montserratbold";
  position: relative;
  right: 4vw;
}
.pageContent .membersPortalPanel .row .cardSideText {
  font-family: "montserrat";
  position: relative;
}
@media only screen and (min-width: 768px) {
  .pageContent .membersPortalPanel .row .cardSideText {
    padding: 1.1vw;
  }
}
.pageContent .membersPortalPanel .row .cardSideText .sideDesc {
  font-size: 11px;
}
.pageContent .membersPortalPanel .row .cardSideText .sideViewName {
  font-size: 1.2vw;
  font-family: "montserratbold";
}
.pageContent .membersPortalPanel .row .cardSideText .sideIPNumber {
  font-size: 11px;
}
.pageContent .membersPortalPanel .row .hidding {
  visibility: hidden;
}
.pageContent .membersPortalBgImg {
  margin: -30px;
  position: relative;
  top: -105px;
  max-height: 500px;
  width: 104%;
  overflow: hidden;
  object-fit: cover;
  object-position: 0 10%;
}
.pageContent .cardsList {
  display: flex;
  margin-bottom: 3rem;
}
.pageContent .cardsList div .formBox {
  height: 100%;
}
.pageContent .cardsList div .formBox .heading .icon {
  font-size: 25px;
  margin-bottom: 1rem;
  color: #38429C;
}
.pageContent .cardsList div .formBox ul li {
  margin-bottom: 12px;
}
.pageContent .cardsList div .formBox ul li a {
  color: #202020;
}
.pageContent .cardsList div .formBox ul li a:hover {
  color: #38429C;
  text-decoration: none;
  border-bottom: 2px solid;
}
.pageContent .cardsList .disabledItem .formBox {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
.pageContent .cardsList .disabledItem .formBox .heading .icon {
  color: #8e8d8d;
}
.pageContent .alignFlexSB {
  display: flex;
  justify-content: space-between;
}
.pageContent h1.borderBootom {
  border-bottom: 3px solid #38429C;
  padding-bottom: 5px;
  font-weight: 700;
  cursor: pointer;
}
.pageContent h1.borderBootom .icon {
  margin-right: 8px;
}
.pageContent h1.borderBootom .icon.expanded {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.pageContent .editableSectionHeading .editBtn, .pageContent .editableSectionHeading .refreshBtn, .pageContent .editableSectionHeading .contributorGearBtn {
  right: 5px;
}
.pageContent .editableSectionHeading .refreshBtn {
  right: 45px;
}

.wrapperPage {
  position: relative;
}
.wrapperPage .pageContent {
  width: calc(100% - 265px);
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}
.wrapperPage .pageContent.active {
  width: 100%;
}

.wrapperPageLateralmenu {
  position: relative;
  /* Sidebar navigation */
  /* /. Sidebar navigation */
}
.wrapperPageLateralmenu .pageContent {
  width: calc(100% - 265px);
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}
.wrapperPageLateralmenu .pageContent.active {
  width: 100%;
}
.wrapperPageLateralmenu .sidebarWrapper {
  overflow: auto;
}
.wrapperPageLateralmenu .sidebarWrapper .btnClose {
  border-radius: 0;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #E5E5E5;
  font-size: 13px;
  float: none;
  margin: 3px 0 10px;
  padding: 5px 0 8px;
  width: 100%;
  z-index: 99;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  background-image: none;
}
.wrapperPageLateralmenu .sidebarWrapper .btnClose .glyphicon {
  margin-right: 5px;
}
.wrapperPageLateralmenu .sidebarWrapper .btnClose .icon {
  font-size: 11px;
  margin-right: 5px;
}
.wrapperPageLateralmenu .sidebarWrapper.active {
  margin-left: -265px;
}

.disabled {
  cursor: not-allowed;
}

.sideBarSettingWrapper {
  padding-top: 10px;
  float: right;
}
.sideBarSettingWrapper i {
  padding-right: 5px;
}

.textCenter {
  text-align: center;
}

.topMargin {
  margin-top: 25px;
}

@media only screen and (max-width: 768px) {
  /* Lists boxes */
  .cardsList {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10vh;
  }
  .cardsList .form-group {
    flex: 1;
  }
  .wrapperPage .pageContent {
    width: 100%;
    padding-top: 60px;
  }
  .wrapperPage .pageContent .navbar-toggle.btnLateralmenu {
    display: block;
  }
  .wrapperPageLateralmenu {
    /* Sidebar navigation */
    /* /. Sidebar navigation */
  }
  .wrapperPageLateralmenu .pageContent {
    width: 100%;
    padding-top: 60px;
  }
  .wrapperPageLateralmenu .pageContent .navbar-toggle.btnLateralmenu {
    display: block;
  }
  .wrapperPageLateralmenu .sidebarWrapper {
    margin-left: -265px;
  }
  .wrapperPageLateralmenu .sidebarWrapper h4 {
    padding: 45px 0 15px;
  }
  .wrapperPageLateralmenu .sidebarWrapper.active {
    margin-left: 0;
    width: 100%; /* to make menu full width when active*/
  }
  .wrapperPageLateralmenu #sidebarCollapse span {
    display: none;
  }
}
@media only screen and (min-width: 769px) {
  .wrapperPageLateralmenu .sidebarWrapper .btnClose {
    display: none;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .pageContent {
    padding: 30px 20px;
  }
  h2 {
    font-size: 25px !important;
  }
  .membersPortalContainer .membersPortalPanel {
    position: absolute;
    height: 50%;
    top: -5vh;
    margin-top: 10vh;
  }
  .membersPortalContainer .membersPortalPanel .row {
    display: block;
    text-align: left;
    bottom: 5vh;
    left: 4vw;
  }
  .membersPortalContainer .membersPortalPanel .row .cardSubText {
    font-size: 13px !important;
    font-family: "montserrat" !important;
  }
  .membersPortalContainer .membersPortalPanel .row .cardMainText {
    font-size: 15px !important;
    font-family: "montserratbold" !important;
    bottom: 1vh;
  }
  .membersPortalContainer .membersPortalPanel .row .cardSideText {
    right: 4vw;
    top: 1vh;
  }
  .membersPortalContainer .membersPortalPanel .row .cardSideText .sideDesc {
    font-size: 11px !important;
  }
  .membersPortalContainer .membersPortalPanel .row .cardSideText .sideViewName {
    position: absolute;
    font-size: 10px !important;
    top: 0px;
  }
  .membersPortalContainer .membersPortalPanel .row .cardSideText .sideIPNumber {
    font-size: 10px !important;
  }
  .membersPortalContainer .container {
    padding: 4vh;
  }
  .membersPortalBgImg {
    margin-top: -50px;
  }
  .wrapperLogin .pageContent h2 {
    margin: 20px auto;
  }
}
.ms-Icon--SyncStatus {
  width: fit-content;
  height: fit-content;
}
.ms-Icon--SyncStatus.live {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.wrapperLookup {
  position: relative;
}
.wrapperLookup input[type=text] {
  padding-right: 70px !important;
}
.wrapperLookup button {
  border: none !important;
  height: 100% !important;
  padding: 10px !important;
  position: absolute !important;
  right: 0 !important;
  background: #2e6bcd; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #2e6bcd 12%, #5e2eab 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #2e6bcd 12%, #5e2eab 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #2e6bcd 12%, #5e2eab 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#2e6bcd", endColorstr="#5e2eab",GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  color: #ffffff !important;
  -webkit-transition: all 0.3s ease; /* Safari */
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.wrapperLookup button:hover {
  background: #2e6bcd; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #5e2eab 12%, #2e6bcd 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #5e2eab 12%, #2e6bcd 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #5e2eab 12%, #2e6bcd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="##5e2eab", endColorstr="#2e6bcd",GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  -webkit-transition: all 0.3s ease; /* Safari */
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.footer-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  padding-bottom: 5vh;
}

.footer {
  padding: 0px 40px;
  bottom: 0;
  width: 100%;
}

.footer-links {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.powered-by-text {
  font-size: 11px;
  color: grey;
}

hr {
  width: 100%;
}

.important-label {
  content: "*";
  height: 20px;
  width: 200px;
  color: red;
}

.timeLabel {
  margin-top: 5px;
  font-weight: 600;
}

.timeInput {
  height: 38px;
  width: 230px;
}

.timeInputContainer {
  display: flex;
  flex-direction: column;
}

.timeInputSubContainer {
  font-family: "montserratmedium", sans-serif;
  font-weight: normal;
  margin: 0;
}

.gridContainer {
  display: flex;
  flex-direction: row;
  gap: 18px;
}

.territoryDropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 13px;
}

.territoryDropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.territoryDropdown-content .territoryDropdown-item {
  display: flex;
  align-items: center;
  padding: 1px;
  justify-content: space-between;
}

.territoryDropdown-content .territoryDropdown-item span {
  flex-grow: 1;
  margin-right: 20px;
}

.territoryDropdown-content label {
  margin-left: 10px;
}

.territoryDropdown-content.show {
  width: 100%;
  position: relative;
  max-height: 560px;
}
@media only screen and (max-width: 500px) {
  .territoryDropdown-content.show {
    width: 54%;
  }
}

.arrow-icon {
  font-weight: 600;
  position: absolute;
  right: 3%;
  top: 43%;
  transform: translateY(-50%);
  transition: transform 0.2s ease;
}

.arrow-icon.up {
  position: absolute;
  font-weight: 600;
  right: 3%;
  top: 13%;
  transform: translateY(-50%) rotate(180deg);
}

.selectTerritoryOptions {
  margin-top: 201px;
  position: absolute;
  left: 66%;
}

.searchIpOptions {
  top: 34%;
  right: 66.5%;
  position: absolute;
}

.fullWidth {
  width: 100%;
}

.UsageMaintenanceMatchingWorksToolbar {
  height: auto;
  width: 100%;
  padding: 0 !important;
}

.UsageMaintenanceMatchingWorksToolbar .toolbarWrapper {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f5f5f5;
  text-align: center;
  gap: 12px;
}

#DragAndDropArea table.dataTable tbody tr td div {
  padding: 12px 2px !important;
}

.dataTable {
  width: 100%;
}
.dataTable thead tr {
  border-bottom: 1px solid #38429C;
  font-family: "montserratmedium", sans-serif;
  font-size: 12px;
}
.dataTable thead tr td div {
  padding: 5px 0 5px 10px;
}
.dataTable thead tr td .headerCell {
  display: flex;
  font-family: "montserratmedium", sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dataTable thead tr td .headerCell.headerCellSortable:hover {
  cursor: pointer;
}
.dataTable thead tr td .headerCell.headerCellSortable:hover img.sortIcon {
  visibility: visible;
}
.dataTable thead tr td .headerCell.headerCellSortable img.sortIcon {
  height: 15px;
  width: 20px;
  visibility: hidden;
}
.dataTable thead tr td .headerCell.headerCellSortable .sortAscendingIcon {
  height: 10px;
  width: 20px;
}
.dataTable tbody tr {
  border-bottom: 1px solid #E5E5E5;
  cursor: pointer;
}
.dataTable tbody tr td div {
  padding: 12px 10px;
  display: flex;
  align-items: center;
}
.dataTable tbody tr td div .icon {
  color: #38429C;
  font-size: 16px;
  margin: 0 8px 0 0;
}
.dataTable tbody tr td div .icon.iconHover:hover {
  background-color: #38429C;
  border-radius: 10px;
  color: #ffffff;
}
.dataTable tbody tr td div select, .dataTable tbody tr td div input {
  max-width: max-content;
}
.dataTable tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.dataTable tbody tr td .tableCell.hasIcon input[type=text], .dataTable tbody tr td .tableCell.hasIcon input[type=number], .dataTable tbody tr td .tableCell.hasIcon input[type=time], .dataTable tbody tr td .tableCell.hasIcon select {
  width: auto;
  max-width: 110px;
}
.dataTable tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
}
.dataTable tbody tr td .showMore {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: absolute;
}
.dataTable tbody tr td .showMore .popUp {
  align-items: center;
  right: 30px;
  display: block;
  padding: 0px;
  left: unset;
  bottom: unset;
}
.dataTable tbody tr td .showMore .popUp .popUpItem i {
  margin-left: 3px;
  color: #1a7ec5;
}
.dataTable tbody tr:hover {
  background-color: #f5f5f5;
}
.dataTable tbody tr:last-child {
  border-bottom: none;
}

.unableMinWidth {
  min-width: 0 !important;
}

.noPadding {
  padding: 0 0 0 0 !important;
}

.inheritPosition {
  position: inherit !important;
}

.minWidth {
  min-width: 100% !important;
}

.row .headerContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.row .headerContainer .headerSection {
  flex: 1;
  font-family: "montserratmedium", sans-serif;
  font-size: 16px;
  padding: 0 0 5px 10px;
  border-bottom: 3px solid #E5E5E5;
  cursor: pointer;
}
.row .headerContainer .headerSection.activeSection {
  border-bottom: 3px solid #38429C;
}

@media only screen and (max-width: 920px) {
  .dataTable {
    border-top: 1px solid #38429C;
  }
  .dataTable thead {
    display: none;
  }
  .dataTable tbody,
  .dataTable th,
  .dataTable td,
  .dataTable tr,
  .dataTable .trSelectable {
    display: block;
  }
  .dataTable tr {
    border-bottom: 1px solid #38429C !important;
  }
  .dataTable tr td {
    border-top: none;
    padding: 12px 10px;
    max-width: 100% !important;
  }
  .dataTable tr td:before {
    content: attr(title);
    font-family: "montserratmedium", sans-serif;
  }
  .dataTable tr td div {
    padding: 0 !important;
  }
  .dataTable tr td div select, .dataTable tr td div input {
    width: 100% !important;
  }
}
.territoryContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.alert {
  border-width: 0 0 0 6px;
  border-style: solid;
  -webkit-box-shadow: 0px 9px 27px -6px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 9px 27px -6px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 9px 27px -6px rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
  background-image: none;
  margin-top: 8px;
  white-space: pre-wrap;
}
.alert .alert-content {
  display: flex;
}
.alert .alert-content .icon {
  font-size: 20px;
  margin: 0 10px 0 0;
}
.alert .alert-content .icon.info {
  color: #2e6bcd !important;
}
.alert .alert-content .icon.info {
  color: #2e6bcd !important;
}
.alert .alert-content .icon.sucess {
  color: #008f59;
}
.alert .alert-content .icon.danger {
  color: #ff6070;
}
.alert .alert-content .icon.warning {
  color: #ff9800;
}
.alert .alert-content .text {
  margin-top: 4px;
}
.alert .alert-content .text ul {
  margin: 10px 0 10px 0;
  padding: 0 0 0 25px;
}
.alert .alert-content .text ul li {
  margin: 0 0 4px 0;
}
.alert .alert-content .text ul li:last-child {
  margin: 0;
}
.alert-info {
  border-color: #2e6bcd;
  color: #202020;
}
.alert-success {
  border-color: #008f59;
  color: #202020;
}
.alert-danger {
  border-color: #ff6070;
  color: #202020;
}
.alert-warning {
  border-color: #ff9800;
  color: #202020;
}

.workflowInfo {
  display: inline-flex;
  margin: 30px 0 10px;
}
.workflowInfo .icon {
  color: #ff9800;
  margin-right: 10px;
  font-size: 20px;
  border-left: 3px solid;
  padding-left: 10px;
}
.workflowInfo span:last-of-type {
  flex-grow: 1;
}
.workflowInfo .workflowLabel {
  font-family: "montserrat_mediumregular", sans-serif;
  margin-bottom: 5px !important;
}
.workflowInfo .row {
  margin-bottom: -20px;
}
.workflowInfo .col-sm-12 {
  margin-bottom: -10px;
}
.workflowInfo .date {
  float: left;
  width: revert;
}
.workflowInfo .assinged {
  float: left;
  width: revert;
}
.workflowInfo .message {
  float: left;
  width: revert;
}

.col-md-12 .row-workflow .arrowIconExpandDiv {
  display: flex;
  position: absolute;
  margin-left: 5px;
  margin-top: 8px;
}
.col-md-12 .row-workflow .arrowIconExpandText {
  margin-left: 6px;
  overflow-wrap: break-word;
}
.col-md-12 .row-workflow .arrowIconExpandAll {
  height: 16px;
  width: 16px;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.col-md-12 .row-workflow .arrowIconExpandAllExpanded {
  height: 16px;
  width: 16px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.col-md-12 .row-workflow .arrowIconExpandAllExpanded:hover,
.col-md-12 .row-workflow .arrowIconExpandAll:hover {
  cursor: pointer;
}

.col-md-12 .row-workflow-empty {
  margin-bottom: 15px;
}

.selectAllCheckBox {
  transform: rotate(0deg) !important;
}

.selectAllCheckBoxText {
  margin-top: 3px !important;
}

.dragContainer {
  margin: 0 0 1em 0;
  padding: 1em;
  border: 2px solid transparent;
}

.dragContainer .daggableItem {
  padding: 15px 2em;
}
.dragContainer .daggableItem:last-of-type {
  margin-bottom: 0;
}
.dragContainer .daggableItem:before {
  content: "⇅";
  position: absolute;
  left: 10px;
  font-size: 1.8em;
  cursor: move;
}
.dragContainer .daggableItem:before:hover {
  color: #38429C;
}
.dragContainer .daggableItem:hover {
  background-color: #fafafa;
}
.dragContainer .daggableItem.js-active {
  border: 1px dashed #ffffff;
  background-color: #38429C;
}

/*--------------------------------------------------------------
## Forms elements
--------------------------------------------------------------*/
.disabledItem {
  color: #8e8d8d;
}

.flexContent label {
  margin: 0 8px;
}
.flexContent input[type=text], .flexContent input[type=date], .flexContent input[type=time], .flexContent textarea, .flexContent select {
  width: auto !important;
  margin: 0 8px;
  flex-grow: 1;
}

input[type=text] {
  height: 38px;
  padding: 8px 40px 8px 8px;
  width: 100%;
}

input[type=time] {
  height: 38px;
  padding: 8px 40px 8px 8px;
  width: 100%;
}

input[type=number] {
  height: 38px;
  padding: 8px 40px 8px 8px;
  width: 100%;
}

input[type=tel] {
  height: 38px;
  padding: 8px 40px 8px 8px;
  width: 100%;
}

input[type=date] {
  height: 38px;
  padding: 8px 40px 8px 8px;
  width: 100%;
}

input[type=password] {
  height: 38px;
  padding: 8px 40px 8px 8px;
  width: 100%;
}

label {
  font-family: "montserratmedium", sans-serif;
  font-weight: normal;
  margin: 8px 0 5px;
}

select {
  height: 38px;
  padding: 8px 40px 8px 8px;
}
select:focus {
  outline: none;
}

input[type=text], input[type=number], input[type=tel], input[type=time], textarea, select {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  padding: 7px;
  width: 100%;
  -webkit-transition: all 0.3s; /* Safari */
  transition: all 0.3s;
}
input[type=text]:focus, input[type=number]:focus, input[type=tel]:focus, input[type=time]:focus, textarea:focus, select:focus {
  border: 1px solid #38429C;
  outline: none;
}

input[type=date], textarea, select {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  padding: 7px;
  width: 100%;
  -webkit-transition: all 0.3s; /* Safari */
  transition: all 0.3s;
}
input[type=date]:focus, textarea:focus, select:focus {
  border: 1px solid #38429C;
  outline: none;
}

textarea {
  min-height: 300px;
  resize: vertical;
}

.formBox.checkOptions {
  -webkit-transition: box-shadow 0.2s ease-in-out; /* Safari */
  transition: box-shadow 0.2s ease-in-out;
}
.formBox.checkOptions:hover {
  -webkit-box-shadow: 0px 9px 27px -6px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 9px 27px -6px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 9px 27px -6px rgba(0, 0, 0, 0.4);
}

.formBox {
  background: #ffffff;
  /*border: 1px solid $lighGray;*/
  padding: 15px;
  position: relative;
  border-radius: 4px;
  -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
}
.formBox .heading {
  display: flex;
  align-items: center;
}
.formBox .heading .icon {
  font-size: 16px;
  margin: 0 8px 0 0;
}
.formBox input, .formBox input[type=text], .formBox input[type=number], .formBox input[type=tel], .formBox input[type=time] select {
  /*border:none;*/
}
.formBox input:focus, .formBox input[type=text]:focus, .formBox input[type=number]:focus, .formBox input[type=tel]:focus, .formBox input[type=time] select:focus {
  background-color: #ffffff;
}
.formBox input:disabled, .formBox input[type=text]:disabled, .formBox input[type=number]:disabled, .formBox input[type=tel]:disabled, .formBox input[type=time]:disabled, .formBox select:disabled {
  color: #8e8d8d;
  background-color: #F6F7FA;
}
.formBox .form-group select {
  display: block;
  width: 100%;
}

.autoBar {
  overflow-x: auto;
}

/*Check Options */
.checkOptions {
  /* The radio box */
  /* On mouse-over, add a grey background color */
  /* When the radio button is checked, add a blue background */
  /* Create the indicator (the dot/circle - hidden when not checked) */
  /* Show the indicator (dot/circle) when checked */
  /* Style the indicator (dot/circle) */
  /*  /. The radio box */
}
.checkOptions .heading {
  display: block;
}
.checkOptions label {
  font-family: "montserratmedium", sans-serif;
  font-weight: 300;
  display: block;
  margin-bottom: 3px;
}
.checkOptions .itemsWrapper {
  display: block;
  position: relative;
}
.checkOptions input[type=text] {
  height: 38px;
  padding: 8px 40px 8px 8px;
  width: 100%;
}
.checkOptions input[type=number] {
  height: 38px;
  padding: 8px 40px 8px 8px;
  width: 100%;
}
.checkOptions .dividerOr {
  text-align: center;
  margin-top: 25px;
  line-height: 20px;
}
.checkOptions .radioBox {
  display: block;
  position: absolute;
  /*padding-left: 35px;*/
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 36px;
  height: 36px;
  right: 1px;
  top: 1px;
  /* Hide the browser's default radio button */
  /* Create a custom radio button */
}
.checkOptions .radioBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkOptions .radioBox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 36px;
  width: 36px;
  background-color: #d8d8d8;
}
.checkOptions .radioBox.singleOption {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
}
.checkOptions .radioBox:hover input ~ .checkmark {
  background-color: #ccc;
}
.checkOptions .radioBox input:checked ~ .checkmark {
  background-color: #6ac259;
}
.checkOptions .checkmark:after {
  content: "";
  position: absolute;
  opacity: 0.8;
  /*display: none;*/
}
.checkOptions .radioBox input:checked ~ .checkmark:after {
  display: block;
  opacity: 1;
}
.checkOptions .radioBox .checkmark:after {
  left: 15px;
  top: 5px;
  width: 10px;
  height: 24px;
  border: solid white;
  border-width: 0 5px 5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*  /. Check Options */
/* Toggle Switch */
.toggleSwitchContacontainer input[type=checkbox] {
  display: none;
  height: 0;
  width: 0;
  visibility: hidden;
}
.toggleSwitchContacontainer label {
  cursor: pointer;
  text-indent: -9999px;
  width: 60px;
  height: 30px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
  margin: 0;
}
.toggleSwitchContacontainer label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 3px;
  width: 26px;
  height: 26px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}
.toggleSwitchContacontainer input:checked + label {
  background: #65c36c;
}
.toggleSwitchContacontainer input:checked + label:after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}
.toggleSwitchContacontainer label:active:after {
  width: 35px;
}

/* Toggle Switch */
input[type=text], input[type=date], input[type=number], input[type=tel], input[type=time], textarea, select {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  height: 38px;
  padding: 7px;
  width: 100%;
  -webkit-transition: all 0.3s; /* Safari */
  transition: all 0.3s;
  border-radius: 4px;
}
input[type=text]:focus, input[type=date]:focus, input[type=number]:focus, input[type=tel]:focus, input[type=time]:focus, textarea:focus, select:focus {
  border: 1px solid #38429C;
  outline: none;
}
input[type=text].editableLabel, input[type=date].editableLabel, input[type=number].editableLabel, input[type=tel].editableLabel, input[type=time].editableLabel, textarea.editableLabel, select.editableLabel {
  background-color: #f5f5f5;
  border-width: 0 0 2px 0;
}
input[type=text].editableLabel:focus, input[type=date].editableLabel:focus, input[type=number].editableLabel:focus, input[type=tel].editableLabel:focus, input[type=time].editableLabel:focus, textarea.editableLabel:focus, select.editableLabel:focus {
  background-color: #ffffff;
}

.versionBox {
  background: none;
  padding: 15px;
  position: relative;
  border: none;
  box-shadow: none;
  color: white;
}
.versionBox span {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.flexGapSm {
  gap: 10px;
}

.extraWidth {
  min-width: 19rem !important;
  padding: 12px 10px !important;
}

.bold {
  font-weight: bold;
  padding-right: 5px;
}

.subTittle {
  font-size: smaller;
}

.subTittle2 {
  font-size: x-small;
}

.dropText {
  color: #202020;
}

.poolsImportTextBox {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  border: 1px solid #c7c7c7;
  background-color: #f7f7f7;
  border-radius: 4px;
  font-family: "montserrat", sans-serif;
  font-size: 14px;
}

.dropBox {
  color: #c7c7c7;
  border-radius: 4px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 10px;
}
.dropBox .dropBoxContent {
  border-radius: 4px;
  border-style: dashed;
  padding: 60px;
  height: 100%;
  width: 100%;
  border-width: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f7f7f7;
}
.dropBox .dropBoxContent .dopBoxContent-Icon {
  font-size: 3rem;
  margin-bottom: 2rem;
}
.dropBox .dropBoxContent .dopBoxContent-Heading {
  font-family: "montserratbold", sans-serif;
  font-size: 16px;
}

.display {
  display: block;
}

.notToDisplay {
  display: none;
}

.containerStages {
  margin-bottom: 5px;
}

.disabledDragAndDrop {
  pointer-events: none;
  opacity: 0.4;
}

.importFileDragAndDrop {
  min-height: 110px;
}

.dragArea {
  border-style: dashed;
  padding: 20px;
  height: 100%;
  width: 100%;
  border-width: 1px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "montserratbold", sans-serif;
  font-size: 16px;
}

/*--------------------------------------------------------------
## Repertoire
--------------------------------------------------------------*/
.actionButton {
  width: 250px;
  height: 28px;
  margin-bottom: 25px;
  color: white;
  margin-top: 25px;
  margin-right: 15px;
  outline: none;
  align-self: flex-end;
  font-size: 12px;
  border: none;
  background-color: var(--primaryColor);
}

.actionButton:hover {
  -webkit-transition: all 0.3s;
  /* Safari */
  transition: all 0.3s;
  -webkit-box-shadow: 0px 9px 27px -6px rgba(43, 43, 43, 0.42);
  -moz-box-shadow: 0px 9px 27px -6px rgba(43, 43, 43, 0.42);
  box-shadow: 0px 9px 27px -6px rgba(43, 43, 43, 0.42);
}

.buttonsCarouselView {
  margin: 1.5rem 0;
}
.buttonsCarouselView .CarouselContainer .CarouselContents .carouselButton {
  border-width: 1px 1px 1px 0;
  border-color: #38429C;
  border-style: solid;
  cursor: pointer;
  padding: 8px 7px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.buttonsCarouselView .CarouselContainer .CarouselContents:first-of-type {
  border-left: 1px solid #38429C;
}
.buttonsCarouselView .CarouselContainer .CarouselContents .activeItem {
  background-color: #38429C;
  color: #ffffff;
}

.otherContributorFilters {
  float: left;
  clear: both;
}
.otherContributorFilters .form-group {
  width: 200px;
}

.CarouselScrollTabs {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.CarouselScrollTabs .CarouselContainer {
  width: 95%;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  overflow-y: hidden;
  margin: 0 3px;
  z-index: 0;
}
.CarouselScrollTabs .CarouselContainer .CarouselContents {
  width: 95%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  overflow-y: hidden;
  margin: 0 3px;
}
.CarouselScrollTabs button.buttonContainer {
  width: 35px;
  height: 30px;
  z-index: 2;
  border: none;
  background-color: #ffffff;
}
.CarouselScrollTabs button.buttonContainer.leftShadow {
  box-shadow: 2px -1px 2px lightgrey;
}
.CarouselScrollTabs button.buttonContainer.leftShadow .leftScrollButton {
  width: 15px;
  height: 20px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.CarouselScrollTabs button.buttonContainer.rightShadow {
  box-shadow: -2px -1px 2px lightgrey;
}
.CarouselScrollTabs button.buttonContainer.rightShadow .rightScrollButton {
  width: 15px;
  height: 20px;
}
.CarouselScrollTabs button.buttonContainer:focus {
  outline: 0;
}
.CarouselScrollTabs button.buttonContainer:active {
  background-color: #38429C;
}
.CarouselScrollTabs button.buttonContainer::-webkit-scrollbar {
  height: 0;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
}
.checkboxContainer.inline {
  margin-top: 25px;
}
.checkboxContainer .subContainer {
  display: flex;
  position: relative;
  padding-left: 30px;
  margin: 0;
  cursor: pointer;
  height: 25px;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  /* Hide default checkbox */
  /* Custom checkbox */
}
.checkboxContainer .subContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkboxContainer .subContainer input:checked ~ .inputCheckbox {
  background-color: #65c36c !important;
}
.checkboxContainer .subContainer .inputCheckbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #e5e5e5;
  border-radius: 4px;
}
.checkboxContainer .subContainer .inputCheckbox::after {
  content: "";
  position: absolute;
  left: 9px;
  top: 2px;
  width: 8px;
  height: 16px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkboxContainer .subContainer .checkboxLabel {
  margin-left: 4px;
  padding-top: 4px;
}
.checkboxContainer .subContainer:hover input ~ .inputCheckbox {
  background-color: #B1B1B1;
}

.combinedTextAndButtonDataInput {
  display: flex;
  flex: 1 1;
  flex-direction: row;
}
.combinedTextAndButtonDataInput .textInput {
  display: flex;
  flex: 1;
  margin-right: 5%;
}
.combinedTextAndButtonDataInput button {
  border: none;
  display: flex;
  flex: 0.3;
  white-space: nowrap;
  align-items: center;
  height: 100%;
  padding: 8px 11px;
}
.combinedTextAndButtonDataInput button.disabledButton {
  background-color: transparent;
  color: #B1B1B1;
}
.combinedTextAndButtonDataInput button.enabledButton {
  background-color: #38429C;
  color: #ffffff;
}
.combinedTextAndButtonDataInput .buttonText {
  border: none;
  white-space: nowrap;
  font-size: 12px;
  text-align: center;
  width: 100%;
}
.combinedTextAndButtonDataInput button[disabled] {
  cursor: default;
}

.dataGridTableSection {
  display: block;
  height: 100%;
  width: 100%;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dropdownDataInput select.showAsCode {
  min-width: 80px;
  max-width: 120px;
}

@media only screen and (max-width: 920px) {
  .dropdownDataInput select.showAsCode {
    position: relative;
    width: 100%;
    max-width: inherit;
  }
}
.ms-Dropdown, .ms-Dropdown-items, .ms-Button-flexContainer, .ms-Dropdown-label, .ms-Dropdown-title {
  font-size: 13px;
  font-family: "montserrat_mediumregular", sans-serif;
}

.ms-Dropdown, .ms-Dropdown-items, .ms-Button-flexContainer, .ms-Dropdown-label {
  font-weight: normal;
}

.ms-Dropdown-label {
  padding: 0;
  margin: 8px 0 5px 0;
  font-family: "montserratmedium", sans-serif;
  color: #202020;
}

.ms-Dropdown {
  font-weight: 400;
  color: #202020;
  text-rendering: optimizeLegibility;
  background: #f5f5f5;
  height: 38px;
  padding: 0;
  margin: 0;
  width: 100%;
}

.ms-Dropdown.is-open > .ms-Dropdown-title {
  background: #fff;
  border: 1px solid #38429C;
  outline: none;
}

.ms-Dropdown:focus::after {
  border: none;
}

.ms-Dropdown-title {
  background: #f5f5f5;
  border: 1px solid #e5e5e5;
  line-height: 1.2;
  height: 38px;
  width: 100%;
  padding: 8px;
  margin: 0;
  transition: all 0.3s;
}

.ms-ComboBox, .ms-ComboBox-items, .ms-Button-flexContainer, .ms-Label, .ms-ComboBox-title {
  font-size: 13px;
  font-family: "montserrat_mediumregular", sans-serif;
}

.ms-ComboBox, .ms-ComboBox-items, .ms-Button-flexContainer, .ms-Label {
  font-weight: normal;
}

.ms-Label {
  margin: 8px 0px 5px 0px;
  font-family: "montserratmedium", sans-serif;
  color: #202020;
  padding: 0 0 0 0;
}

.ms-ComboBox {
  font-weight: 400;
  color: #202020;
  text-rendering: optimizeLegibility;
  background: #f5f5f5;
  height: 38px;
  margin: 0;
  width: 100%;
}

.ms-ComboBox.is-open > .ms-ComboBox-title {
  background: #fff;
  border: 1px solid #38429C;
  outline: none;
}

.ms-ComboBox:focus::after {
  border: none;
}

.ms-ComboBox-title {
  background: #f5f5f5;
  border: 1px solid #e5e5e5;
  line-height: 1.2;
  height: 38px;
  width: 100%;
  padding: 8px;
  margin: 0;
  transition: all 0.3s;
}

.ms-Button-flexContainer .ms-ComboBox-optionText {
  overflow: visible;
  white-space: normal;
  text-overflow: ellipsis;
  display: inline-block;
  padding: 3px 0;
}

.GridsView {
  flex: 1;
  margin: 20px 0 20px;
}
.GridsView .headerContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.GridsView .headerContainer .headerSection {
  flex: 1;
  font-family: "montserratmedium", sans-serif;
  font-size: 16px;
  padding: 0 0 5px 10px;
  border-bottom: 3px solid #E5E5E5;
  cursor: pointer;
}
.GridsView .headerContainer .headerSection.activeSection {
  border-bottom: 3px solid #38429C;
}

.AccordionContainer {
  flex: 1;
}
.AccordionContainer .itemAccordion {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "montserratmedium", sans-serif;
  padding: 15px 0 15px 0;
  border-bottom: 1px solid #E5E5E5;
  cursor: pointer;
}
.AccordionContainer .itemAccordion .icon {
  margin-right: 8px;
}
.AccordionContainer .itemAccordion .icon.expanded {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.AccordionContainer .itemAccordion.activeItem {
  color: #38429C;
  border-bottom: none;
}
.AccordionContainer .itemAccordion .workMessageBanner {
  flex: 1;
  margin-left: 30px;
}
.AccordionContainer .contentAccordion {
  margin: 0 0 20px 0;
  border-bottom: 1px solid #E5E5E5;
  width: 100%;
  padding: 0 0 15px;
}
.AccordionContainer .contentAccordion .tableContainer {
  margin-bottom: 5px;
  margin-top: 5px;
}
.AccordionContainer .contentAccordionAgreement {
  margin: 0 15px 20px 15px;
  border-bottom: 1px solid #E5E5E5;
  max-width: 100%;
  padding: 0 0 15px;
}
.AccordionContainer .contentAccordionAgreement .tableContainer {
  margin-bottom: 5px;
  margin-top: 5px;
}

.IconTextButton {
  display: flex;
  align-items: center;
  white-space: normal;
  cursor: pointer;
}
.IconTextButton .icon {
  font-size: 15px;
  margin-right: 5px;
  color: #38429C;
}
.IconTextButton .disabledIcon {
  color: grey;
}
.IconTextButton .disabled {
  color: grey;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  font-size: 10px;
  font-weight: bold;
}
.IconTextButton.isIconHidden .icon {
  visibility: hidden;
}
.IconTextButton.isIconHidden:hover .icon {
  visibility: visible;
}
.IconTextButton:hover {
  color: #38429C;
}

@media only screen and (max-width: 768px) {
  .IconTextButton {
    white-space: normal;
    margin-right: 4px;
  }
}
.paginationDiv {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 20px;
  justify-content: flex-end;
  border-radius: 4px;
}
.paginationDiv .showPerPageDiv {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.paginationDiv .showPerPageDiv .resultsNumberDiv {
  font-weight: bold;
  white-space: nowrap;
  margin-right: 10px;
}
.paginationDiv .showPerPageDiv .resultsPerPageSelect {
  margin: 0 5px 0 5px;
  height: 30px;
  width: auto;
}
.paginationDiv .pageNumberDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  border-radius: 4px;
}
.paginationDiv .pageNumberDiv .pageNumberButton {
  display: flex;
  border: 1px solid #38429C;
  color: #38429C;
  padding: 0 5px 0 5px;
  height: 30px;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  flex-direction: row;
}
.paginationDiv .pageNumberDiv .pageNumberButton .pageNumberPrevIcon {
  width: 14px;
  height: 14px;
  margin-right: 2px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  fill: #38429C;
}
.paginationDiv .pageNumberDiv .pageNumberButton .pageNumberNextIcon {
  width: 14px;
  height: 14px;
  margin-left: 2px;
  fill: #38429C;
}
.paginationDiv .pageNumberDiv .inputDiv {
  margin: 0 12px 0 12px;
  height: 30px;
}
.paginationDiv .pageNumberDiv .inputDiv .pageNumberInput {
  width: 30px;
  text-align: center;
  margin-right: 5px;
  height: 30px;
}

.ant-pagination-next .pageNumberButton, .ant-pagination-prev .pageNumberButton {
  display: flex;
  color: #38429C;
  padding: 0 5px 0 5px;
  height: 30px;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  flex-direction: row;
}

@media only screen and (max-width: 600px) {
  .paginationDiv {
    flex-direction: column;
    justify-content: flex-end;
  }
  .paginationDiv .showPerPageDiv {
    margin: 0 0 5px 0;
  }
}
@media only screen and (max-width: 920px) {
  .paginationDiv {
    flex-direction: column;
    justify-content: flex-end;
  }
  .paginationDiv .showPerPageDiv {
    margin: 0 0 25px 0;
  }
  .paginationDiv .showPerPageDiv .resultsPerPageSelect {
    margin: 0 5px 0 5px;
  }
  .paginationDiv .pageNumberDiv .pageNumberButton {
    padding: 0 5px 0 5px;
  }
  .paginationDiv .pageNumberDiv .inputDiv {
    margin: 0 6px 0 6px;
  }
}
.ant-picker-dropdown {
  z-index: 1300;
}

.repertoireModal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(105, 105, 105, 0.37);
  z-index: 1051;
  display: flex;
  justify-content: center;
  align-items: center;
}
.repertoireModal .modal {
  width: 80%;
  min-width: 70%;
  max-width: 700px;
  max-height: 80%;
  min-height: 20%;
  background: white;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
}
.repertoireModal .modal .fixedPart {
  flex-shrink: 0;
  margin-top: 30px;
}
.repertoireModal .modal .dynamicPart {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 10px;
  width: 100%;
}
.repertoireModal .modal .modalBody {
  background: white;
  flex: 1;
}
.repertoireModal .modal .closeButton {
  position: absolute;
  top: 5px;
  right: 7px;
  cursor: pointer;
}
.repertoireModal .modal .newWorkButton {
  display: flex;
  justify-content: end;
}
.repertoireModal .modal .title {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 15px;
}
.repertoireModal .modal .closeIcon {
  width: 12px;
  height: 12px;
}
.repertoireModal .modal .doneButtonRow {
  width: 95%;
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
}
.repertoireModal .modal .doneButtonRow .doneButton {
  width: 20%;
}
.repertoireModal .importData {
  min-height: 29% !important;
  overflow-y: auto;
}

.selectionCriteriaOptions {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.noFlex {
  display: block !important;
}

.centerTittle {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 499px) {
  .repertoireModal .modal {
    min-width: 95%;
    max-height: 95%;
  }
}
.versionHistoryPointer {
  pointer-events: none;
}

.pointerEnable {
  pointer-events: all;
}

.topMarginRepertoireAttachment {
  padding-top: 25px;
}

.newJobButton {
  position: absolute;
  right: 20px;
  top: 5px;
}

.isMandatory::after {
  content: "*";
  color: #cd0303;
  margin-left: 5px;
  font-weight: 600;
}

.disabledItem {
  color: #8e8d8d;
}

.ms-ComboBox-container.required .ms-Label::after {
  content: "*";
  color: #cd0303;
  margin-left: 5px;
  font-weight: 600;
}
.ms-ComboBox-container.disabledItem .ms-Label {
  color: #8e8d8d;
  cursor: not-allowed !important;
}
.ms-ComboBox-container.disabledItem .ms-ComboBox .ms-ComboBox-Input {
  color: #8e8d8d;
  cursor: not-allowed !important;
}

.ms-Dropdown-container.required .ms-Label::after {
  content: "*";
  color: #cd0303;
  margin-left: 5px;
  font-weight: 600;
}
.ms-Dropdown-container.disabledItem .ms-Label {
  color: #8e8d8d;
  cursor: not-allowed !important;
}
.ms-Dropdown-container.disabledItem .ms-Dropdown .ms-Dropdown-title {
  color: #8e8d8d;
  cursor: not-allowed !important;
}
.ms-Dropdown-container .ms-Label {
  padding: 0;
}

.selected-item-container {
  width: 100%;
  padding: 0px;
  overflow: auto;
  margin-top: 50px;
}

.selected-items-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.selected-item {
  background-color: #f1f1f1;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 5px 10px;
  display: inline-block;
  font-size: 14px;
}

.close-icon {
  cursor: pointer;
  font-size: 16px;
}

.ms-Callout-container.container-175 {
  position: relative;
  top: 610px;
  right: 613px;
}

.searchResultsDiv {
  display: flex;
  margin-top: 30px;
  width: 100%;
  overflow: hidden;
}
.searchResultsDiv .SearchResultsTable {
  display: flex;
  flex: 1;
  margin-bottom: 20px;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}
.searchResultsDiv .arrowIconExpandDiv {
  width: 15%;
  display: flex;
  position: absolute;
  margin-left: 5px;
  margin-top: 8px;
}
.searchResultsDiv .arrowIconExpandText {
  margin-left: 6px;
  overflow-wrap: break-word;
}
.searchResultsDiv .arrowIconExpandAll {
  height: 16px;
  width: 16px;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.searchResultsDiv .arrowIconExpandAllExpanded {
  height: 16px;
  width: 16px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.searchResultsDiv .arrowIconExpandAllExpanded:hover,
.searchResultsDiv .arrowIconExpandAll:hover {
  cursor: pointer;
}

.wrapText {
  overflow-wrap: break-word;
}

@media only screen and (max-width: 920px) {
  .tableContainer .table {
    border-top: 1px solid #38429C;
    table-layout: fixed;
  }
  .tableContainer .table .thead {
    display: none;
  }
  .tableContainer .table .tbody,
  .tableContainer .table .th,
  .tableContainer .table .td,
  .tableContainer .table .tr,
  .tableContainer .table .trSelectable {
    display: block;
  }
  .tableContainer .table tr {
    border-bottom: 1px solid #38429C !important;
  }
  .tableContainer .table tr td {
    border-top: none;
    padding: 5px 0 5px 10px;
    max-width: 100% !important;
  }
  .tableContainer .table tr td:before {
    content: attr(title);
    font-family: "montserratmedium", sans-serif;
  }
}
.searchView {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}
.searchView .searchFieldsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 25px;
  row-gap: 10px;
  width: 100%;
}
.searchView .searchFieldsGridUsageModal {
  width: 100%;
}
.searchView .searchFieldsGridUsageModal .row {
  margin-left: 20px;
}
.searchView .headerSection {
  flex: 1 1;
  font-family: "montserratmedium", sans-serif;
  font-size: 18px;
  padding: 10px 0 5px 15px;
  margin-bottom: 10px;
  border-bottom: 3px solid #2e6bcd;
}
.searchView .searchButton {
  width: 250px;
  height: 28px;
  margin-bottom: 25px;
  color: white;
  margin-top: 25px;
  outline: none;
  align-self: flex-end;
  font-size: 12px;
  border: none;
  background-color: var(--primaryColor);
}
.searchView .searchButton:hover {
  -webkit-transition: all 0.3s;
  /* Safari */
  transition: all 0.3s;
  -webkit-box-shadow: 0px 9px 27px -6px rgba(43, 43, 43, 0.42);
  -moz-box-shadow: 0px 9px 27px -6px rgba(43, 43, 43, 0.42);
  box-shadow: 0px 9px 27px -6px rgba(43, 43, 43, 0.42);
}
.searchView .searchResultsDiv {
  display: flex;
  margin-top: 10px;
  width: 100%;
  overflow: hidden;
}
.searchView .searchResultsDiv .tr .rowExpanded {
  max-width: 500px;
}
.searchView .searchResultsDiv .tr .rowUnexpanded {
  max-width: 500px;
  height: 18px;
  overflow: hidden;
}
.searchView .centerTextNotFound {
  text-align: center;
  margin-top: 30px;
}
@media only screen and (max-width: 768px) {
  .searchView .searchButton {
    height: 20px;
    width: 30%;
    min-width: 120px;
    font-size: calc(6px + 1vw);
  }
}

.selectionTable {
  display: flex;
  flex: 1;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  flex-direction: column;
  overflow: hidden;
  width: 98%;
}
.selectionTable .tableSection {
  word-wrap: break-word;
  padding: 6px 25px 6px 10px;
  position: relative;
}
.selectionTable .tableSection .button.btn-defaultPrimary {
  width: 50%;
  margin-left: 5px;
  margin-top: 0px;
  float: right;
}
.selectionTable .td {
  max-width: 150px;
}
.selectionTable .button.btn-defaultPrimary {
  margin-top: 5px;
}
.selectionTable .tableContainer {
  flex: 1 1 100%;
  width: 0;
  max-width: max-content;
  min-width: 98%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.selectionTable .tableContainer .arrowIconDiv {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.selectionTable .tableContainer .arrowIconDiv .workTitle {
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}
.selectionTable .tableContainer .arrowIcon {
  height: 12px;
  width: 12px;
  margin-right: 5px;
}
.selectionTable .tableContainer .arrowIconExpanded {
  height: 12px;
  width: 12px;
  margin-right: 5px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.selectionTable .tableContainer .arrowIconExpanded:hover,
.selectionTable .tableContainer .arrowIcon:hover {
  cursor: pointer;
}
.selectionTable .table {
  background: white;
  font-size: 13px;
  table-layout: auto;
  width: 100%;
}
.selectionTable .thead {
  border-bottom: 2px solid var(--primaryColor);
}
.selectionTable .tr {
  border-bottom: 1px solid var(--secondaryColor);
}
.selectionTable .tr:hover {
  background-color: var(--secondaryColor);
}
.selectionTable .trSelectable {
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
}
.selectionTable .trSelectable:hover {
  background: #f1f1f1;
}
.selectionTable .intrayRow {
  background: #f1f1f1;
  color: #3d3d3d !important;
  margin-left: 10px !important;
}
.selectionTable .intrayRow .paddedVersion {
  padding-left: 30px;
}
.selectionTable .inputDiv {
  margin: 0 12px 0 12px;
  height: 30px;
}
.selectionTable .arrowIconExpandDiv {
  width: 15%;
  display: flex;
  margin-left: 5px;
  margin-top: 8px;
}
.selectionTable .arrowIconExpandText {
  margin-left: 6px;
  overflow-wrap: break-word;
}
.selectionTable .arrowIconExpandAll {
  height: 16px;
  width: 16px;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.selectionTable .arrowIconExpandAllExpanded {
  height: 16px;
  width: 16px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.selectionTable .arrowIconExpandAllExpanded:hover,
.selectionTable .arrowIconExpandAll:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1368px) {
  .selectionTable {
    display: flex;
    flex: 1;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    flex-direction: column;
    overflow: hidden;
    width: 98%;
  }
  .selectionTable .tableSection {
    word-wrap: break-word;
    padding: 6px 25px 6px 10px;
    position: relative;
  }
  .selectionTable .tableSection .externalIcon {
    height: 14px;
    width: 14px;
    margin-left: 5px;
    font-size: 18px;
  }
  .selectionTable .td {
    max-width: 120px;
  }
  .selectionTable .tableContainer {
    flex: 1 1 100%;
    width: 0;
    max-width: max-content;
    min-width: 98%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .selectionTable .table {
    background: white;
    font-size: 13px;
    table-layout: auto;
    width: 100%;
  }
  .selectionTable .thead {
    border-bottom: 2px solid var(--primaryColor);
  }
  .selectionTable .tr {
    border-bottom: 1px solid var(--secondaryColor);
  }
  .selectionTable .tr:hover {
    background-color: var(--secondaryColor);
  }
  .selectionTable .trSelectable {
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
  }
  .selectionTable .trSelectable:hover {
    background: #f1f1f1;
  }
  .selectionTable .inputDiv {
    margin: 0 12px 0 12px;
    height: 30px;
  }
}
@media only screen and (max-width: 920px) {
  selectionTable .inputDiv {
    margin: 0 6px 0 6px;
    height: 20px;
  }
  selectionTable .resultsPerPageSelect {
    margin: 0 5px 0 5px;
    height: 20px;
  }
  selectionTable .table {
    table-layout: fixed;
    border-top: 1px solid #ccc;
  }
  selectionTable .thead,
  selectionTable .tbody,
  selectionTable .th,
  selectionTable .td,
  selectionTable .tr,
  selectionTable .trSelectable {
    display: block;
  }
  selectionTable .thead {
    display: none;
  }
  selectionTable .tr {
    border-bottom: 1px solid var(--darkrey);
  }
  selectionTable .trSelectable {
    width: 100%;
    border-bottom: 1px solid var(--darkgrey);
  }
  selectionTable .td {
    border-bottom: 1px solid var(--secondaryColor);
    padding: 5px 0 5px 10px;
    max-width: 100%;
  }
  selectionTable .td:before {
    content: attr(title);
    font-weight: bold;
  }
  selectionTable .tableSection {
    padding-right: 50px;
    padding-left: 0;
  }
}
@media only screen and (max-width: 499px) {
  .selectionTable .table {
    font-size: 9px;
  }
}
.selectorView .selectorTextDiv {
  font-size: 13px;
  font-weight: bold;
}
.selectorView .selectorRadioButtonsDiv {
  display: flex;
  flex-direction: row;
  border: 1px solid #38429C;
  width: 100%;
  margin-right: 5px;
  margin-top: 2%;
}
.selectorView .selectorRadioButtonsDiv .selectorRadioButtonsLeftSubDiv {
  display: flex;
  flex-direction: row;
  width: 50%;
}
.selectorView .selectorRadioButtonsDiv .selectorRadioButtonsRightSubDiv {
  display: flex;
  flex-direction: row;
  width: 50%;
}
.selectorView .selectorRadioButtonsDiv .selectorRadioButtonsText {
  font-size: 12px;
  text-align: center;
}
.selectorView .selectorRadioButtonsDiv .selectorRadioButtonSelected {
  background-color: #38429C;
  height: 35px;
  color: #ffffff;
  width: 100%;
  border: none;
  padding: 8px;
}
.selectorView .selectorRadioButtonsDiv .selectorRadioButtonUnSelected {
  background-color: #ffffff;
  color: #38429C;
  height: 35px;
  width: 100%;
  border: none;
  padding: 8px;
}

.selectorViewDisabled .selectorTextDiv {
  font-size: 13px;
  font-weight: bold;
}
.selectorViewDisabled .selectorRadioButtonsDiv {
  display: flex;
  flex-direction: row;
  border: 1px solid #B1B1B1;
  width: 100%;
  margin-right: 5px;
  margin-top: 2%;
}
.selectorViewDisabled .selectorRadioButtonsDiv .selectorRadioButtonsLeftSubDiv {
  display: flex;
  flex-direction: row;
  width: 50%;
}
.selectorViewDisabled .selectorRadioButtonsDiv .selectorRadioButtonsRightSubDiv {
  display: flex;
  flex-direction: row;
  width: 50%;
}
.selectorViewDisabled .selectorRadioButtonsDiv .selectorRadioButtonsText {
  font-size: 12px;
  text-align: center;
}
.selectorViewDisabled .selectorRadioButtonsDiv .selectorRadioButtonSelected {
  background-color: #B1B1B1;
  height: 35px;
  color: #ffffff;
  width: 100%;
  border: none;
  padding: 8px;
}
.selectorViewDisabled .selectorRadioButtonsDiv .selectorRadioButtonUnSelected {
  background-color: #ffffff;
  color: #B1B1B1;
  height: 35px;
  width: 100%;
  border: none;
  padding: 8px;
}

.noTopMargin {
  margin-top: 0% !important;
}

@media only screen and (max-width: 1150px) {
  .selectorsView {
    width: 100%;
    align-items: center;
  }
  .selectorsView .selectorRadioButtonsDiv {
    width: 96%;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 768px) {
  .selectorRadioButtonsText {
    font-size: calc(0.4em + 1vmin);
  }
}
.showMore {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  position: relative !important;
  white-space: nowrap;
}
.showMore .showMoreIcon {
  height: 10px;
  width: 15px;
}
.showMore .popUp {
  position: absolute;
  background: white;
  top: -42px;
  left: 30px;
  border-bottom: none;
  box-shadow: 0px 0px 5px rgba(43, 43, 43, 0.42);
  z-index: 2;
}
.showMore .popUp.singleItemPopUp {
  top: -12px;
}
.showMore .popUp.centeredSingleItemPop {
  top: 8px;
  left: -19px;
}
.showMore .popUpItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 11px;
  padding: 8px 10px 8px 10px;
  color: var(--darkgrey);
  max-height: 32px;
  min-width: 150px;
}
.showMore .icon {
  height: 12px;
  width: 12px;
  margin: 10px 10px 10px 10px;
}

@media only screen and (max-width: 768px) {
  .showMore .popUp {
    top: 0 !important;
    right: 1px !important;
    bottom: -63px !important;
    left: auto !important;
  }
}
/* Small Devices, Tablets */
@media only screen and (max-width: 767px) {
  .showMore .popUp {
    top: 0 !important;
    right: 1px !important;
    bottom: -63px !important;
    left: auto !important;
  }
}
.tableContainer {
  flex: 1 1 100%;
  width: 0;
  max-width: max-content;
  min-width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
}
.tableContainer .table {
  background: white;
  font-size: 13px;
  /*table-layout: auto;*/
  width: 100%;
}
.tableContainer .table .thead {
  border-bottom: 1px solid #38429C !important;
}
.tableContainer .table .thead tr {
  border-bottom: none;
}
.tableContainer .table .thead tr td {
  border-top: none !important;
}
.tableContainer .table .thead tr td .headerCell {
  display: flex;
  font-family: "montserratmedium", sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tableContainer .table .thead tr td .headerCell.headerCellSortable:hover {
  cursor: pointer;
}
.tableContainer .table .thead tr td .headerCell.headerCellSortable:hover img.sortIcon {
  visibility: visible;
}
.tableContainer .table .thead tr td .headerCell.headerCellSortable img.sortIcon {
  height: 15px;
  width: 20px;
  visibility: hidden;
}
.tableContainer .table .thead tr td .headerCell.headerCellSortable .sortAscendingIcon {
  height: 10px;
  width: 20px;
}
.tableContainer .table .tr {
  border-bottom: 1px solid #e5e5e5;
}
.tableContainer .table .tr .td {
  padding: 12px 10px;
  border-top: none !important;
  align-content: center;
}
.tableContainer .table .tr .td .tableCell {
  position: relative;
}
.tableContainer .table .tr .td .tableCell .arrowIconDiv {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainer .table .tr .td .tableCell .arrowIconDiv .workTitle {
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tableContainer .table .tr .td .tableCell .arrowIconDiv .externalIcon {
  font-size: 18px;
  height: 14px;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
  cursor: pointer;
}
.tableContainer .table .tr .td .tableCell .arrowIconDiv .multipleTitlesDiv {
  padding: 0px 18px 0px 0px;
  align-items: normal;
  position: relative;
  vertical-align: middle;
}
.tableContainer .table .tr .td .tableCell .arrowIconDiv .statementDownloadIcon {
  font-size: 18px;
  height: 22px;
  width: 22px;
  right: 0;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.tableContainer .table .tr .td .tableCell .arrowIcon {
  height: 12px;
  width: 12px;
  margin-right: 5px;
}
.tableContainer .table .tr .td .tableCell .arrowIconExpanded {
  height: 12px;
  width: 12px;
  margin-right: 5px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.tableContainer .table .tr .td .tableCell .arrowIconExpanded:hover,
.tableContainer .table .tr .td .tableCell .arrowIcon:hover {
  cursor: pointer;
}
.tableContainer .table .tr .td .tableCell .multipleTitlesDiv {
  display: flex;
  flex-direction: column;
}
.tableContainer .table .tr .td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainer .table .tr .td .tableCell .icon {
  font-size: 16px;
  margin: 0 8px 0 0;
}
.tableContainer .table .tr .td .tableCell select, .tableContainer .table .tr .td .tableCell input {
  width: 100%;
}
.tableContainer .table .tr .td .tableCell.statusCompleted {
  color: #008f59;
  display: flex;
  align-items: center;
}
.tableContainer .table .tr .td .tableCell.statusInprogress {
  color: #2e6bcd;
  display: flex;
  align-items: center;
}
.tableContainer .table .tr .td .tableCell.statusError {
  color: #ff6070;
  display: flex;
  align-items: center;
}
.tableContainer .table .tr .td .tableCell.statusNotStarted {
  color: #B1B1B1;
  display: flex;
  align-items: center;
}
.tableContainer .table .tr .td .tableCell.statusCancelled {
  color: #ff9800;
  display: flex;
  align-items: center;
}
.tableContainer .table .tr .td .openCell {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainer .table .tr .alignRight {
  text-align: right;
  padding-right: 25px !important;
}
.tableContainer .table .tr:hover {
  background-color: #f5f5f5;
}
.tableContainer .table .tr .openCell .externalIcon {
  align-self: center;
  font-size: 18px;
  height: 14px;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
  cursor: pointer;
}
.tableContainer .table.contributors tbody tr td:nth-of-type(1) .tableCell {
  margin-top: 12px;
}
.tableContainer .table.contributors tbody tr td:nth-of-type(4) .tableCell input[type=text], .tableContainer .table.contributors tbody tr td:nth-of-type(4) .tableCell input[type=number], .tableContainer .table.contributors tbody tr td:nth-of-type(4) .tableCell input[type=tel] {
  min-width: 270px;
}
.tableContainer .table.contributors tbody tr td.extraWide input[type=text] {
  min-width: 160px;
  max-width: 200px;
}
.tableContainer .table.contributors tbody tr td .tableCell input[type=text], .tableContainer .table.contributors tbody tr td .tableCell input[type=number], .tableContainer .table.contributors tbody tr td .tableCell input[type=tel] select {
  min-width: 80px;
}
.tableContainer .table.contributors tbody tr td .tableCell input[type=number] {
  text-align: right;
  padding-right: 0px !important;
}
.tableContainer .table.contributors tbody tr td .tableCell input[type=tel] {
  text-align: right;
  padding-right: 0px !important;
}
.tableContainer .table.contributors tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainer .table.contributors tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainer .table.contributors tbody tr td .tableCell.hasIcon input[type=text], .tableContainer .table.contributors tbody tr td .tableCell.hasIcon input[type=number], .tableContainer .table.contributors tbody tr td .tableCell.hasIcon input[type=tel] select {
  width: auto;
  max-width: 110px;
}
.tableContainer .table.contributors tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainer .table.contributors tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainer .table.jobsGrid tbody tr td .tableCell {
  max-width: 100% !important;
}
.tableContainer .table.titles tbody tr td {
  width: auto;
}
.tableContainer .table.titles tbody tr td .tableCell {
  width: auto;
}
.tableContainer .table.titles tbody tr td:nth-of-type(3) {
  width: 70%;
}
.tableContainer .table.titles tbody tr td:nth-of-type(3) .tableCell {
  max-width: unset;
}
.tableContainer .table.titles tbody tr td:nth-of-type(3) .tableCell div {
  width: 100%;
}
.tableContainer .table.titles tbody tr td:nth-of-type(3) .tableCell div input[type=text], .tableContainer .table.titles tbody tr td:nth-of-type(3) .tableCell div input[type=number] {
  width: 100%;
}
.tableContainer .table.comments tbody tr td:nth-of-type(1) {
  width: 15%;
}
.tableContainer .table.comments tbody tr td:nth-of-type(3) {
  width: 15%;
}
.tableContainer .table.comments tbody tr td:nth-of-type(3) .tableCell {
  max-width: unset;
}
.tableContainer .table.comments tbody tr td:nth-of-type(3) .tableCell div {
  width: 100%;
}
.tableContainer .table.comments tbody tr td:nth-of-type(3) .tableCell div input[type=text], .tableContainer .table.comments tbody tr td:nth-of-type(3) .tableCell div input[type=number] {
  width: 100%;
}
.tableContainer .table.comments tbody tr td:nth-of-type(4) {
  width: 100%;
}
.tableContainer .table.comments tbody tr td:nth-of-type(4) .tableCell {
  max-width: 100%;
}
.tableContainer .table.comments tbody tr [title~=Category] {
  min-width: 400px;
}
.tableContainer .table.comments tbody tr td {
  width: auto;
}
.tableContainer .table.comments tbody tr td .tableCell {
  width: auto;
}
.tableContainer .table.comments tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainer .table.comments tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainer .table.comments tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainer .table.comments tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainer .table.administeredBy tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainer .table.administeredBy tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainer .table.administeredBy tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainer .table.administeredBy tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainer .table.applicableWorks tbody tr td {
  width: auto;
  min-width: 110px;
  max-width: 300px;
}
.tableContainer .table.applicableWorks tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainer .table.applicableWorks tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainer .table.applicableWorks tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainer .table.applicableWorks tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainer .table.agreementCarveOuts tbody tr td {
  width: auto;
  min-width: 110px;
  max-width: 300px;
}
.tableContainer .table.agreementCarveOuts tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainer .table.agreementCarveOuts tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainer .table.agreementCarveOuts tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainer .table.agreementCarveOuts tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainer .table.agreementDuplicates tbody tr td {
  width: auto;
  min-width: 110px;
  max-width: 300px;
}
.tableContainer .table.agreementDuplicates tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainer .table.agreementDuplicates tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainer .table.agreementDuplicates tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: relative;
}
.tableContainer .table.matchingRepertoireWorks tbody tr td:nth-of-type(1) {
  width: 180px;
}
.tableContainer .table.matchingRepertoireWorks tbody tr td:nth-of-type(1) .tableCell {
  margin-left: 20px;
}
.tableContainer .table.matchingRepertoireWorks tbody tr td:nth-of-type(6) {
  min-width: 40px;
}
.tableContainer .table.matchingRepertoireWorks tbody tr td:nth-of-type(7) {
  min-width: 30px;
}
.tableContainer .table.matchingRepertoireWorks tbody tr td:nth-of-type(8) {
  width: 280px;
}
.tableContainer .table.matchingRepertoireWorks tbody tr td:nth-of-type(9) {
  min-width: 90px;
}
.tableContainer .table.matchingRepertoireWorks tbody tr input[type=text] {
  margin-bottom: 5px;
}
.tableContainer .table.matchingRepertoireWorks tbody tr td {
  width: auto;
  min-width: 110px;
  max-width: 300px;
}
.tableContainer .table.matchingRepertoireWorks tbody tr td .tableCell input[type=text] {
  margin-bottom: 5px;
}
.tableContainer .table.matchingRepertoireWorks tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainer .table.matchingRepertoireWorks tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainer .table.matchingRepertoireWorks tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainer .table.matchingRepertoireWorks tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainer .table.matchingRepertoireWorks tbody tr td .arrowIcon {
  display: flex;
  flex-direction: row;
  height: 12px;
  width: 12px;
  margin-right: 5px;
  margin-top: 10px;
  align-self: center;
  position: absolute;
}
.tableContainer .table.matchingRepertoireWorks tbody tr td .arrowIconExpanded {
  display: flex;
  flex-direction: row;
  height: 12px;
  width: 12px;
  margin-right: 5px;
  margin-top: 10px;
  align-self: center;
  position: absolute;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.tableContainer .table.matchingRepertoireWorks tbody tr td .arrowIconExpanded:hover,
.tableContainer .table.matchingRepertoireWorks tbody tr td .arrowIcon:hover {
  cursor: pointer;
}
.tableContainer .table.matchingRepertoireProducts tbody tr td:nth-of-type(1) {
  width: 180px;
}
.tableContainer .table.matchingRepertoireProducts tbody tr td:nth-of-type(1) .tableCell {
  margin-left: 20px;
}
.tableContainer .table.matchingRepertoireProducts tbody tr td:nth-of-type(6) {
  min-width: 40px;
}
.tableContainer .table.matchingRepertoireProducts tbody tr td:nth-of-type(7) {
  min-width: 30px;
}
.tableContainer .table.matchingRepertoireProducts tbody tr td:nth-of-type(8) {
  width: 280px;
}
.tableContainer .table.matchingRepertoireProducts tbody tr td:nth-of-type(9) {
  min-width: 90px;
}
.tableContainer .table.matchingRepertoireProducts tbody tr input[type=text] {
  margin-bottom: 5px;
}
.tableContainer .table.matchingRepertoireProducts tbody tr td {
  width: auto;
  min-width: 110px;
  max-width: 300px;
}
.tableContainer .table.matchingRepertoireProducts tbody tr td .tableCell input[type=text] {
  margin-bottom: 5px;
}
.tableContainer .table.matchingRepertoireProducts tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainer .table.matchingRepertoireProducts tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainer .table.matchingRepertoireProducts tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainer .table.matchingRepertoireProducts tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainer .table.matchingRepertoireProducts tbody tr td .arrowIcon {
  display: flex;
  flex-direction: row;
  height: 12px;
  width: 12px;
  margin-right: 5px;
  margin-top: 10px;
  align-self: center;
  position: absolute;
}
.tableContainer .table.matchingRepertoireProducts tbody tr td .arrowIconExpanded {
  display: flex;
  flex-direction: row;
  height: 12px;
  width: 12px;
  margin-right: 5px;
  margin-top: 10px;
  align-self: center;
  position: absolute;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.tableContainer .table.matchingRepertoireProducts tbody tr td .arrowIconExpanded:hover,
.tableContainer .table.matchingRepertoireProducts tbody tr td .arrowIcon:hover {
  cursor: pointer;
}
.tableContainer .table.productAVWork tbody tr td:nth-of-type(1) {
  width: 70%;
}
.tableContainer .table.productAVWork tbody tr td:nth-of-type(1) .tableCell {
  max-width: unset;
}
.tableContainer .table.productAVWork tbody tr td:nth-of-type(1) .tableCell div {
  width: 100%;
}
.tableContainer .table.productAVWork tbody tr td:nth-of-type(1) .tableCell div input[type=text], .tableContainer .table.productAVWork tbody tr td:nth-of-type(1) .tableCell div input[type=number] {
  width: 100%;
}
.tableContainer .table.productAVWork tbody tr td {
  width: auto;
}
.tableContainer .table.productAVWork tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainer .table.productAVWork tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainer .table.productAVWork tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainer .table.productAVWork tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainer .table.productWork tbody tr td:nth-of-type(4) {
  min-width: 350px;
}
.tableContainer .table.productWork tbody tr td {
  width: auto;
  min-width: 110px;
}
.tableContainer .table.productWork tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainer .table.productWork tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainer .table.productWork tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainer .table.productWork tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainer .table.productContributor tbody tr td {
  width: auto;
}
.tableContainer .table.productContributor tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainer .table.productContributor tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainer .table.productContributor tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainer .table.productContributor tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainer .table.instrumentation tbody tr td {
  width: auto;
}
.tableContainer .table.instrumentation tbody tr td .tableCell {
  width: auto;
}
.tableContainer .table.instrumentation tbody tr td:nth-of-type(1) {
  width: 20%;
}
.tableContainer .table.instrumentation tbody tr td:nth-of-type(1) .tableCell {
  max-width: unset;
}
.tableContainer .table.instrumentation tbody tr td:nth-of-type(1) .tableCell div {
  width: 100%;
}
.tableContainer .table.instrumentation tbody tr td:nth-of-type(1) .tableCell div input[type=text], .tableContainer .table.instrumentation tbody tr td:nth-of-type(1) .tableCell div input[type=number] {
  width: 100%;
}
.tableContainer .table.instrumentation tbody tr td:nth-of-type(2) {
  width: 25%;
}
.tableContainer .table.instrumentation tbody tr td:nth-of-type(2) .tableCell {
  max-width: unset;
}
.tableContainer .table.instrumentation tbody tr td:nth-of-type(2) .tableCell div {
  width: 100%;
}
.tableContainer .table.instrumentation tbody tr td:nth-of-type(2) .tableCell div input[type=text], .tableContainer .table.instrumentation tbody tr td:nth-of-type(2) .tableCell div input[type=number] {
  width: 100%;
}
.tableContainer .table.instrumentation tbody tr td:nth-of-type(3) {
  width: 500px;
  max-width: 500px;
}
.tableContainer .table.instrumentation tbody tr td:nth-of-type(3) .tableCell {
  max-width: 100%;
}
.tableContainer .table.instrumentation tbody tr td:nth-of-type(3) .tableCell div {
  max-width: 100%;
}
.tableContainer .table.instrumentation tbody tr td:nth-of-type(3) .tableCell div input[type=text], .tableContainer .table.instrumentation tbody tr td:nth-of-type(3) .tableCell div input[type=number] {
  width: 100%;
}
.tableContainer .table.artists tbody tr td:nth-of-type(1) .dropdownDataInput {
  min-width: 150px;
}
.tableContainer .table.artists tbody tr td:nth-of-type(1) .tableCell {
  min-width: 150px;
}
.tableContainer .table.artists tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainer .table.artists tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainer .table.artists tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainer .table.artists tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainer .table.artists.components tbody tr td:nth-of-type(4) {
  min-width: 350px;
}
.tableContainer .table.artists.components tbody tr td {
  width: auto;
  min-width: 110px;
}
.tableContainer .table.artists.components tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainer .table.artists.components tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainer .table.artists.components tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainer .table.artists.components tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainer .table.productPerformer tbody tr td:nth-of-type(1) {
  max-width: 200px;
}
.tableContainer .table.productPerformer tbody tr td:nth-of-type(1) .tableCell {
  max-width: 200px;
}
.tableContainer .table.productPerformer tbody tr td:nth-of-type(1) .tableCell input[type=text], .tableContainer .table.productPerformer tbody tr td:nth-of-type(1) .tableCell input[type=number] {
  min-width: 470px;
}
.tableContainer .table.productPerformer tbody tr td {
  width: auto;
}
.tableContainer .table.productPerformer tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainer .table.productPerformer tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainer .table.productPerformer tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainer .table.productPerformer tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainer .table.distributionPools tbody tr td:nth-of-type(2) .dropdownDataInput {
  min-width: 150px;
}
.tableContainer .table.distributionPools tbody tr td:nth-of-type(2) .tableCell {
  min-width: 150px;
}
@media only screen and (max-width: 920px) {
  .tableContainer .tableContainer .table tr td .tableCell {
    max-width: 100% !important;
    padding-left: 0;
    width: 100%;
  }
  .tableContainer .tableContainer .table tr td .tableCell .arrowIconDiv .workTitle {
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .tableContainer .tableContainer .table.contributors tbody tr td:nth-of-type(1) {
    position: relative;
    width: 100%;
  }
  .tableContainer .tableContainer .table.contributors tbody tr td:nth-of-type(2) {
    position: relative;
    width: 100%;
    left: 0;
  }
  .tableContainer .tableContainer .table.contributors tbody tr td:nth-of-type(3) {
    position: relative;
    left: 0;
  }
  .tableContainer .tableContainer .table.contributors tbody tr td:nth-of-type(6) .tableCell input[type=text], .tableContainer .tableContainer .table.contributors tbody tr td:nth-of-type(6) .tableCell input[type=number] {
    width: 100%;
  }
  .tableContainer .tableContainer .table.contributors tbody tr td .tableCell input[type=text], .tableContainer .tableContainer .table.contributors tbody tr td .tableCell input[type=number], .tableContainer .tableContainer .table.contributors tbody tr td .tableCell select {
    max-width: 100%;
  }
  .tableContainer .tableContainer .table.contributors tbody tr td.rowNumber {
    width: 15% !important;
  }
  .tableContainer .tableContainer .table.comments tbody tr td:nth-of-type(3) {
    position: relative;
    width: 100%;
    left: 0;
  }
  .tableContainer .tableContainer .table.comments tbody tr td .tableCell input[type=text], .tableContainer .tableContainer .table.comments tbody tr td .tableCell input[type=number], .tableContainer .tableContainer .table.comments tbody tr td .tableCell select {
    max-width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .tableContainer .tableContainer .table.contributors tbody tr td:nth-of-type(4) .tableCell input[type=text] {
    min-width: 200px !important;
  }
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #fbfbfb;
}

.ant-table-wrapper td, .ant-table-wrapper tr {
  font-size: 12px;
  font-family: "montserratregular", sans-serif;
}

.ant-table-wrapper th {
  font-size: 13px;
  font-family: "montserratmedium", sans-serif;
  font-weight: 400;
}

.ant-table-column-sorter-inner .active, .ant-table-filter-column .active {
  color: #2e6bcd !important;
}

.ant-table-cell {
  font-size: 13px;
  font-family: "montserratregular", sans-serif;
}

.ant-table-cell .hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}

.ant-table-cell .hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}

.ant-table-cell .widerField {
  min-width: 270px;
}

.ant-table-cell .widerFieldNumber {
  min-width: 150px;
}

.ant-table-cell .percentageField {
  min-width: 70px;
}

.colMaxWidth {
  max-width: 250px !important;
}

.ant-table-row-header {
  border-bottom: 1px solid #2e6bcd;
}

.nested-table {
  display: inline;
}

.nested-table .ant-table table {
  table-layout: auto;
}

.nested-table .ant-table {
  min-width: 1000px;
}

.parent-table table tr.ant-table-expanded-row > td {
  overflow-x: auto;
  overflow-y: hidden;
}

.ant-table-title .button {
  margin-top: 0 !important;
  width: 200px;
}
.ant-table-title .IconTextButton {
  padding-top: 10px !important;
}
.ant-table-title .saveContainer {
  justify-content: end !important;
}

.ant-table-cell .arrowIcon {
  display: flex;
  flex-direction: row;
  height: 12px;
  width: 12px;
  margin-right: 5px;
  align-self: center;
}
.ant-table-cell .arrowIconExpanded {
  display: flex;
  flex-direction: row;
  height: 12px;
  width: 12px;
  margin-right: 5px;
  align-self: center;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.ant-table-cell .arrowIconExpanded:hover,
.ant-table-cell .arrowIcon:hover {
  cursor: pointer;
}
.ant-table-cell .arrowIconDiv .openInNewTab {
  margin-right: 0px !important;
}

thead.ant-table-thead tr th {
  border-bottom: 1px solid #2e6bcd !important;
  background-color: #ffffff !important;
}

.ant-table-row-expand-icon-cell .icon {
  cursor: pointer;
  color: #2e6bcd;
}

.loaderContainer {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.loader {
  border: 7px solid #fbfbfb;
  border-top: 7px solid #2e6bcd;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
th.ant-table-cell {
  font-weight: 400 !important;
}

.TabsView .tabsDiv {
  padding-top: 0px;
  display: flex;
  flex-direction: row;
  background: transparent;
  /*border-bottom:1px solid $primary-color;*/
  width: 100%;
  overflow-x: auto;
}
.TabsView .tabsDiv .tab {
  align-items: center;
  border-radius: 4px 4px 0 0;
  display: flex;
  justify-content: center;
  padding: 5px;
  background-color: #ffffff;
  border-bottom: none;
  margin-right: 10px;
  font-size: 12px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
}
.TabsView .tabsDiv .tab .removeTabDiv {
  padding-left: 10px;
  font-size: 8px;
  cursor: pointer;
}
.TabsView .tabsDiv .tab .removeTabDiv.closeDisabled {
  cursor: progress;
}
.TabsView .tabsDiv .tab .tabIconType {
  fill-rule: evenodd;
}
.TabsView .tabsDiv .activeTab {
  align-items: center;
  border-radius: 4px 4px 0 0;
  background-color: #38429C;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 10px 6px;
  margin-right: 10px;
  font-size: 12px;
  white-space: nowrap;
  -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
}
.TabsView .tabsDiv .activeTab .removeTabDiv {
  padding-left: 10px;
  font-size: 8px;
  cursor: pointer;
}
.TabsView .tabsDiv .activeTab .removeTabDiv.closeDisabled {
  cursor: progress;
}
.TabsView .tabsDiv .activeTab .tabIconType {
  fill: #ffffff;
  fill-rule: evenodd;
}
.TabsView .tab-content .newWorkButton {
  position: absolute;
  right: 20px;
  top: 5px;
}
.TabsView .tab-content .isReadonly {
  cursor: not-allowed;
}
.TabsView .tab-content .newPaymentRunButton {
  position: absolute;
  right: 20px;
  top: 5px;
}

.tableContainerModal {
  flex: 1 1 100%;
  width: 0%;
  max-width: max-content;
  min-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
}
.tableContainerModal .table {
  background: white;
  font-size: 13px;
  /*table-layout: auto;*/
  width: 100%;
}
.tableContainerModal .table .thead {
  border-bottom: 1px solid #38429C !important;
}
.tableContainerModal .table .thead tr {
  border-bottom: none;
}
.tableContainerModal .table .thead tr td {
  border-top: none !important;
}
.tableContainerModal .table .thead tr td .headerCell {
  display: flex;
  font-family: "montserratmedium", sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tableContainerModal .table .thead tr td .headerCell.headerCellSortable:hover {
  cursor: pointer;
}
.tableContainerModal .table .thead tr td .headerCell.headerCellSortable:hover img.sortIcon {
  visibility: visible;
}
.tableContainerModal .table .thead tr td .headerCell.headerCellSortable img.sortIcon {
  height: 15px;
  width: 20px;
  visibility: hidden;
}
.tableContainerModal .table .thead tr td .headerCell.headerCellSortable .sortAscendingIcon {
  height: 10px;
  width: 20px;
}
.tableContainerModal .table .tr {
  border-bottom: 1px solid #e5e5e5;
}
.tableContainerModal .table .tr .td {
  padding: 12px 10px;
  border-top: none !important;
}
.tableContainerModal .table .tr .td .tableCell {
  position: relative;
}
.tableContainerModal .table .tr .td .tableCell .arrowIconDiv {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainerModal .table .tr .td .tableCell .arrowIconDiv .workTitle {
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tableContainerModal .table .tr .td .tableCell .arrowIconDiv .externalIcon {
  font-size: 18px;
  height: 14px;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
  cursor: pointer;
}
.tableContainerModal .table .tr .td .tableCell .arrowIcon {
  height: 12px;
  width: 12px;
  margin-right: 5px;
}
.tableContainerModal .table .tr .td .tableCell .arrowIconExpanded {
  height: 12px;
  width: 12px;
  margin-right: 5px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.tableContainerModal .table .tr .td .tableCell .arrowIconExpanded:hover,
.tableContainerModal .table .tr .td .tableCell .arrowIcon:hover {
  cursor: pointer;
}
.tableContainerModal .table .tr .td .tableCell .multipleTitlesDiv {
  display: flex;
  flex-direction: column;
}
.tableContainerModal .table .tr .td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainerModal .table .tr .td .tableCell .icon {
  font-size: 16px;
  margin: 0 8px 0 0;
}
.tableContainerModal .table .tr .td .tableCell select, .tableContainerModal .table .tr .td .tableCell input {
  width: 100%;
}
.tableContainerModal .table .tr .td .tableCell.statusCompleted {
  color: #008f59;
  display: flex;
  align-items: center;
}
.tableContainerModal .table .tr .td .tableCell.statusInprogress {
  color: #2e6bcd;
  display: flex;
  align-items: center;
}
.tableContainerModal .table .tr .td .tableCell.statusError {
  color: #ff6070;
  display: flex;
  align-items: center;
}
.tableContainerModal .table .tr .td .tableCell.statusNotStarted {
  color: #B1B1B1;
  display: flex;
  align-items: center;
}
.tableContainerModal .table .tr .td .tableCell.statusCancelled {
  color: #ff9800;
  display: flex;
  align-items: center;
}
.tableContainerModal .table .tr .alignRight {
  text-align: right;
  padding-right: 25px !important;
}
.tableContainerModal .table .tr:hover {
  background-color: #f5f5f5;
}
.tableContainerModal .table.contributors tbody tr td:nth-of-type(1) .tableCell {
  margin-top: 12px;
}
.tableContainerModal .table.contributors tbody tr td:nth-of-type(4) .tableCell input[type=text], .tableContainerModal .table.contributors tbody tr td:nth-of-type(4) .tableCell input[type=number], .tableContainerModal .table.contributors tbody tr td:nth-of-type(4) .tableCell input[type=tel] {
  min-width: 270px;
}
.tableContainerModal .table.contributors tbody tr td .tableCell input[type=text], .tableContainerModal .table.contributors tbody tr td .tableCell input[type=number], .tableContainerModal .table.contributors tbody tr td .tableCell input[type=tel] select {
  min-width: 80px;
  max-width: 120px;
}
.tableContainerModal .table.contributors tbody tr td .tableCell input[type=number] {
  text-align: right;
  padding-right: 0px !important;
}
.tableContainerModal .table.contributors tbody tr td .tableCell input[type=tel] {
  text-align: right;
  padding-right: 0px !important;
}
.tableContainerModal .table.contributors tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainerModal .table.contributors tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainerModal .table.contributors tbody tr td .tableCell.hasIcon input[type=text], .tableContainerModal .table.contributors tbody tr td .tableCell.hasIcon input[type=number], .tableContainerModal .table.contributors tbody tr td .tableCell.hasIcon input[type=tel] select {
  width: auto;
  max-width: 110px;
}
.tableContainerModal .table.contributors tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainerModal .table.contributors tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainerModal .table.jobsGrid tbody tr td .tableCell {
  max-width: 100% !important;
}
.tableContainerModal .table.titles tbody tr td {
  width: auto;
}
.tableContainerModal .table.titles tbody tr td .tableCell {
  width: auto;
}
.tableContainerModal .table.titles tbody tr td:nth-of-type(3) {
  width: 70%;
}
.tableContainerModal .table.titles tbody tr td:nth-of-type(3) .tableCell {
  max-width: unset;
}
.tableContainerModal .table.titles tbody tr td:nth-of-type(3) .tableCell div {
  width: 100%;
}
.tableContainerModal .table.titles tbody tr td:nth-of-type(3) .tableCell div input[type=text], .tableContainerModal .table.titles tbody tr td:nth-of-type(3) .tableCell div input[type=number] {
  width: 100%;
}
.tableContainerModal .table.comments tbody tr td:nth-of-type(1) {
  width: 15%;
}
.tableContainerModal .table.comments tbody tr td:nth-of-type(3) {
  width: 15%;
}
.tableContainerModal .table.comments tbody tr td:nth-of-type(3) .tableCell {
  max-width: unset;
}
.tableContainerModal .table.comments tbody tr td:nth-of-type(3) .tableCell div {
  width: 100%;
}
.tableContainerModal .table.comments tbody tr td:nth-of-type(3) .tableCell div input[type=text], .tableContainerModal .table.comments tbody tr td:nth-of-type(3) .tableCell div input[type=number] {
  width: 100%;
}
.tableContainerModal .table.comments tbody tr td:nth-of-type(4) {
  width: 100%;
}
.tableContainerModal .table.comments tbody tr td:nth-of-type(4) .tableCell {
  max-width: 100%;
}
.tableContainerModal .table.comments tbody tr [title~=Category] {
  min-width: 400px;
}
.tableContainerModal .table.comments tbody tr td {
  width: auto;
}
.tableContainerModal .table.comments tbody tr td .tableCell {
  width: auto;
}
.tableContainerModal .table.comments tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainerModal .table.comments tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainerModal .table.comments tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainerModal .table.comments tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainerModal .table.administeredBy tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainerModal .table.administeredBy tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainerModal .table.administeredBy tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainerModal .table.administeredBy tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainerModal .table.applicableWorks tbody tr td {
  width: auto;
  min-width: 110px;
  max-width: 300px;
}
.tableContainerModal .table.applicableWorks tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainerModal .table.applicableWorks tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainerModal .table.applicableWorks tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainerModal .table.applicableWorks tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainerModal .table.agreementDuplicates tbody tr td {
  width: auto;
  min-width: 110px;
  max-width: 300px;
}
.tableContainerModal .table.agreementDuplicates tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainerModal .table.agreementDuplicates tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainerModal .table.agreementDuplicates tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: relative;
}
.tableContainerModal .table.matchingRepertoireWorks tbody tr td {
  width: auto;
  min-width: 110px;
  max-width: 300px;
}
.tableContainerModal .table.matchingRepertoireWorks tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainerModal .table.matchingRepertoireWorks tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainerModal .table.matchingRepertoireWorks tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainerModal .table.matchingRepertoireWorks tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainerModal .table.matchingRepertoireProducts tbody tr td {
  width: auto;
  min-width: 110px;
  max-width: 300px;
}
.tableContainerModal .table.matchingRepertoireProducts tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainerModal .table.matchingRepertoireProducts tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainerModal .table.matchingRepertoireProducts tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainerModal .table.matchingRepertoireProducts tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainerModal .table.productAVWork tbody tr td:nth-of-type(1) {
  width: 70%;
}
.tableContainerModal .table.productAVWork tbody tr td:nth-of-type(1) .tableCell {
  max-width: unset;
}
.tableContainerModal .table.productAVWork tbody tr td:nth-of-type(1) .tableCell div {
  width: 100%;
}
.tableContainerModal .table.productAVWork tbody tr td:nth-of-type(1) .tableCell div input[type=text], .tableContainerModal .table.productAVWork tbody tr td:nth-of-type(1) .tableCell div input[type=number] {
  width: 100%;
}
.tableContainerModal .table.productAVWork tbody tr td {
  width: auto;
}
.tableContainerModal .table.productAVWork tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainerModal .table.productAVWork tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainerModal .table.productAVWork tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainerModal .table.productAVWork tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainerModal .table.productWork tbody tr td:nth-of-type(4) {
  min-width: 350px;
}
.tableContainerModal .table.productWork tbody tr td {
  width: auto;
  min-width: 110px;
}
.tableContainerModal .table.productWork tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainerModal .table.productWork tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainerModal .table.productWork tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainerModal .table.productWork tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainerModal .table.productContributor tbody tr td:nth-of-type(1) {
  max-width: 500px;
}
.tableContainerModal .table.productContributor tbody tr td:nth-of-type(1) .tableCell {
  max-width: 500px;
}
.tableContainerModal .table.productContributor tbody tr td:nth-of-type(1) .tableCell input[type=text], .tableContainerModal .table.productContributor tbody tr td:nth-of-type(1) .tableCell input[type=number] {
  min-width: 470px;
}
.tableContainerModal .table.productContributor tbody tr td {
  width: auto;
}
.tableContainerModal .table.productContributor tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainerModal .table.productContributor tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainerModal .table.productContributor tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainerModal .table.productContributor tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}
.tableContainerModal .table.instrumentation tbody tr td {
  width: auto;
}
.tableContainerModal .table.instrumentation tbody tr td .tableCell {
  width: auto;
}
.tableContainerModal .table.instrumentation tbody tr td:nth-of-type(1) {
  width: 20%;
}
.tableContainerModal .table.instrumentation tbody tr td:nth-of-type(1) .tableCell {
  max-width: unset;
}
.tableContainerModal .table.instrumentation tbody tr td:nth-of-type(1) .tableCell div {
  width: 100%;
}
.tableContainerModal .table.instrumentation tbody tr td:nth-of-type(1) .tableCell div input[type=text], .tableContainerModal .table.instrumentation tbody tr td:nth-of-type(1) .tableCell div input[type=number] {
  width: 100%;
}
.tableContainerModal .table.instrumentation tbody tr td:nth-of-type(2) {
  width: 25%;
}
.tableContainerModal .table.instrumentation tbody tr td:nth-of-type(2) .tableCell {
  max-width: unset;
}
.tableContainerModal .table.instrumentation tbody tr td:nth-of-type(2) .tableCell div {
  width: 100%;
}
.tableContainerModal .table.instrumentation tbody tr td:nth-of-type(2) .tableCell div input[type=text], .tableContainerModal .table.instrumentation tbody tr td:nth-of-type(2) .tableCell div input[type=number] {
  width: 100%;
}
.tableContainerModal .table.instrumentation tbody tr td:nth-of-type(3) {
  width: 500px;
  max-width: 500px;
}
.tableContainerModal .table.instrumentation tbody tr td:nth-of-type(3) .tableCell {
  max-width: 100%;
}
.tableContainerModal .table.instrumentation tbody tr td:nth-of-type(3) .tableCell div {
  max-width: 100%;
}
.tableContainerModal .table.instrumentation tbody tr td:nth-of-type(3) .tableCell div input[type=text], .tableContainerModal .table.instrumentation tbody tr td:nth-of-type(3) .tableCell div input[type=number] {
  width: 100%;
}
.tableContainerModal .table.components tbody tr td:nth-of-type(4) {
  min-width: 350px;
}
.tableContainerModal .table.components tbody tr td {
  width: auto;
  min-width: 110px;
}
.tableContainerModal .table.components tbody tr td .tableCell i {
  font-size: 16px;
}
.tableContainerModal .table.components tbody tr td .tableCell.hasIcon {
  display: flex;
  padding: 0 18px 0 0;
  flex-direction: row;
  align-items: normal;
  position: relative;
}
.tableContainerModal .table.components tbody tr td .tableCell.hasIcon .icon {
  align-self: center;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  width: 14px;
  right: 0;
  margin-left: 5px;
  position: absolute;
}
.tableContainerModal .table.components tbody tr td .tableCell[title=Remove] {
  margin-top: 6px;
}

@media only screen and (max-width: 920px) {
  .tableContainer .table tr td .tableCell {
    max-width: 100% !important;
    padding-left: 0;
    width: 100%;
  }
  .tableContainer .table tr td .tableCell .arrowIconDiv .workTitle {
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .tableContainer .table.contributors tbody tr td:nth-of-type(1) {
    position: relative;
    width: 100%;
  }
  .tableContainer .table.contributors tbody tr td:nth-of-type(2) {
    position: relative;
    width: 100%;
    left: 0;
  }
  .tableContainer .table.contributors tbody tr td:nth-of-type(3) {
    position: relative;
    left: 0;
  }
  .tableContainer .table.contributors tbody tr td:nth-of-type(6) .tableCell input[type=text], .tableContainer .table.contributors tbody tr td:nth-of-type(6) .tableCell input[type=number] {
    width: 100%;
  }
  .tableContainer .table.contributors tbody tr td .tableCell input[type=text], .tableContainer .table.contributors tbody tr td .tableCell input[type=number], .tableContainer .table.contributors tbody tr td .tableCell select {
    max-width: 100%;
  }
  .tableContainer .table.contributors tbody tr td.rowNumber {
    width: 15% !important;
  }
  .tableContainer .table.comments tbody tr td:nth-of-type(3) {
    position: relative;
    width: 100%;
    left: 0;
  }
  .tableContainer .table.comments tbody tr td .tableCell input[type=text], .tableContainer .table.comments tbody tr td .tableCell input[type=number], .tableContainer .table.comments tbody tr td .tableCell select {
    max-width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .tableContainer .table.contributors tbody tr td:nth-of-type(4) .tableCell input[type=text] {
    min-width: 200px !important;
  }
}
.commentsView {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.loadingView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.loadingView .loadingViewText {
  font-size: 20px;
  font-weight: bold;
}

.yesNoPromptView {
  margin: 10px;
  width: -webkit-fill-available;
}

@-moz-document url-prefix() {
  .yesNoPromptView {
    width: -moz-available;
  }
}
.popUpInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  /* text-align: center; */
}
.popUpInfo .popUpImage {
  margin-top: 0px;
  margin-left: 0px;
  border: 1px solid blue;
  /* float: left; */
}
.popUpInfo .popUpText {
  font-size: 15px;
  /* float: right; */
  /* display:inline-block; */
  border: 1px solid black;
}

.workNotFoundView {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.radioContainer label.radio-inline {
  margin-top: 0px;
  margin-right: 30px;
}
.radioContainer label.radio-inline input[type=radio] {
  margin-top: 1px;
}

.mapSearch {
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  width: 1000px;
  height: 40px;
  padding: 0 12px;
  margin-bottom: 8px;
  margin-top: 8px;
  border-radius: 3px;
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
}

.mapFrame {
  border: 0;
  margin-bottom: 40px;
}

.contributor-comment {
  border: none;
  background: inherit;
  font-size: 1.9em;
  padding-left: 0.8em;
  padding-right: 0.8em;
  color: #38429C;
}

/*--------------------------------------------------------------
## Works
--------------------------------------------------------------*/
.workBatchInput {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
.workBatchInput .textInput {
  flex: 1 0 0;
  margin-right: 5%;
  min-width: 100px;
}
.workBatchInput button {
  border: none;
  flex: 0 0 0;
  white-space: nowrap;
  align-items: center;
  height: 100%;
  padding: 8px 11px;
  margin: 5px;
}
.workBatchInput button.disabledButton {
  background-color: transparent;
  color: #B1B1B1;
}
.workBatchInput button.enabledButton {
  background-color: #38429C;
  color: #ffffff;
}
.workBatchInput .buttonText {
  border: none;
  white-space: nowrap;
  font-size: 12px;
  text-align: center;
  width: 100%;
}
.workBatchInput button[disabled] {
  cursor: default;
}

.matchConnectorStatusButtonWrapper {
  position: relative;
  min-width: 200px;
  max-width: 240px;
}

.matchConnectorStatusButtonWrapper .button {
  border: none !important;
  height: 100% !important;
  padding: 10px !important;
  position: absolute !important;
  width: 70%;
  margin: 0px !important;
  background: linear-gradient(135deg, #2e6bcd 12%, #5e2eab 100%);
  color: #ffffff !important;
  transition: all 0.3s ease;
  font-size: 75%;
}

.matchConnectorStatusButtonWrapper .button:disabled {
  background: #8e8d8d;
}

.matchConnectorStatusButtonWrapper .button:disabled:hover {
  box-shadow: none;
  -webkit-transition: all 0.3s ease; /* Safari */
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.WorkMaintenanceToolbar {
  height: auto;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 0 !important;
}
.WorkMaintenanceToolbar .toolbarWrapper {
  flex: 1;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  background: #f5f5f5;
  text-align: center;
}
.WorkMaintenanceToolbar .toolbarWrapper .toolbarButton {
  cursor: pointer;
}
.WorkMaintenanceToolbar .toolbarWrapper .toolbarButton .toolbarButtonContainer {
  font-size: 12px;
  padding: 8px 11px;
}
.WorkMaintenanceToolbar .toolbarWrapper .toolbarButton .toolbarButtonContainer:hover {
  background-color: #e5e5e5;
}
.WorkMaintenanceToolbar .toolbarWrapper .toolbarButton .toolbarButtonContainer.IconTextButton .disabledIcon {
  color: #B1B1B1 !important;
}
.WorkMaintenanceToolbar .toolbarWrapper .toolbarButton .toolbarButtonContainer.disabled {
  color: #B1B1B1 !important;
  cursor: not-allowed;
}
.WorkMaintenanceToolbar .toolbarWrapper .toolbarButton.disabled :hover {
  background-color: transparent;
}
.WorkMaintenanceToolbar .toolbarWrapper .saveButton {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: flex-end;
}
.WorkMaintenanceToolbar .toolbarWrapper .saveButton .saveButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.WorkMaintenanceToolbar .toolbarWrapper .saveButton .saveButtonContainer button {
  border: none;
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding: 8px 11px;
}
.WorkMaintenanceToolbar .toolbarWrapper .saveButton .saveButtonContainer button.disabledSaveButton {
  background-color: transparent;
  color: #B1B1B1;
}
.WorkMaintenanceToolbar .toolbarWrapper .saveButton .saveButtonContainer button.saveButton {
  background-color: #38429C;
  color: #ffffff;
}
.WorkMaintenanceToolbar .toolbarWrapper .saveButton .saveButtonContainer button.saveButton:hover {
  background: #2e6bcd; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #5e2eab 12%, #2e6bcd 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #5e2eab 12%, #2e6bcd 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #5e2eab 12%, #2e6bcd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="##5e2eab", endColorstr="#2e6bcd",GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  -webkit-transition: all 0.3s ease; /* Safari */
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.WorkMaintenanceToolbar .toolbarWrapper .saveButton .saveButtonContainer .saveButtonText {
  border: none;
  white-space: nowrap;
  font-size: 12px;
  text-align: center;
  width: 100%;
}
.WorkMaintenanceToolbar .toolbarWrapper .saveButton .saveButtonContainer button[disabled] {
  cursor: default;
}
.WorkMaintenanceToolbar .toolbarWrapper .saveButton .saveButtonContainer .dropdown-toggle {
  height: 100%;
  margin-left: 1px;
}
.WorkMaintenanceToolbar .toolbarWrapper .saveButton .saveButtonContainer .dropdown-toggle + .dropdown-menu {
  right: 0 !important;
  left: auto !important;
}
.WorkMaintenanceToolbar .toolbarWrapper.fixedToolbar {
  position: fixed;
  height: auto;
  z-index: 99;
  top: 115px;
  -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
}

.toolbar-toggle {
  display: none;
}

@media only screen and (max-width: 950px) {
  .WorkMaintenanceToolbar {
    height: auto !important;
    position: fixed;
    z-index: 9998;
    bottom: 0;
    margin: 0;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
  }
  .WorkMaintenanceToolbar .toolbarWrapper {
    display: block;
    height: auto;
    position: absolute;
    top: 120px;
    left: 20px;
    right: 20px;
    border-radius: 6px;
    -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
    -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
    box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  }
  .WorkMaintenanceToolbar .toolbarWrapper .toolbarButton .toolbarButtonContainer {
    padding: 11px;
  }
  .WorkMaintenanceToolbar .toolbarWrapper .saveButton {
    display: block;
    display: block;
    padding: 11px;
  }
  .WorkMaintenanceToolbar .toolbarWrapper .saveButton .saveButtonContainer button {
    padding: 11px;
  }
  .WorkMaintenanceToolbar .toolbarWrapper .saveButton .saveButtonContainer button.disabledSaveButton {
    border: 1px solid #B1B1B1;
  }
  .WorkMaintenanceToolbar.collapsing {
    overflow: hidden;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: visibility;
    -o-transition-property: visibility;
    transition-property: visibility;
  }
  .toolbar-toggle {
    border-radius: 50px;
    top: 260px;
    display: flex;
    align-items: center;
    position: fixed;
    padding: 0;
    right: 10px;
    margin-bottom: 10px;
    background-color: #38429C;
    background-image: none;
    border: 1px solid transparent;
    width: 42px;
    height: 42px;
    z-index: 99;
    -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
    -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
    box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  }
  .toolbar-toggle .icon {
    color: #ffffff;
    font-size: 22px;
    margin: auto;
  }
  .toolbar-toggle:focus {
    outline: none;
  }
  .toolbar-toggle[aria-expanded=true] {
    z-index: 9999;
  }
}
@media only screen and (min-width: 950px) {
  .closeSecondPane {
    border-radius: 50px;
    top: 260px;
    display: flex;
    align-items: center;
    padding: 0;
    right: 10px;
    margin-bottom: 10px;
    background-color: #38429C;
    background-image: none;
    border: 1px solid transparent;
    width: 42px;
    height: 42px;
    z-index: 99;
    background-color: white;
    position: absolute;
    top: -5px;
    -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
    -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
    box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  }
  .closeSecondPane .icon {
    color: #ffffff;
    font-size: 22px;
    margin: auto;
  }
  .closeSecondPane:focus {
    outline: none;
  }
  .closeSecondPane[aria-expanded=true] {
    z-index: 9999;
  }
}
@media only screen and (min-width: 951px) {
  .WorkMaintenanceToolbar {
    display: block;
    height: auto !important;
  }
  .compactView .closed-distribution-checkbox-container {
    margin-top: -30px;
  }
  .closed-distribution-checkbox-container {
    margin-top: -52px;
  }
}
.DataIngestionToolbar {
  display: block;
  height: auto !important;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 0 !important;
}
.DataIngestionToolbar .toolbarWrapper {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  background: #f5f5f5;
  text-align: center;
}
.DataIngestionToolbar .toolbarWrapper .toolbarButton {
  cursor: pointer;
}
.DataIngestionToolbar .toolbarWrapper .toolbarButton .toolbarButtonContainer {
  font-size: 12px;
  padding: 8px 11px;
}
.DataIngestionToolbar .toolbarWrapper .toolbarButton .toolbarButtonContainer:hover {
  background-color: #e5e5e5;
}
.DataIngestionToolbar .toolbarWrapper .toolbarButton .toolbarButtonContainer.IconTextButton .disabledIcon {
  color: #B1B1B1 !important;
}
.DataIngestionToolbar .toolbarWrapper .toolbarButton .toolbarButtonContainer.disabled {
  color: #B1B1B1 !important;
  cursor: not-allowed;
}
.DataIngestionToolbar .toolbarWrapper .toolbarButton.disabled :hover {
  background-color: transparent;
}
.DataIngestionToolbar .toolbarWrapper .saveButton {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: flex-end;
}
.DataIngestionToolbar .toolbarWrapper .saveButton .saveButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.DataIngestionToolbar .toolbarWrapper .saveButton .saveButtonContainer button {
  border: none;
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding: 8px 11px;
}
.DataIngestionToolbar .toolbarWrapper .saveButton .saveButtonContainer button.disabledSaveButton {
  background-color: transparent;
  color: #B1B1B1;
}
.DataIngestionToolbar .toolbarWrapper .saveButton .saveButtonContainer button.saveButton {
  background-color: #38429C;
  color: #ffffff;
}
.DataIngestionToolbar .toolbarWrapper .saveButton .saveButtonContainer button.saveButton:hover {
  background: #2e6bcd; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #5e2eab 12%, #2e6bcd 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #5e2eab 12%, #2e6bcd 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #5e2eab 12%, #2e6bcd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="##5e2eab", endColorstr="#2e6bcd",GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  -webkit-transition: all 0.3s ease; /* Safari */
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.DataIngestionToolbar .toolbarWrapper .saveButton .saveButtonContainer .saveButtonText {
  border: none;
  white-space: nowrap;
  font-size: 12px;
  text-align: center;
  width: 100%;
}
.DataIngestionToolbar .toolbarWrapper .saveButton .saveButtonContainer button[disabled] {
  cursor: default;
}
.DataIngestionToolbar .toolbarWrapper .saveButton .saveButtonContainer .dropdown-toggle {
  height: 100%;
  margin-left: 1px;
}
.DataIngestionToolbar .toolbarWrapper .saveButton .saveButtonContainer .dropdown-toggle + .dropdown-menu {
  right: 0 !important;
  left: auto !important;
}
.DataIngestionToolbar .toolbarWrapper.fixedToolbar {
  position: fixed;
  height: auto;
  z-index: 99;
  top: 115px;
  -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
}
.DataIngestionToolbar .toolbarWrapper {
  background: #ffffff;
}

.WorkMaintenanceSaveResult {
  white-space: pre-wrap;
}

code {
  background-color: #F6F7FA;
  color: #38429C;
}

.modal-dialog.codeModal {
  height: 90%;
}

.modal-dialog.codeModal .modal-content {
  height: 100%;
}

.modal-dialog.codeModal .modal-content .modal-header button {
  opacity: 1 !important;
}

.modal-dialog.codeModal .modal-content .modal-header button .icon {
  color: #38429C !important;
  opacity: 1;
}

.modal-dialog.codeModal .modal-content .modal-body {
  height: 93%;
}

.modal-dialog.codeModal .modal-content .modal-body .codeContainer {
  height: 100%;
}

.modal-dialog.codeModal .modal-content .modal-body .codeContainer .codeHeader {
  align-items: center;
  background-color: #e5e5e5;
  display: flex;
}

.modal-dialog.codeModal .modal-content .modal-body .codeContainer .codeHeader .language {
  flex-grow: 1;
  padding: 5px 8px;
}

.modal-dialog.codeModal .modal-content .modal-body .codeContainer .codeHeader .action {
  cursor: pointer;
  font-size: 12px;
  display: flex;
  padding: 5px 8px;
  align-items: center;
}

.modal-dialog.codeModal .modal-content .modal-body .codeContainer .codeHeader .action .icon {
  color: #38429C;
  font-size: 15px;
  padding: 0 5px;
}

.modal-dialog.codeModal .modal-content .modal-body .codeContainer .codeHeader .action:hover {
  background-color: #f5f5f5;
}

.modal-dialog.codeModal .modal-content .modal-body .codeContainer pre {
  background-color: #f5f5f5;
  border-radius: 0 !important;
  border: 0;
  max-height: 550px;
}

.copyMessage {
  position: relative;
  display: inline-block;
}

.copyMessage .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.copyMessage .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.copyMessage:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tableContainer .table .tr .td .tableCell.hasCode {
  display: flex;
  flex-direction: row;
  align-items: normal;
  position: relative;
}

.tableContainer .table .tr .td .tableCell.hasCode .icon {
  align-self: center;
  color: #38429C;
  cursor: pointer;
  font-size: 15px;
  height: auto;
  width: 24px;
  right: 2px;
  margin: 0;
  position: absolute;
  top: 2px;
  background: white;
  padding: 2px;
  text-align: center;
}

.tableContainer .table .tr .td .tableCell.hasCode code {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/*--------------------------------------------------------------
## Usage
--------------------------------------------------------------*/
.UsageSearchToolbar {
  height: auto;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 0 !important;
}
.UsageSearchToolbar .toolbarWrapper {
  flex: 1;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  background: #f5f5f5;
  text-align: center;
}
.UsageSearchToolbar .toolbarWrapper .toolbarButton {
  cursor: pointer;
}
.UsageSearchToolbar .toolbarWrapper .toolbarButton .toolbarButtonContainer {
  font-size: 12px;
  padding: 8px 11px;
}
.UsageSearchToolbar .toolbarWrapper .toolbarButton .toolbarButtonContainer:hover {
  background-color: #e5e5e5;
}
.UsageSearchToolbar .toolbarWrapper .toolbarButton .toolbarButtonContainer.IconTextButton .disabledIcon {
  color: #B1B1B1 !important;
}
.UsageSearchToolbar .toolbarWrapper .toolbarButton .toolbarButtonContainer.disabled {
  color: #B1B1B1 !important;
  cursor: not-allowed;
}
.UsageSearchToolbar .toolbarWrapper .toolbarButton.disabled :hover {
  background-color: transparent;
}
.UsageSearchToolbar .toolbarWrapper .saveButton {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: flex-end;
}
.UsageSearchToolbar .toolbarWrapper .saveButton .saveButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.UsageSearchToolbar .toolbarWrapper .saveButton .saveButtonContainer button {
  border: none;
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding: 8px 11px;
}
.UsageSearchToolbar .toolbarWrapper .saveButton .saveButtonContainer button.disabledSaveButton {
  background-color: transparent;
  color: #B1B1B1;
}
.UsageSearchToolbar .toolbarWrapper .saveButton .saveButtonContainer button.saveButton {
  background-color: #38429C;
  color: #ffffff;
}
.UsageSearchToolbar .toolbarWrapper .saveButton .saveButtonContainer button.saveButton:hover {
  background: #2e6bcd; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #5e2eab 12%, #2e6bcd 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #5e2eab 12%, #2e6bcd 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #5e2eab 12%, #2e6bcd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="##5e2eab", endColorstr="#2e6bcd",GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  -webkit-transition: all 0.3s ease; /* Safari */
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.UsageSearchToolbar .toolbarWrapper .saveButton .saveButtonContainer .saveButtonText {
  border: none;
  white-space: nowrap;
  font-size: 12px;
  text-align: center;
  width: 100%;
}
.UsageSearchToolbar .toolbarWrapper .saveButton .saveButtonContainer button[disabled] {
  cursor: default;
}
.UsageSearchToolbar .toolbarWrapper .saveButton .saveButtonContainer .dropdown-toggle {
  height: 100%;
  margin-left: 1px;
}
.UsageSearchToolbar .toolbarWrapper .saveButton .saveButtonContainer .dropdown-toggle + .dropdown-menu {
  right: 0 !important;
  left: auto !important;
}
.UsageSearchToolbar .toolbarWrapper.fixedToolbar {
  position: fixed;
  height: auto;
  z-index: 99;
  top: 115px;
  -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
}

.toolbar-toggle {
  display: none;
}

@media only screen and (max-width: 960px) {
  .UsageSearchToolbar {
    height: auto !important;
    position: fixed;
    z-index: 9998;
    bottom: 0;
    margin: 0;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
  }
  .UsageSearchToolbar .toolbarWrapper {
    display: block;
    height: auto;
    position: absolute;
    top: 280px;
    left: 20px;
    right: 20px;
    border-radius: 6px;
    -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
    -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
    box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  }
  .UsageSearchToolbar .toolbarWrapper .toolbarButton .toolbarButtonContainer {
    padding: 11px;
  }
  .UsageSearchToolbar .toolbarWrapper .saveButton {
    display: block;
    display: block;
    padding: 11px;
  }
  .UsageSearchToolbar .toolbarWrapper .saveButton .saveButtonContainer button {
    padding: 11px;
  }
  .UsageSearchToolbar .toolbarWrapper .saveButton .saveButtonContainer button.disabledSaveButton {
    border: 1px solid #B1B1B1;
  }
  .UsageSearchToolbar.collapsing {
    overflow: hidden;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: visibility;
    -o-transition-property: visibility;
    transition-property: visibility;
  }
  .toolbar-toggle {
    border-radius: 50px;
    top: 100px;
    display: flex;
    align-items: center;
    position: fixed;
    padding: 0;
    right: 10px;
    margin-bottom: 10px;
    background-color: #38429C;
    background-image: none;
    border: 1px solid transparent;
    width: 42px;
    height: 42px;
    z-index: 99;
    -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
    -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
    box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  }
  .toolbar-toggle .icon {
    color: #ffffff;
    font-size: 22px;
    margin: auto;
  }
  .toolbar-toggle:focus {
    outline: none;
  }
  .toolbar-toggle[aria-expanded=true] {
    z-index: 9999;
  }
}
@media only screen and (min-width: 961px) {
  .UsageSearchToolbar {
    display: block;
    height: auto !important;
  }
}
.usageRowDropdown {
  background-color: #f5f5f5;
}

.usageRowDropdown .tr {
  background-color: #f5f5f5;
}

.performanceNumbers .container {
  overflow-x: auto;
  margin: 25px 0 25px 0;
  border-bottom: 1px solid #E5E5E5;
  border-top: 1px solid #E5E5E5;
  width: 100%;
  padding: 25px 0 25px 0;
}

.performanceNumbers .leftHeader .section {
  background-color: #38429C;
  color: #ffffff;
  height: 30px;
  line-height: 30px;
  margin-top: 1px;
  margin-bottom: 0px;
}

.performanceNumbers .horizontalRow {
  border-right: 1px solid #38429C;
}

.performanceNumbers .horizontalRow .section {
  height: 30px;
  line-height: 30px;
  margin-top: 1px;
  margin-bottom: 0px;
}

.usageMaintenancePage .rowExpanded {
  max-width: 500px;
}

.usageMaintenancePage .rowUnexpanded {
  max-width: 500px;
  height: 18px;
  overflow: hidden;
}

.findUsagePage .rowExpanded {
  max-width: 500px;
}

.findUsagePage .rowUnexpanded {
  max-width: 500px;
  height: 18px;
  overflow: hidden;
}

.sizedTextDisplay {
  min-height: 70px;
}

.distributionSubjectInput button {
  border: none;
  flex: 0 0 0;
  white-space: nowrap;
  align-items: center;
  height: 100%;
  padding: 8px 11px;
  margin: 5px;
}

.distributionSubjectInput button.disabledButton {
  background-color: transparent;
  color: #B1B1B1;
}

.distributionSubjectInput button.enabledButton {
  background-color: #38429C;
  color: #ffffff;
}

.matchingButtonWrapper {
  position: relative;
}

.matchingButtonWrapper .input {
  padding-right: 70px !important;
}

.matchingButtonWrapper .button {
  border: none !important;
  height: 100% !important;
  padding: 10px !important;
  position: absolute !important;
  right: 0 !important;
  width: 70px;
  margin: 0px !important;
  background: linear-gradient(135deg, #2e6bcd 12%, #5e2eab 100%);
  color: #ffffff !important;
  transition: all 0.3s ease;
}

.matchingWorksSection {
  margin-top: 30px;
}

.contextualMenu {
  position: relative;
  z-index: 100;
  display: none;
}
.contextualMenu.show {
  display: block;
}
.contextualMenu.show .toolbarWrapper {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  -webkit-transform: translate(0%, 0%);
  -ms-transform: translate(0%, 0%);
  -o-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  opacity: 1;
}
.contextualMenu .toolbarWrapper {
  display: block;
  height: auto;
  position: absolute;
  width: 180px;
  -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
  background-color: #ffffff;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  -webkit-transform: translate(0%, -10%);
  -ms-transform: translate(0%, -10%);
  -o-transform: translate(0%, -10%);
  transform: translate(0%, -10%);
  opacity: 0;
}
.contextualMenu .toolbarWrapper .toolbarButton .toolbarButtonContainer {
  padding: 11px;
}
.contextualMenu .toolbarWrapper .toolbarButton .toolbarButtonContainer:hover {
  background-color: #f5f5f5;
}
.contextualMenu .toolbarWrapper .toolbarButton .toolbarButtonContainer.disabled {
  color: #B1B1B1 !important;
  cursor: not-allowed;
}

div.ms-ComboBox {
  background-color: #ffffff;
  height: 38px !important;
}
div.ms-ComboBox input, div.ms-ComboBox input[type=text] {
  background-color: #ffffff;
  border: none !important;
}

div.ms-ComboBox:after {
  border: 1px solid #e5e5e5 !important;
}

td div div div div.ms-ComboBox button.ms-Button {
  position: relative !important;
  padding: 14px !important;
}

.ms-Icon--Edit::before {
  content: "\e70f";
}

.ms-Icon--AcceptMedium::before {
  content: "\e8fb";
}

.openInNewTab {
  font-size: 18px;
  height: 14px;
  width: 14px;
  right: 0;
  margin-left: 0;
  margin-top: 3px;
  margin-right: 10px;
  position: absolute;
  cursor: pointer;
}

/*--------------------------------------------------------------
## Shared
--------------------------------------------------------------*/
/* layout classes */
.flexRow {
  display: flex;
  flex-direction: row;
}

.flexWrapRow {
  display: flex;
  flex-wrap: wrap;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexJustifyContentCenter {
  justify-content: center;
}

.flexJustifyContentRight {
  justify-content: right;
}

.flexJustifyContentLeft {
  justify-content: left;
}

.flexJustifyContentSpaceBetween {
  justify-content: space-between;
}

.flexJustifyContentSpaceAround {
  justify-content: space-around;
}

.flexAlignItemsStart {
  align-items: flex-start;
}

.flexAlignItemsEnd {
  align-items: flex-end;
}

.flexAlignItemsCenter {
  align-items: center;
}

/* padding */
.paddingVerySmall {
  padding: 3px;
}

.paddingSmall {
  padding: 6px;
}

.paddingMedium {
  padding: 9px;
}

.paddingLarge {
  padding: 12px;
}

.paddingVeryLarge {
  padding: 15px;
}

.paddingRightVerySmall {
  padding-right: 3px;
}

.paddingRightSmall {
  padding-right: 6px;
}

.paddingRightMedium {
  padding-right: 9px;
}

.paddingRightLarge {
  padding-right: 12px;
}

.paddingRightVeryLarge {
  padding-right: 15px;
}

.paddingLeftVerySmall {
  padding-left: 3px;
}

.paddingLeftSmall {
  padding-left: 6px;
}

.paddingLeftMedium {
  padding-left: 9px;
}

.paddingLeftLarge {
  padding-left: 12px;
}

.paddingLeftVeryLarge {
  padding-left: 15px;
}

.paddingVerticalVerySmall {
  padding-top: 3px;
  padding-bottom: 3px;
}

.paddingVerticalSmall {
  padding-top: 6px;
  padding-bottom: 6px;
}

.paddingVerticalMedium {
  padding-top: 9px;
  padding-bottom: 9px;
}

.paddingVerticalLarge {
  padding-top: 12px;
  padding-bottom: 12px;
}

.paddingVerticalVeryLarge {
  padding-top: 15px;
  padding-bottom: 15px;
}

.paddingHorizontalVerySmall {
  padding-right: 3px;
  padding-left: 3px;
}

.paddingHorizontalSmall {
  padding-right: 6px;
  padding-left: 6px;
}

.paddingHorizontalMedium {
  padding-right: 9px;
  padding-left: 9px;
}

.paddingHorizontalLarge {
  padding-right: 12px;
  padding-left: 12px;
}

.paddingHorizontalVeryLarge {
  padding-right: 15px;
  padding-left: 15px;
}

/* margin */
.marginVerySmall {
  margin: 3px;
}

.marginSmall {
  margin: 6px;
}

.marginMedium {
  margin: 9px;
}

.marginLarge {
  margin: 12px;
}

.marginVeryLarge {
  margin: 15px;
}

.marginVerticalVerySmall {
  margin-top: 3px;
  margin-bottom: 3px;
}

.marginVerticalSmall {
  margin-top: 6px;
  margin-bottom: 6px;
}

.marginVerticalMedium {
  margin-top: 9px;
  margin-bottom: 9px;
}

.marginVerticalLarge {
  margin-top: 12px;
  margin-bottom: 12px;
}

.marginVerticalVeryLarge {
  margin-top: 15px;
  margin-bottom: 15px;
}

.marginHorizontalVerySmall {
  margin-right: 3px;
  margin-left: 3px;
}

.marginHorizontalSmall {
  margin-right: 6px;
  margin-left: 6px;
}

.marginHorizontalMedium {
  margin-right: 9px;
  margin-left: 9px;
}

.marginHorizontalLarge {
  margin-right: 12px;
  margin-left: 12px;
}

.marginHorizontalVeryLarge {
  margin-right: 15px;
  margin-left: 15px;
}

.resultsScrollSection {
  overflow-y: initial !important;
}

.resultsScrollSectionArea {
  height: 35vh;
  overflow-y: scroll;
}

.widthFull {
  width: 100%;
}

.widthHalf {
  width: 50%;
}

/* LOADER  */
.loaderWrapper {
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  z-index: 3000;
  position: fixed;
  background: rgba(0, 0, 0, 0.05);
}

.spinner-dualball {
  width: 20%;
  height: 20%;
  position: absolute;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
  margin: auto auto;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.spinner-dualball div {
  box-sizing: content-box;
}

@keyframes spinner-dualball-o {
  0% {
    opacity: 1;
    transform: translate(0 0);
  }
  50% {
    opacity: 1;
    transform: translate(70px, 0);
  }
  50% {
    opacity: 0;
    transform: translate(70px, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}
@keyframes spinner-dualball {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(70px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.spinner-dualball div {
  position: absolute;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  right: 0;
  left: -70px;
  margin: auto;
}
.spinner-dualball span.text {
  text-align: center;
  position: absolute;
  bottom: 10%;
  right: 0;
  left: 0;
}

.spinner-dualball div:nth-child(1) {
  background: rgba(56, 66, 156, 0.8366826074);
  animation: spinner-dualball 0.9345794393s linear infinite;
  animation-delay: -0.4672897196s;
}

.spinner-dualball div:nth-child(2) {
  background: rgba(46, 107, 205, 0.8366826074);
  animation: spinner-dualball 0.9345794393s linear infinite;
  animation-delay: 0s;
}

.spinner-dualball div:nth-child(3) {
  background: rgba(56, 66, 156, 0.8366826074);
  animation: spinner-dualball-o 0.9345794393s linear infinite;
  animation-delay: -0.4672897196s;
}

/* ant style adaptions  */
.ant-picker-large .ant-picker-input > input {
  font-size: 13px !important;
}

.borderTopGreyLine {
  border-top: 1px solid #c7c7c7;
}

.messageBox {
  position: absolute;
  left: -150px;
  opacity: 0.7px;
  background-color: #F6F7FA;
  border: 1px solid #65C36C;
  padding: 6px;
  border-radius: 4px;
  font-size: 12px;
  color: #65C36C;
  z-index: 10;
  width: 140px;
  text-align: center;
}

.addtopackagesuccessMessage {
  border: 1px solid #65C36C;
  color: #65C36C;
}

.addtopackageerrorMessage {
  border: 1px solid #D9534F;
  color: #D9534F;
}

.package-form {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #3d3d3d;
  border-radius: 8px;
  background-color: #F6F7FA;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-row label {
  font-weight: bold;
  margin-right: 10px;
}

.form-row span {
  font-size: 16px;
  color: #555;
}

.form-row select,
.form-row input {
  padding: 5px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
}

.form-actions button {
  padding: 10px 20px;
  margin-left: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-actions button:first-child {
  background-color: #f0f0f0;
  color: #555;
}

.form-actions button:last-child {
  background-color: #5b6ef5;
  color: white;
}

.button-row {
  display: flex; /* Space between the buttons */
  justify-content: flex-start; /* Align buttons to the start (left) */
  align-items: center; /* Align items vertically in the center */
  margin-top: 10px; /* Optional: Add margin to the top for spacing */
}

.btn-outline-defaultPrimary {
  background-color: white;
  color: #38429C;
  border: 1px solid #38429C;
}

.info-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; /* Align vertically in the center */
}

.info-item {
  display: flex;
  flex-direction: column;
  margin-right: 20px; /* Space between items */
}

.bold-label label {
  font-weight: bold;
}

.TabsViewSplitScreen {
  max-width: 50%;
}

/* font */
.standardText {
  color: #202020;
  font-weight: 400;
  font-family: "montserratregular", sans-serif;
}

.overflowEllipsis {
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fontHeader {
  color: #202020;
  font-family: "montserratmedium", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

/* table */
.tableHeader {
  border-bottom: 1px solid #38429C !important;
  font-family: "montserratmedium", sans-serif;
  white-space: nowrap;
}

.tableData {
  border-top: none !important;
}

.tableRow {
  border-bottom: 1px solid #e5e5e5;
}

.rowNotExpanded {
  max-width: 100px;
  overflow: hidden;
}

.arrowIconExpandAllNotExpanded {
  height: 16px;
  width: 16px;
  transform: rotate(-90deg);
}

.arrowIconExpandAllAreExpanded {
  height: 16px;
  width: 16px;
  transform: rotate(90deg);
}

.arrowIconExpandDivDisplay {
  width: 15%;
  display: flex;
  margin-left: 5px;
  margin-top: 8px;
  padding-right: 10px;
}

.arrowIconTableLine {
  height: 12px;
  width: 12px;
  margin-right: 5px;
}

/* formik */
.invalidForkmikField {
  border: 1px solid red;
  width: fit-content;
  border-radius: 3px;
}

.formItemsContainer {
  display: flex;
  flex-wrap: wrap;
}

.formItem {
  flex: 0 0 33.33%;
  box-sizing: border-box;
}

.validationForkmikField {
  border: 1px solid red;
  border-radius: 3px;
}

.emptyValidationBorder {
  border: 1px;
}

.heightBox {
  height: 35px;
}

.checkBoxSize {
  height: 28px;
  padding: 1px;
  width: 28px;
  padding-left: 25px !important;
  margin-bottom: 26px !important;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d1cfcf;
  border-radius: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "montserratregular", sans-serif !important;
}

.editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "montserratregular", sans-serif !important;
}

.checkBoxContainerSize {
  height: 28px;
  padding: 1px;
  width: 28px;
  margin: 0px !important;
}

.ant-input-number {
  height: 37px;
}

/* view preference */
.compactView {
  /* Checkboxes */
}
.compactView .ant-input-number {
  height: 28px !important;
}
.compactView .form-group {
  margin-bottom: 5px !important;
}
.compactView .form-group label {
  line-height: 1;
}
.compactView input[type=text],
.compactView input[type=date],
.compactView input[type=number],
.compactView input[type=time],
.compactView textarea,
.compactView select {
  height: 28px !important;
}
.compactView .ms-ComboBox {
  height: 28px !important;
}
.compactView .checkOptions .radioBox {
  width: 28px !important;
  height: 28px !important;
}
.compactView .checkOptions .radioBox .checkmark {
  height: 28px !important;
  width: 28px !important;
}
.compactView .checkOptions .radioBox .checkmark:after {
  left: 10px;
  top: 3px;
  width: 10px;
  height: 18px;
}
.compactView .ant-picker {
  height: 28px !important;
}
.compactView .ms-Dropdown {
  height: 28px !important;
}
.compactView .ms-Dropdown-title {
  height: 28px !important;
}
.compactView .ant-select-selector {
  height: 28px !important;
  border-radius: 0px !important;
}
.compactView .treeVewSelector {
  height: 28px !important;
  border-radius: 0px !important;
}
.compactView .parent-table .ant-table-tbody .ant-table-cell {
  padding: 5px !important;
  line-height: 1.1;
}
.compactView .checkboxContainer .subContainer {
  height: 15px;
  padding-left: 20px;
  /* Custom checkbox */
}
.compactView .checkboxContainer .subContainer .inputCheckbox {
  height: 15px;
  width: 15px;
}
.compactView .checkboxContainer .subContainer .inputCheckbox::after {
  left: 4px;
  top: 2px;
  width: 6px;
  height: 10px;
}
.compactView .checkboxContainer .formItemsContainer .flexRow label {
  margin: 0;
}
.compactView .parent-table .ant-table-content .ant-table-thead .ant-table-cell {
  padding: 5px !important;
}
.compactView .ant-table-tbody .ant-table-tbody tr td {
  padding: 5px !important;
  line-height: 1.1;
}
.compactView .ant-table-tbody .ant-table-tbody {
  padding: 0 !important;
}
.compactView .ant-table-tbody .ant-table-tbody > tr > td {
  padding: 5px !important;
}
.compactView .ant-select-selctor {
  border-radius: 0;
  height: 28px;
  line-height: 1.1;
}
.compactView .splitButton .button {
  padding: 5px !important;
}
.compactView .tableContainer .table .tr .td {
  padding: 5px 5px !important;
}
.compactView .button {
  padding: 6px !important;
}
.compactView .AccordionContainer .itemAccordion {
  padding: 7px 0 7px 0 !important;
}
.compactView .selectorView .selectorRadioButtonsDiv .selectorRadioButtonSelected, .compactView .selectorView .selectorRadioButtonsDiv .selectorRadioButtonUnSelected {
  height: 28px !important;
  padding: 5px !important;
}

.treeVewSelector {
  width: 100%;
  min-width: 200px;
  height: 38px !important;
}

.ant-select-selector {
  width: 100%;
  min-width: 200px;
  height: 38px !important;
  border-radius: 0px !important;
}

.ant-pagination-options .ant-select-selector {
  min-width: min-content;
  height: 28px !important;
  border-radius: 0px;
}

.parent-table {
  font-size: 13px;
  font-synthesis: none;
}

.expandCollapseButtonPosition {
  position: relative;
  top: 42px;
  z-index: 3000;
}

.scheduledJobView {
  overflow: hidden;
}

.focusedTableRow {
  background: #f5f5f5;
}

.modalLarge {
  width: 1300px;
}

.noTopCorrection {
  top: auto !important;
}

.datePickerSize {
  min-width: 200px;
  margin: 0 !important;
}

.hideCountryOrRegion {
  display: none !important;
}

