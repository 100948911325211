.showMore {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    position: relative !important;
    white-space: nowrap;

    .showMoreIcon {
        height: 10px;
        width: 15px;
    }

    .popUp {
        position: absolute;
        background: white;
        top: -42px;
        &.singleItemPopUp {
            top: -12px;
        }
        &.centeredSingleItemPop {
            top: 8px;
            left: -19px;
        }
        left: 30px;
        border-bottom: none;
        box-shadow: 0px 0px 5px rgba(43, 43, 43, 0.42);
        z-index: 2;
    }

    .popUpItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 11px;
        padding: 8px 10px 8px 10px;
        color: var(--darkgrey);
        max-height: 32px;
        min-width: 150px;
    }

    .icon {
        height: 12px;
        width: 12px;
        margin: 10px 10px 10px 10px;
    }
}

@media only screen and (max-width: 768px) {
    .showMore .popUp {
        top: 0 !important;
        right: 1px !important;
        bottom: -63px !important;
        left: auto !important;
    }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 767px) {
    .showMore .popUp {
        top: 0 !important;
        right: 1px !important;
        bottom: -63px !important;
        left: auto !important;
    }
}
