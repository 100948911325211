.actionButton {
    width: 250px;
    height: 28px;
    margin-bottom: 25px;
    color: white;
    margin-top: 25px;
    margin-right: 15px;
    outline: none;
    align-self: flex-end;
    font-size: 12px;
    border: none;
    background-color: var(--primaryColor);
}
  
  .actionButton:hover {
    -webkit-transition: all 0.3s;
    /* Safari */
    transition: all 0.3s;
    -webkit-box-shadow: 0px 9px 27px -6px rgba(43, 43, 43, 0.42);
    -moz-box-shadow: 0px 9px 27px -6px rgba(43, 43, 43, 0.42);
    box-shadow: 0px 9px 27px -6px rgba(43, 43, 43, 0.42);
  }
  