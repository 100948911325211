.treeview{
	font-size:$font__size_small;
	line-height: 1.5;
	 span.indent {
		margin-left: 10px;
		margin-right: 10px;
	}
	.list-group{
		box-shadow: none;

		.list-group-item{
			border-radius:$b-radius-value !important;
			cursor: pointer;
			margin-bottom: 1px;
			padding: 10px 5px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			display: flex;
			align-items: center;

			&:first-child{
				border-radius:$b-radius-value !important;
			}
			&:last-child{
				border-radius:$b-radius-value !important;
			}
			&.node-selected{
				background-color: $primary-color !important;
			}
			span{
				&.icon {
					margin-right:4px;
					width: 12px;
					

					&.chevron{
						font-size: $font__size_xsmall;
						width: 12px;
						
					}
				}
				&.indent {
				    margin-left: 6px;
				    margin-right: 6px;
				}
			    &.badge{
			    	background-color:$secondary-color;
					font-size:$font__size_xsmall;
					font-weight:400;
					display: flex;
					flex-direction: row-reverse;
					align-items: center;
					margin-left: auto;
					min-width: auto;

					.icon{
						margin:0 0 0 3px;
						font-weight: 600;
					}

				}
				&.text{
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

				}
			}
			a{
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

			}
			
			
		}



	}
}


.treeview{
	.node-disabled {
		color: silver;
		cursor: not-allowed;
	}
}

.ms-Icon--SyncOccurence {
	animation-name: spin;
	animation-duration: 5000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	width: -ms-10px;
	height: -ms-10px;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}