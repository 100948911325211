.ant-picker-dropdown {
    z-index: 1300;
  }

.repertoireModal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(105, 105, 105, 0.37);
    z-index: 1051;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal {
        width: 80%;
        min-width: 70%;
        max-width: 700px;
        max-height: 80%;
        min-height: 20%;
        background: white;
        display: flex;
        flex-flow: column;
        align-items: center;
        position: relative;

        .fixedPart {
            flex-shrink: 0;
            margin-top: 30px;
        }

        .dynamicPart {
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            padding: 0 10px 0 10px;
            width: 100%;
        }

        .modalBody {
            background: white;
            flex: 1;
        }

        .closeButton {
            position: absolute;
            top: 5px;
            right: 7px;
            cursor: pointer;
        }

        .newWorkButton {
            display: flex;
            justify-content: end;
        }

        .title {
            display: flex;
            justify-content: center;
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 15px;
        }

        .closeIcon {
            width: 12px;
            height: 12px;
        }

        .doneButtonRow {
            width: 95%;
            display: flex;
            justify-content: end;
            padding-bottom: 10px;

            .doneButton {
                width: 20%;
            }
        }
    }

    .importData {
        min-height: 29% !important;
        overflow-y: auto;
    }
}

.selectionCriteriaOptions{
    display: flex;
    gap: 12px;
    flex-direction: column;
}

.noFlex {
    display: block !important;
}

.centerTittle {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 499px) {
    .repertoireModal {
        .modal {
            min-width: 95%;
            max-height: 95%;
        }
    }
}
