﻿.popUpInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    /* text-align: center; */

    .popUpImage {
        margin-top: 0px;
        margin-left: 0px;
        border: 1px solid blue;
        /* float: left; */
    }

    .popUpText {
        font-size: 15px;
        /* float: right; */
        /* display:inline-block; */
        border: 1px solid black;
    }
}
