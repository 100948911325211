.openInNewTab {
    font-size: 18px;
    height: 14px;
    width: 14px;
    right: 0;
    margin-left: 0;
    margin-top: 3px;
    margin-right: 10px;
    position: absolute;
    cursor: pointer;
}