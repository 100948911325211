.disabledItem {
    color: $disabled-color;
}

// FLEX FORMS{
.flexContent {
    label {
        margin: 0 8px;
    }

    input[type="text"], input[type="date"],input[type="time"], textarea, select {
        width: auto !important;
        margin: 0 8px;
        flex-grow: 1;
    }
}
//FORMS
input[type="text"]{
    height: 38px;
    padding: 8px 40px 8px 8px;
    width: 100%;
}
input[type="time"]{
    height: 38px;
    padding: 8px 40px 8px 8px;
    width: 100%;
}
input[type="number"] {
    height: 38px;
    padding: 8px 40px 8px 8px;
    width: 100%;
}
input[type="tel"] {
    height: 38px;
    padding: 8px 40px 8px 8px;
    width: 100%;
}
input[type="date"] {
    height: 38px;
    padding: 8px 40px 8px 8px;
    width: 100%;
}
input[type="password"] {
    height: 38px;
    padding: 8px 40px 8px 8px;
    width: 100%;
}
label{
    font-family: $font__medium;
    font-weight: normal;
    margin: 8px 0 5px;
}
select{
   	height: 38px;
    padding: 8px 40px 8px 8px;

    &:focus{
        outline: none;
    }
}
input[type="text"], input[type="number"], input[type="tel"], input[type="time"], textarea, select{
    background: $white;
    border: 1px solid $lighGray;
    padding: 7px;
    width: 100%;
    -webkit-transition:  all 0.3s; /* Safari */
    transition: all 0.3s;

    &:focus{
	    border: 1px solid $primary-color;
        outline: none;       
	}
}
input[type="date"], textarea, select {
    background: $white;
    border: 1px solid $lighGray;
    padding: 7px;
    width: 100%;
    -webkit-transition: all 0.3s; /* Safari */
    transition: all 0.3s;

    &:focus {
        border: 1px solid $primary-color;
        outline: none;
    }
}
textarea{
    min-height: 300px;
    resize: vertical;

}
.formBox.checkOptions{
    -webkit-transition: box-shadow 0.2s ease-in-out; /* Safari */
    transition: box-shadow 0.2s ease-in-out;

    &:hover{
        @include shadowinhover;
    }
}

// FORMS WHITE BACKGROUND
.formBox{
    background: $white;
    /*border: 1px solid $lighGray;*/
    padding: 15px;
    position: relative;
    border-radius: $b-radius-value;
    @include shadow;  

    .heading{
        display: flex;
        align-items: center;
        .icon{
            font-size: 16px;
            margin:0 8px 0 0;
        }
    }
    input, input[type="text"], input[type="number"], input[type="tel"],input[type="time"] select{
        /*border:none;*/  
        
        &:focus{
            background-color:$white;
        }
    }
   
    input:disabled, input[type="text"]:disabled, input[type="number"]:disabled, input[type="tel"]:disabled,input[type="time"]:disabled, select:disabled{
        color: $disabled-color;
        background-color: #F6F7FA;
    }
    .form-group{
        select{
            display: block;
            width: 100%;
        }
    }
}

.autoBar{
    overflow-x: auto;
}

/*Check Options */
.checkOptions{

    .heading{
        display: block;
        
    }

    label{
        font-family: $font__medium;
        font-weight: 300;
        display: block;
        margin-bottom: 3px;
    }
    .itemsWrapper{
        display: block;
        position: relative;
    }
    input[type="text"]{
        height: 38px;
        padding: 8px 40px 8px 8px;
        width: 100%;

    }
    input[type="number"]{
        height: 38px;
        padding: 8px 40px 8px 8px;
        width: 100%;
    }
    .dividerOr{
        text-align: center;
        margin-top: 25px;
        line-height: 20px;
    }

    /* The radio box */
    .radioBox {
        display: block;
        position: absolute;
        /*padding-left: 35px;*/
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        margin: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width:36px;
        height:36px;
        right: 1px;
        top: 1px;

            /* Hide the browser's default radio button */
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
            }
             /* Create a custom radio button */
            .checkmark {
                position: absolute;
                top: 0;
                left:0;
                height:36px;
                width: 36px;
                background-color: #d8d8d8;
            }
            &.singleOption{
                position: relative;
                display: inline-block;
                margin-bottom: 0;

            }
    }
    /* On mouse-over, add a grey background color */
    .radioBox:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .radioBox input:checked ~ .checkmark {
        background-color: #6ac259;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        opacity: .8;
        /*display: none;*/
    }

    /* Show the indicator (dot/circle) when checked */
    .radioBox input:checked ~ .checkmark:after {
        display: block;
        opacity: 1;
    }

    /* Style the indicator (dot/circle) */
    .radioBox .checkmark:after {
        left:15px;
        top: 5px;
        width:10px;
        height: 24px;
        border: solid white;
        border-width: 0 5px 5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }/*  /. The radio box */

}/*  /. Check Options */


/* Toggle Switch */
.toggleSwitchContacontainer{
    input[type=checkbox]{
        display: none;
        height: 0;
        width: 0;
        visibility: hidden;
    }
    
    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 60px;
        height: 30px;
        background: grey;
        display: block;
        border-radius: 100px;
        position: relative;
        margin: 0;
    }
    
    label:after {
        content: '';
        position: absolute;
        top: 2px;
        left: 3px;
        width: 26px;
        height: 26px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
    }
    
    input:checked + label {
        background:$green-color;
    }
    
    input:checked + label:after {
        left: calc(100% - 3px);
        transform: translateX(-100%);
    }
    
    label:active:after {
        width:35px;
    }
}
/* Toggle Switch */ 

input[type="text"], input[type="date"], input[type="number"], input[type="tel"], input[type="time"],textarea, select{
    background: $white;
    border: 1px solid $lighGray;
    height: 38px;
    padding: 7px;
    width: 100%;
    -webkit-transition:  all 0.3s; /* Safari */
    transition: all 0.3s;
    border-radius:4px;

    &:focus{
	    border: 1px solid $primary-color;
        outline: none;
        
	}
    &.editableLabel{
        background-color: $bgGray;
        border-width: 0 0 2px 0;

        &:focus{
            background-color:$white;
        }
    }
}
.versionBox {
    background: none;
    padding: 15px;
    position: relative;
    border: none;
    box-shadow: none;
    color: white;

    span {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
}

.flexGapSm {
    gap: 10px;
}

.extraWidth {
    min-width: 19rem !important;
    padding: 12px 10px !important; 
  }