﻿.UsageSearchToolbar {
    height: auto;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
    padding: 0 !important;

    .toolbarWrapper {
        flex: 1;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        background: $bgGray;
        text-align: center;

        .toolbarButton {
            cursor: pointer;

            .toolbarButtonContainer {
                font-size: $font__size_small;
                padding: 8px 11px;

                &:hover {
                    background-color: $lighGray;
                }

                &.IconTextButton {
                    .disabledIcon {
                        color: $mediumGray !important;
                    }
                }

                &.disabled {
                    color: $mediumGray !important;
                    cursor: not-allowed;
                }
            }

            &.disabled {
                :hover {
                    background-color: transparent;
                }
            }
        }

        .saveButton {
            display: flex;
            flex: 1 1;
            flex-direction: row;
            justify-content: flex-end;

            .saveButtonContainer {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                button {
                    border: none;
                    display: flex;
                    white-space: nowrap;
                    align-items: center;
                    padding: 8px 11px;

                    &.disabledSaveButton {
                        background-color: transparent;
                        color: $mediumGray;
                    }

                    &.saveButton {
                        background-color: $primary-color;
                        color: $white;

                        &:hover {
                            @include gradientOneHover;
                            @include btnTransition;
                        }
                    }
                }

                .saveButtonText {
                    border: none;
                    white-space: nowrap;
                    font-size: $font__size_small;
                    text-align: center;
                    width: 100%;
                }

                button[disabled] {
                    cursor: default;
                }

                .dropdown-toggle {
                    height: 100%;
                    margin-left: 1px;

                    & + .dropdown-menu {
                        right: 0 !important;
                        left: auto !important;
                    }
                }
            }
        }

        &.fixedToolbar {
            position: fixed;
            height: auto;
            z-index: 99;
            top: 115px;
            @include shadow;
        }
    }
}

.toolbar-toggle {
    display: none;
}

@media only screen and (max-width: 960px) {
    .UsageSearchToolbar {
        height: auto !important;
        position: fixed;
        z-index: 9998;
        bottom: 0;
        margin: 0;
        background: rgba(black, 0.5);
        left: 0;
        top: 0;

        .toolbarWrapper {
            display: block;
            height: auto;
            position: absolute;
            top: 280px;
            left: 20px;
            right: 20px;
            border-radius: 6px;
            @include shadow;

            .toolbarButton {

                .toolbarButtonContainer {
                    padding: 11px;
                }
            }

            .saveButton {
                display: block;
                display: block;
                padding: 11px;

                .saveButtonContainer {
                    button {
                        padding: 11px;

                        &.disabledSaveButton {
                            border: 1px solid $mediumGray;
                        }
                    }
                }
            }
        }

        &.collapsing {
            overflow: hidden;
            -webkit-transition-timing-function: ease;
            -o-transition-timing-function: ease;
            transition-timing-function: ease;
            -webkit-transition-duration: .3s;
            -o-transition-duration: .3s;
            transition-duration: .3s;
            -webkit-transition-property: visibility;
            -o-transition-property: visibility;
            transition-property: visibility;
        }
    }

    .toolbar-toggle {
        border-radius: 50px;
        top: 100px;
        display: flex;
        align-items: center;
        position: fixed;
        padding: 0;
        right: 10px;
        margin-bottom: 10px;
        background-color: $primary-color;
        background-image: none;
        border: 1px solid transparent;
        width: 42px;
        height: 42px;
        z-index: 99;
        @include shadow;

        .icon {
            color: $white;
            font-size: 22px;
            margin: auto;
        }

        &:focus {
            outline: none;
        }

        &[aria-expanded="true"] {
            z-index: 9999;
        }
    }
}

@media only screen and (min-width: 961px) {
    .UsageSearchToolbar {
        display: block;
        height: auto !important;
    }
}

.usageRowDropdown {
    background-color: #f5f5f5; 
}
.usageRowDropdown .tr {
    background-color: #f5f5f5;
}
.performanceNumbers .container {
    overflow-x: auto;
    margin: 25px 0 25px 0;
    border-bottom: 1px solid #E5E5E5;
    border-top: 1px solid #E5E5E5;
    width: 100%;
    padding: 25px 0 25px 0;
}

.performanceNumbers .leftHeader .section {
    background-color: #38429C;
    color: #ffffff;
    height: 30px;
    line-height: 30px;
    margin-top: 1px;
    margin-bottom: 0px;
}

.performanceNumbers .horizontalRow {
    border-right: 1px solid #38429C;
}

.performanceNumbers .horizontalRow .section {
    height: 30px;
    line-height: 30px;
    margin-top: 1px;
    margin-bottom: 0px;
}

.usageMaintenancePage .rowExpanded {
    max-width: 500px;
}

.usageMaintenancePage .rowUnexpanded {
    max-width: 500px;
    height: 18px;
    overflow: hidden;
}

.findUsagePage .rowExpanded {
    max-width: 500px;
}

.findUsagePage .rowUnexpanded {
    max-width: 500px;
    height: 18px;
    overflow: hidden;
}

.sizedTextDisplay {
    min-height: 70px 
}

.distributionSubjectInput button {
    border: none;
    flex: 0 0 0;
    white-space: nowrap;
    align-items: center;
    height: 100%;
    padding: 8px 11px;
    margin: 5px;
}

.distributionSubjectInput button.disabledButton {
    background-color: transparent;
    color: #B1B1B1;
}

.distributionSubjectInput button.enabledButton {
    background-color: #38429C;
    color: #ffffff;
}

.matchingButtonWrapper {
    position: relative;
}
.matchingButtonWrapper .input {
    padding-right: 70px !important;
}
.matchingButtonWrapper .button {
    border: none !important;
    height: 100% !important;
    padding: 10px !important;
    position: absolute !important;
    right: 0 !important;
    width: 70px;
    margin: 0px !important;
    background: linear-gradient(135deg, #2e6bcd 12%, #5e2eab 100%);
    color: #ffffff !important;
    transition: all 0.3s ease;
}
.matchingWorksSection{
    margin-top:30px;
}