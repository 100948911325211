﻿.loadingView {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;

    .loadingViewText {
        font-size: 20px;
        font-weight: bold;
    }
}
