code {
    background-color: #F6F7FA;
    color: #38429C; 
}

.modal-dialog.codeModal { 
    height: 90%; }
    .modal-dialog.codeModal .modal-content {
      height: 100%; }
      .modal-dialog.codeModal .modal-content .modal-header button {
        opacity: 1 !important; }
        .modal-dialog.codeModal .modal-content .modal-header button .icon {
          color: #38429C !important;
          opacity: 1; }
      .modal-dialog.codeModal .modal-content .modal-body {
        height: 93%; }
        .modal-dialog.codeModal .modal-content .modal-body .codeContainer {
          height: 100%; }
          .modal-dialog.codeModal .modal-content .modal-body .codeContainer .codeHeader {
            align-items: center;
            background-color: #e5e5e5;
            display: flex; }
            .modal-dialog.codeModal .modal-content .modal-body .codeContainer .codeHeader .language {
              flex-grow: 1;
              padding: 5px 8px; }
            .modal-dialog.codeModal .modal-content .modal-body .codeContainer .codeHeader .action {
              cursor: pointer;
              font-size: 12px;
              display: flex;
              padding: 5px 8px;
              align-items: center; }
              .modal-dialog.codeModal .modal-content .modal-body .codeContainer .codeHeader .action .icon {
                color: #38429C;
                font-size: 15px;
                padding: 0 5px; }
              .modal-dialog.codeModal .modal-content .modal-body .codeContainer .codeHeader .action:hover {
                background-color: #f5f5f5; }
          .modal-dialog.codeModal .modal-content .modal-body .codeContainer pre {
            background-color: #f5f5f5;
            border-radius: 0 !important;
            border: 0;
            max-height: 550px; }
  
  .copyMessage {
    position: relative;
    display: inline-block; }
    .copyMessage .tooltiptext {
      visibility: hidden;
      width: 140px;
      background-color: #555;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      bottom: 120%;
      left: 50%;
      margin-left: -75px;
      opacity: 0;
      transition: opacity 0.3s; }
      .copyMessage .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent; }
    .copyMessage:hover .tooltiptext {
      visibility: visible;
      opacity: 1; }

      .tableContainer .table .tr .td .tableCell.hasCode {
        display: flex;
        flex-direction: row;
        align-items: normal;
        position: relative; }
        .tableContainer .table .tr .td .tableCell.hasCode .icon {
          align-self: center;
          color: #38429C;
          cursor: pointer;
          font-size: 15px;
          height: auto;
          width: 24px;
          right: 2px;
          margin: 0;
          position: absolute;
          top: 2px;
          background: white;
          padding: 2px;
          text-align: center; }
        .tableContainer .table .tr .td .tableCell.hasCode code {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical; }