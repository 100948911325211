.tableContainer {
    flex: 1 1 100%;
    width: 0;
    max-width: max-content;
    min-width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 20px;
    margin-bottom: 20px;

    .table {
        background: white;
        font-size: 13px;
        /*table-layout: auto;*/
        width: 100%;

        .thead {
            border-bottom: 1px solid $primary-color !important;

            tr {
                border-bottom: none;

                td {
                    border-top: none !important;

                    .headerCell {
                        display: flex;
                        font-family: $font__medium;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        &.headerCellSortable {
                            &:hover {
                                cursor: pointer;

                                img.sortIcon {
                                    visibility: visible;
                                }
                            }

                            img.sortIcon {
                                height: 15px;
                                width: 20px;
                                visibility: hidden;
                            }

                            .sortAscendingIcon {
                                height: 10px;
                                width: 20px;
                            }
                        }
                    }
                }
            }
        }

        .tr {
            border-bottom: 1px solid $lighGray;

            .td {
                padding: 12px 10px;
                border-top: none !important;
                align-content: center;

                .tableCell {
                    position: relative;

                    .arrowIconDiv {
                        display: flex;
                        padding: 0 18px 0 0;
                        flex-direction: row;
                        align-items: normal;
                        position: relative;

                        .workTitle {
                            white-space: normal;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        .externalIcon {
                            font-size: 18px;
                            height: 14px;
                            width: 14px;
                            right: 0;
                            margin-left: 5px;
                            position: absolute;
                            cursor: pointer;
                        }

                        .multipleTitlesDiv {
                            padding: 0px 18px 0px 0px;
                            align-items: normal;
                            position: relative;
                            vertical-align: middle;
                        }

                        .statementDownloadIcon {
                            font-size: 18px;
                            height: 22px;
                            width: 22px;
                            right: 0;
                            margin-left: 5px;
                            margin-right: 5px;
                            cursor: pointer;
                        }
                    }

                    .arrowIcon {
                        height: 12px;
                        width: 12px;
                        margin-right: 5px;
                    }

                    .arrowIconExpanded {
                        height: 12px;
                        width: 12px;
                        margin-right: 5px;
                        -webkit-transform: rotate(90deg);
                        -moz-transform: rotate(90deg);
                        -o-transform: rotate(90deg);
                        -ms-transform: rotate(90deg);
                        transform: rotate(90deg);
                    }

                    .arrowIconExpanded:hover,
                    .arrowIcon:hover {
                        cursor: pointer;
                    }

                    .multipleTitlesDiv {
                        display: flex;
                        flex-direction: column;
                    }

                    &[title="Remove"] {
                        margin-top: 6px;
                    }
                    // Status icons
                    .icon {
                        font-size: $font__size_large;
                        margin: 0 8px 0 0;
                    }

                    select, input {
                        width: 100%;
                    }
                    //Cells with Status info
                    &.statusCompleted {
                        color: $color-alert-success;
                        display: flex;
                        align-items: center;
                    }

                    &.statusInprogress {
                        color: $secondary-color;
                        display: flex;
                        align-items: center;
                    }

                    &.statusError {
                        color: $color-alert-danger;
                        display: flex;
                        align-items: center;
                    }

                    &.statusNotStarted {
                        color: $mediumGray;
                        display: flex;
                        align-items: center;
                    }

                    &.statusCancelled {
                        color: $color-alert-warning;
                        display: flex;
                        align-items: center;
                    }
                }

                .openCell {
                    display: flex;
                    padding: 0 18px 0 0;
                    flex-direction: row;
                    align-items: normal;
                    position: relative;
                }
            }

            .alignRight {
                text-align: right;
                padding-right: 25px !important;
            }

            &:hover {
                background-color: $bgGray;
            }

            .openCell {
                .externalIcon {
                    align-self: center;
                    font-size: 18px;
                    height: 14px;
                    width: 14px;
                    right: 0;
                    margin-left: 5px;
                    position: absolute;
                    cursor: pointer;
                }
            }
        }

        &.contributors {
            tbody {
                tr {
                    td:nth-of-type(1) {
                        .tableCell {
                            margin-top: 12px;
                        }
                    }

                    td:nth-of-type(4) {
                        .tableCell {
                            input[type="text"], input[type="number"], input[type="tel"] {
                                min-width: 270px;
                            }
                        }
                    }

                    td {
                        &.extraWide {
                            input[type="text"] {
                                min-width: 160px;
                                max-width: 200px;
                            }
                        }

                        .tableCell {

                            input[type="text"], input[type="number"], input[type="tel"] select {
                                min-width: 80px;
                            }

                            input[type="number"] {
                                text-align: right;
                                padding-right: 0px !important;
                            }

                            input[type="tel"] {
                                text-align: right;
                                padding-right: 0px !important;
                            }

                            i {
                                font-size: 16px;
                            }

                            &.hasIcon {
                                display: flex;
                                padding: 0 18px 0 0;
                                flex-direction: row;
                                align-items: normal;
                                position: relative;

                                input[type="text"], input[type="number"], input[type="tel"] select {
                                    width: auto;
                                    max-width: 110px;
                                }

                                .icon {
                                    align-self: center;
                                    cursor: pointer;
                                    font-size: 18px;
                                    height: auto;
                                    width: 14px;
                                    right: 0;
                                    margin-left: 5px;
                                    position: absolute;
                                }
                            }

                            &[title="Remove"] {
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }

        &.jobsGrid {
            tbody {
                tr {
                    td {
                        .tableCell {
                            max-width: 100% !important;
                        }
                    }
                }
            }
        }

        &.titles {
            tbody {
                tr {
                    td {
                        width: auto;

                        .tableCell {
                            width: auto;
                        }
                    }

                    td:nth-of-type(3) {
                        width: 70%;

                        .tableCell {
                            max-width: unset;

                            div {
                                width: 100%;

                                input[type="text"], input[type="number"] {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.comments {
            tbody {
                tr {

                    td:nth-of-type(1) {
                        width: 15%;
                    }

                    td:nth-of-type(3) {
                        width: 15%;

                        .tableCell {
                            max-width: unset;

                            div {
                                width: 100%;

                                input[type="text"], input[type="number"] {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    td:nth-of-type(4) {
                        width: 100%;

                        .tableCell {
                            max-width: 100%;
                        }
                    }

                    [title~="Category"] {
                        min-width: 400px;
                    }

                    td {
                        width: auto;

                        .tableCell {
                            width: auto;

                            i {
                                font-size: 16px;
                            }

                            &.hasIcon {
                                display: flex;
                                padding: 0 18px 0 0;
                                flex-direction: row;
                                align-items: normal;
                                position: relative;

                                .icon {
                                    align-self: center;
                                    cursor: pointer;
                                    font-size: 18px;
                                    height: auto;
                                    width: 14px;
                                    right: 0;
                                    margin-left: 5px;
                                    position: absolute;
                                }
                            }

                            &[title="Remove"] {
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }

        &.administeredBy {
            tbody {
                tr {
                    td {
                        .tableCell {

                            i {
                                font-size: 16px;
                            }

                            &.hasIcon {
                                display: flex;
                                padding: 0 18px 0 0;
                                flex-direction: row;
                                align-items: normal;
                                position: relative;

                                .icon {
                                    align-self: center;
                                    cursor: pointer;
                                    font-size: 18px;
                                    height: auto;
                                    width: 14px;
                                    right: 0;
                                    margin-left: 5px;
                                    position: absolute;
                                }
                            }

                            &[title="Remove"] {
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }

        &.applicableWorks {
            tbody {
                tr {
                    td {
                        width: auto;
                        min-width: 110px;
                        max-width: 300px;

                        .tableCell {

                            i {
                                font-size: 16px;
                            }

                            &.hasIcon {
                                display: flex;
                                padding: 0 18px 0 0;
                                flex-direction: row;
                                align-items: normal;
                                position: relative;

                                .icon {
                                    align-self: center;
                                    cursor: pointer;
                                    font-size: 18px;
                                    height: auto;
                                    width: 14px;
                                    right: 0;
                                    margin-left: 5px;
                                    position: absolute;
                                }
                            }


                            &[title="Remove"] {
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }

        &.agreementCarveOuts {
            tbody {
                tr {
                    td {
                        width: auto;
                        min-width: 110px;
                        max-width: 300px;

                        .tableCell {

                            i {
                                font-size: 16px;
                            }

                            &.hasIcon {
                                display: flex;
                                padding: 0 18px 0 0;
                                flex-direction: row;
                                align-items: normal;
                                position: relative;

                                .icon {
                                    align-self: center;
                                    cursor: pointer;
                                    font-size: 18px;
                                    height: auto;
                                    width: 14px;
                                    right: 0;
                                    margin-left: 5px;
                                    position: absolute;
                                }
                            }


                            &[title="Remove"] {
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }

        &.agreementDuplicates {
            tbody {
                tr {
                    td {
                        width: auto;
                        min-width: 110px;
                        max-width: 300px;

                        .tableCell {

                            i {
                                font-size: 16px;
                            }

                            &.hasIcon {
                                display: flex;
                                padding: 0 18px 0 0;
                                flex-direction: row;
                                align-items: normal;
                                position: relative;

                                .icon {
                                    align-self: center;
                                    cursor: pointer;
                                    font-size: 18px;
                                    height: auto;
                                    width: 14px;
                                    right: 0;
                                    margin-left: 5px;
                                    position: relative;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.matchingRepertoireWorks {
            tbody {
                tr {
                    td:nth-of-type(1) {
                        .tableCell {
                            margin-left: 20px;
                        }

                        width: 180px;
                    }


                    td:nth-of-type(6) {
                        min-width: 40px;
                    }

                    td:nth-of-type(7) {
                        min-width: 30px;
                    }

                    td:nth-of-type(8) {
                        width: 280px;
                    }

                    td:nth-of-type(9) {
                        min-width: 90px;
                    }

                    input[type="text"] {
                        margin-bottom: 5px;
                    }

                    td {
                        width: auto;
                        min-width: 110px;
                        max-width: 300px;


                        .tableCell {

                            input[type="text"] {
                                margin-bottom: 5px;
                            }

                            i {
                                font-size: 16px;
                            }

                            &.hasIcon {
                                display: flex;
                                padding: 0 18px 0 0;
                                flex-direction: row;
                                align-items: normal;
                                position: relative;

                                .icon {
                                    align-self: center;
                                    cursor: pointer;
                                    font-size: 18px;
                                    height: auto;
                                    width: 14px;
                                    right: 0;
                                    margin-left: 5px;
                                    position: absolute;
                                }
                            }


                            &[title="Remove"] {
                                margin-top: 6px;
                            }
                        }

                        .arrowIcon {
                            display: flex;
                            flex-direction: row;
                            height: 12px;
                            width: 12px;
                            margin-right: 5px;
                            margin-top: 10px;
                            align-self: center;
                            position: absolute;
                        }

                        .arrowIconExpanded {
                            display: flex;
                            flex-direction: row;
                            height: 12px;
                            width: 12px;
                            margin-right: 5px;
                            margin-top: 10px;
                            align-self: center;
                            position: absolute;
                            -webkit-transform: rotate(90deg);
                            -moz-transform: rotate(90deg);
                            -o-transform: rotate(90deg);
                            -ms-transform: rotate(90deg);
                            transform: rotate(90deg);
                        }

                        .arrowIconExpanded:hover,
                        .arrowIcon:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        &.matchingRepertoireProducts {
            tbody {
                tr {
                    td:nth-of-type(1) {
                        .tableCell {
                            margin-left: 20px;
                        }

                        width: 180px;
                    }


                    td:nth-of-type(6) {
                        min-width: 40px;
                    }

                    td:nth-of-type(7) {
                        min-width: 30px;
                    }

                    td:nth-of-type(8) {
                        width: 280px;
                    }

                    td:nth-of-type(9) {
                        min-width: 90px;
                    }

                    input[type="text"] {
                        margin-bottom: 5px;
                    }

                    td {
                        width: auto;
                        min-width: 110px;
                        max-width: 300px;


                        .tableCell {

                            input[type="text"] {
                                margin-bottom: 5px;
                            }

                            i {
                                font-size: 16px;
                            }

                            &.hasIcon {
                                display: flex;
                                padding: 0 18px 0 0;
                                flex-direction: row;
                                align-items: normal;
                                position: relative;

                                .icon {
                                    align-self: center;
                                    cursor: pointer;
                                    font-size: 18px;
                                    height: auto;
                                    width: 14px;
                                    right: 0;
                                    margin-left: 5px;
                                    position: absolute;
                                }
                            }


                            &[title="Remove"] {
                                margin-top: 6px;
                            }
                        }

                        .arrowIcon {
                            display: flex;
                            flex-direction: row;
                            height: 12px;
                            width: 12px;
                            margin-right: 5px;
                            margin-top: 10px;
                            align-self: center;
                            position: absolute;
                        }

                        .arrowIconExpanded {
                            display: flex;
                            flex-direction: row;
                            height: 12px;
                            width: 12px;
                            margin-right: 5px;
                            margin-top: 10px;
                            align-self: center;
                            position: absolute;
                            -webkit-transform: rotate(90deg);
                            -moz-transform: rotate(90deg);
                            -o-transform: rotate(90deg);
                            -ms-transform: rotate(90deg);
                            transform: rotate(90deg);
                        }

                        .arrowIconExpanded:hover,
                        .arrowIcon:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        &.productAVWork {
            tbody {
                tr {


                    td:nth-of-type(1) {
                        width: 70%;

                        .tableCell {
                            max-width: unset;

                            div {
                                width: 100%;

                                input[type="text"], input[type="number"] {
                                    width: 100%;
                                }
                            }
                        }
                    }


                    td {
                        width: auto;

                        .tableCell {

                            i {
                                font-size: 16px;
                            }

                            &.hasIcon {
                                display: flex;
                                padding: 0 18px 0 0;
                                flex-direction: row;
                                align-items: normal;
                                position: relative;

                                .icon {
                                    align-self: center;
                                    cursor: pointer;
                                    font-size: 18px;
                                    height: auto;
                                    width: 14px;
                                    right: 0;
                                    margin-left: 5px;
                                    position: absolute;
                                }
                            }



                            &[title="Remove"] {
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }

        &.productWork {
            tbody {
                tr {

                    td:nth-of-type(4) {
                        min-width: 350px;
                    }

                    td {
                        width: auto;
                        min-width: 110px;

                        .tableCell {

                            i {
                                font-size: 16px;
                            }

                            &.hasIcon {
                                display: flex;
                                padding: 0 18px 0 0;
                                flex-direction: row;
                                align-items: normal;
                                position: relative;

                                .icon {
                                    align-self: center;
                                    cursor: pointer;
                                    font-size: 18px;
                                    height: auto;
                                    width: 14px;
                                    right: 0;
                                    margin-left: 5px;
                                    position: absolute;
                                }
                            }


                            &[title="Remove"] {
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }

        &.productContributor {
            tbody {
                tr {
                    // td:nth-of-type(1) {
                    //     max-width: 500px;

                    //     .tableCell {
                    //         max-width: 500px;

                    //         input[type="text"], input[type="number"] {
                    //             min-width: 470px;
                    //         }
                    //     }
                    // }



                    td {
                        width: auto;

                        .tableCell {

                            i {
                                font-size: 16px;
                            }

                            &.hasIcon {
                                display: flex;
                                padding: 0 18px 0 0;
                                flex-direction: row;
                                align-items: normal;
                                position: relative;

                                .icon {
                                    align-self: center;
                                    cursor: pointer;
                                    font-size: 18px;
                                    height: auto;
                                    width: 14px;
                                    right: 0;
                                    margin-left: 5px;
                                    position: absolute;
                                }
                            }



                            &[title="Remove"] {
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }

        &.instrumentation {
            tbody {
                tr {
                    td {
                        width: auto;

                        .tableCell {
                            width: auto;
                        }
                    }

                    td:nth-of-type(1) {
                        width: 20%;

                        .tableCell {
                            max-width: unset;

                            div {
                                width: 100%;

                                input[type="text"], input[type="number"] {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    td:nth-of-type(2) {
                        width: 25%;

                        .tableCell {
                            max-width: unset;

                            div {
                                width: 100%;

                                input[type="text"], input[type="number"] {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    td:nth-of-type(3) {
                        width: 500px;
                        max-width: 500px;

                        .tableCell {
                            max-width: 100%;

                            div {
                                max-width: 100%;

                                input[type="text"], input[type="number"] {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.artists {
            tbody {
                tr {
                    td:nth-of-type(1) {

                        .dropdownDataInput {
                            min-width: 150px;
                        }

                        .tableCell {
                            min-width: 150px;
                        }
                    }


                    td {
                        .tableCell {


                            i {
                                font-size: 16px;
                            }

                            &.hasIcon {
                                display: flex;
                                padding: 0 18px 0 0;
                                flex-direction: row;
                                align-items: normal;
                                position: relative;

                                .icon {
                                    align-self: center;
                                    cursor: pointer;
                                    font-size: 18px;
                                    height: auto;
                                    width: 14px;
                                    right: 0;
                                    margin-left: 5px;
                                    position: absolute;
                                }
                            }

                            &[title="Remove"] {
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }




            &.components {
                tbody {
                    tr {

                        td:nth-of-type(4) {
                            min-width: 350px;
                        }

                        td {
                            width: auto;
                            min-width: 110px;

                            .tableCell {

                                i {
                                    font-size: 16px;
                                }

                                &.hasIcon {
                                    display: flex;
                                    padding: 0 18px 0 0;
                                    flex-direction: row;
                                    align-items: normal;
                                    position: relative;

                                    .icon {
                                        align-self: center;
                                        cursor: pointer;
                                        font-size: 18px;
                                        height: auto;
                                        width: 14px;
                                        right: 0;
                                        margin-left: 5px;
                                        position: absolute;
                                    }
                                }


                                &[title="Remove"] {
                                    margin-top: 6px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.productPerformer {
            tbody {
                tr {
                    td:nth-of-type(1) {
                        max-width: 200px;

                        .tableCell {
                            max-width: 200px;

                            input[type="text"], input[type="number"] {
                                min-width: 470px;
                            }
                        }
                    }



                    td {
                        width: auto;

                        .tableCell {

                            i {
                                font-size: 16px;
                            }

                            &.hasIcon {
                                display: flex;
                                padding: 0 18px 0 0;
                                flex-direction: row;
                                align-items: normal;
                                position: relative;

                                .icon {
                                    align-self: center;
                                    cursor: pointer;
                                    font-size: 18px;
                                    height: auto;
                                    width: 14px;
                                    right: 0;
                                    margin-left: 5px;
                                    position: absolute;
                                }
                            }



                            &[title="Remove"] {
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }

        &.distributionPools {
            tbody {
                tr {
                    td:nth-of-type(2) {

                        .dropdownDataInput {
                            min-width: 150px;
                        }

                        .tableCell {
                            min-width: 150px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 920px) {
        .tableContainer {
            .table {
                tr {
                    td {
                        .tableCell {
                            max-width: 100% !important;
                            padding-left: 0;
                            width: 100%;

                            .arrowIconDiv {
                                .workTitle {
                                    white-space: normal;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                }
                            }
                        }
                    }
                }

                &.contributors {
                    tbody {
                        tr {
                            td:nth-of-type(1) {
                                position: relative;
                                width: 100%;
                            }

                            td:nth-of-type(2) {
                                position: relative;
                                width: 100%;
                                left: 0;
                            }

                            td:nth-of-type(3) {
                                position: relative;
                                left: 0;
                            }

                            td:nth-of-type(6) {
                                .tableCell {
                                    input[type="text"], input[type="number"] {
                                        width: 100%;
                                    }
                                }
                            }

                            td {
                                .tableCell {

                                    input[type="text"], input[type="number"], select {
                                        max-width: 100%;
                                    }
                                }
                            }

                            td.rowNumber {
                                width: 15% !important;
                            }
                        }
                    }
                }

                &.comments {
                    tbody {
                        tr {

                            td:nth-of-type(3) {
                                position: relative;
                                width: 100%;
                                left: 0;
                            }

                            td {
                                .tableCell {

                                    input[type="text"], input[type="number"], select {
                                        max-width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 480px) {
        .tableContainer .table.contributors tbody tr td:nth-of-type(4) .tableCell input[type="text"] {
            min-width: 200px !important;
        }
    }
}

.table-row-light {
    background-color: #ffffff;
}
.table-row-dark {
    background-color: #fbfbfb;
}

.ant-table-wrapper {
    td, tr {
        font-size: 12px;
        font-family: "montserratregular",sans-serif;
    }
}

.ant-table-wrapper{ 
    th {
        font-size: 13px;
        font-family: "montserratmedium",sans-serif;
        font-weight: 400;
    }
}

.ant-table-column-sorter-inner, .ant-table-filter-column {
    .active {
        color: #2e6bcd !important;
    }
}

.ant-table-cell {
    font-size: 13px;
    font-family: 'montserratregular', sans-serif;
}

.ant-table-cell .hasIcon {
    display: flex;
    padding: 0 18px 0 0;
    flex-direction: row;
    align-items: normal;
    position: relative;
}

.ant-table-cell .hasIcon .icon {
    align-self: center;
    cursor: pointer;
    font-size: 18px;
    height: auto;
    width: 14px;
    right: 0;
    margin-left: 5px;
    position: absolute;
}


.ant-table-cell .widerField {
    min-width: 270px;
}

.ant-table-cell .widerFieldNumber {
    min-width: 150px;
}

.ant-table-cell .percentageField {
    min-width: 70px;
}

.colMaxWidth {
    max-width: 250px !important;
}

.ant-table-row-header {
    border-bottom: 1px solid #2e6bcd;
}

.nested-table {
    display: inline;
  }
  
.nested-table .ant-table table {
    table-layout: auto;
  }
  
.nested-table .ant-table {
    min-width: 1000px;
  }

.parent-table table tr.ant-table-expanded-row > td {
    overflow-x: auto;
    overflow-y: hidden;
  }

.ant-table-title {
    .button {
        margin-top: 0 !important;
        width: 200px;
    }

    .IconTextButton {
        padding-top: 10px !important;
    }

    .saveContainer {
        justify-content: end !important;
    }
    
}

.ant-table-cell {
    .arrowIcon {
        display: flex;
        flex-direction: row;
        height: 12px;
        width: 12px;
        margin-right: 5px;
        align-self: center;
    }
    
    .arrowIconExpanded {
        display: flex;
        flex-direction: row;
        height: 12px;
        width: 12px;
        margin-right: 5px;
        align-self: center;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .arrowIconExpanded:hover,
    .arrowIcon:hover {
        cursor: pointer;
    }
    .arrowIconDiv {
        .openInNewTab {
        margin-right: 0px !important;
        }
    }

} 


thead.ant-table-thead {
    tr {
        th {
            border-bottom: 1px solid #2e6bcd !important;
            background-color: #ffffff !important;
        }
    }
    
}

.ant-table-row-expand-icon-cell {
    .icon {
        cursor: pointer;
        color: #2e6bcd;
    }
    

}


.loaderContainer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  

.loader {
    border: 7px solid #fbfbfb;
    border-top: 7px solid #2e6bcd;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { 
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  th.ant-table-cell {
    font-weight:400!important;
}