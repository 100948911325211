.matchConnectorStatusButtonWrapper {
    position: relative;
    min-width: 200px;
    max-width: 240px;
}

.matchConnectorStatusButtonWrapper .button {
    border: none !important;
    height: 100% !important;
    padding: 10px !important;
    position: absolute !important;
    width: 70%;
    margin: 0px !important;
    background: linear-gradient(135deg, #2e6bcd 12%, #5e2eab 100%);
    color: #ffffff !important;
    transition: all 0.3s ease;
    font-size: 75%;
}

.matchConnectorStatusButtonWrapper .button:disabled {
    background: #8e8d8d;
  }
  
.matchConnectorStatusButtonWrapper .button:disabled:hover {
    box-shadow: none;
    -webkit-transition: all 0.3s ease; /* Safari */
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}