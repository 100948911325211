
.bold {
  font-weight: bold;
  padding-right: 5px;
}

.subTittle {
  font-size: smaller;
}

.subTittle2 {
  font-size: x-small;
}

.dropText {
  color:#202020
}

.poolsImportTextBox {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  border: 1px solid #c7c7c7;
  background-color: #f7f7f7;
  border-radius: 4px;
  font-family: "montserrat", sans-serif;
  font-size: 14px;
}

.dropBox{
  color: #c7c7c7;
  border-radius: 4px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 10px;

  .dropBoxContent{
      border-radius: 4px;
      border-style: dashed;
      padding: 60px;
      height: 100%;
      width: 100%;
      border-width: 4px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #f7f7f7;
      .dopBoxContent-Icon{
          font-size:3rem;
          margin-bottom:2rem;
      }
      .dopBoxContent-Heading{
          font-family: $font__bold;
          font-size: $font__size_large;
      }
  }
}

.display{
  display: block;
}

.notToDisplay{
  display: none;
}

.containerStages{
  margin-bottom: 5px;
}

.disabledDragAndDrop {
  pointer-events: none;
  opacity: 0.4;
}

.importFileDragAndDrop{
  min-height: 110px;
}
.dragArea{
  border-style: dashed;
  padding: 20px;
  height: 100%;
  width: 100%;
  border-width: 1px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: $font__bold;
  font-size: $font__size_large;
}