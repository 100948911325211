.searchResultsDiv {
    display: flex;
    margin-top: 30px;
    width: 100%;
    overflow: hidden;

    .SearchResultsTable {
        display: flex;
        flex: 1;
        margin-bottom: 20px;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
    }

    .arrowIconExpandDiv {
        width: 15%;
        display: flex;
        position: absolute;
        margin-left: 5px;
        margin-top: 8px;
    }

    .arrowIconExpandText {
        margin-left: 6px;
        overflow-wrap: break-word;
    }

    .arrowIconExpandAll {
        height: 16px;
        width: 16px;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }

    .arrowIconExpandAllExpanded {
        height: 16px;
        width: 16px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .arrowIconExpandAllExpanded:hover,
    .arrowIconExpandAll:hover {
        cursor: pointer;
    }
}


.wrapText{
    overflow-wrap: break-word;
}

@media only screen and (max-width: 920px) {
    .tableContainer {
        .table {
            border-top: 1px solid $primary-color;
            table-layout: fixed;

            .thead {
                display: none;
            }

            .tbody,
            .th,
            .td,
            .tr,
            .trSelectable {
                display: block;
            }

            tr {
                border-bottom: 1px solid $primary-color !important;

                td {
                    border-top: none;
                    padding: 5px 0 5px 10px;
                    max-width: 100% !important;

                    &:before {
                        content: attr(title);
                        font-family: $font__medium;
                    }
                }
            }
        }
    }
}
