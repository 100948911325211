.wrapperPageLateralmenu {
    /* Sidebar navigation */
    .sidebarWrapper {
        width: 265px;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        z-index: 999;
        transition: all 0.3s;
        padding-top: 110px;
        /*border-right: 1px solid $color__border;*/
        box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
        padding-right: 2px;
        padding-left: 2px;
        background-color: $white;

        .sidebar-nav {
            font-size: 13.5px;
            top: 0;
            margin: 0;
            padding: 0;
            width: 100%;
            list-style: none;

            li {
                line-height: 18px;

                a {
                    color: $color__text-main !important;
                    display: block;
                    text-decoration: none;
                    padding: .6rem 1rem;

                    &:hover {
                        background: $bgGray;
                        border-radius: $b-radius-value;
                        text-decoration: none;
                    }

                    &:active {
                        text-decoration: none;
                    }

                    &:focus {
                        text-decoration: none;
                    }
                }

                ul {
                    list-style: none;
                    padding: 0 0 0 2rem;

                    li {
                        margin: 0 0 .5rem;

                        ul {
                            li {
                                margin: 0;
                            }
                        }
                    }
                }
            }

            li.active > a {
                background: $primary-color;
                color: $white !important;
                border-radius: $b-radius-value;
            }

            li.active > ul {
                border-left: 1px solid $color__border;
                margin: 0 0 0 2rem;
                padding: 0;
            }
        }

        .sidebar-nav.editableOn {
            li {
                .editIcon {
                    margin-right: 8px;
                }

                a {
                    display: flex;
                }

                .editBox {
                    background-color: $white;
                    margin: 0 2rem 2rem;
                    border-radius: 6px;
                    @include shadow;

                    ul {
                        padding: 0;

                        li {
                            border-bottom: 1px solid $lighGray;

                            a {
                                color: $color__text-main;
                                transition: all 0.3s;

                                &:hover {
                                    padding-left: 1.5rem;
                                }
                            }

                            a.deleteText {
                                color: $red-alerts;
                            }

                            &:last-of-type {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }

        .dropdownSelect {
            margin: 10px 15px 15px;

            label {
                font-family: $font__medium;
            }

            select {
                color: $color__text-main;
                padding: 8px;
                width: 100%;
            }
        }

        .fixed-bottom {
            border-top: 1px solid $color__border;
            display: block;

            a.editBtn {
                margin: 10px;
                padding: 10px 0 10px;
                text-align: center;
                display: block;
                width: auto;
                @include btnTransition;

                &:hover {
                    background: $primary-color;
                    text-decoration: none;
                    color: $white;
                }
            }
        }

        .button {
            font-size: 12.5px;
        }

        .btn-defaultPrimary, button.btn-defaultPrimary {
            @include btnTransition;

            &:hover {
                color: $white;
            }

            &:disabled {
                background: $disabled-color;
                @include btnTransition;

                &:hover {
                    box-shadow: none;
                }
            }

            ;
        }

        .btn-defaultSecondary, button.btn-defaultSecondary {

            &:hover {
                border: 1px solid $darkGray;
                background: $darkGray;
                color: $white;
            }

            &:disabled {
                border: 1px solid $disabled-color;
                color: $disabled-color;
            }

            &:disabled:hover {
                background: transparent;
                box-shadow: none;
            }
        }
    }
    /* /. Sidebar navigation */
}

.sidebarToggle {
    background: $white;
    padding: 10px;
    position: fixed;
    border-radius: 0 6px 6px 0;
    left: 260px;
    top: 115px;
    border: none;
    box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
    z-index: 1;

    &.closed {
        left: 0px;

        .icon {
            transform: rotate(180deg);
        }
    }
}

@media only screen and (max-width: 768px) {
    .sidebarToggle {
        left: 0px;

        .icon {
            transform: rotate(180deg);
        }
    }
}
