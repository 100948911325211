﻿.selectionTable {
    display: flex;
    flex: 1;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    flex-direction: column;
    overflow: hidden;
    width: 98%;

    .tableSection {
        word-wrap: break-word;
        padding: 6px 25px 6px 10px;
        position: relative;

        .button.btn-defaultPrimary {
            width: 50%;
            margin-left: 5px;
            margin-top: 0px;
            float: right;
        }
    }

    .td {
        max-width: 150px;
    }

    .button.btn-defaultPrimary {
        margin-top: 5px;
    }

    .tableContainer {
        flex: 1 1 100%;
        width: 0;
        max-width: max-content;
        min-width: 98%;
        height: 100%;
        overflow-x: auto;
        overflow-y: hidden;

        .arrowIconDiv {
            display: flex;
            padding: 0 18px 0 0;
            flex-direction: row;
            align-items: normal;
            position: relative;

            .workTitle {
                white-space: normal;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .arrowIcon {
            height: 12px;
            width: 12px;
            margin-right: 5px;
        }

        .arrowIconExpanded {
            height: 12px;
            width: 12px;
            margin-right: 5px;
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
        }

        .arrowIconExpanded:hover,
        .arrowIcon:hover {
            cursor: pointer;
        }
    }

    .table {
        background: white;
        font-size: 13px;
        table-layout: auto;
        width: 100%;
    }

    .thead {
        border-bottom: 2px solid var(--primaryColor);
    }

    .tr {
        border-bottom: 1px solid var(--secondaryColor);
    }

    .tr:hover {
        background-color: var(--secondaryColor);
    }

    .trSelectable {
        border-bottom: 1px solid #f1f1f1;
        cursor: pointer;
    }

    .trSelectable:hover {
        background: #f1f1f1;
    }

    .intrayRow {
        background: #f1f1f1;
        color: #3d3d3d !important;
        margin-left: 10px !important;
    }

    .intrayRow .paddedVersion {
        padding-left: 30px;
    }

    .inputDiv {
        margin: 0 12px 0 12px;
        height: 30px;
    }

    .arrowIconExpandDiv {
        width: 15%;
        display: flex;
        margin-left: 5px;
        margin-top: 8px;
    }

    .arrowIconExpandText {
        margin-left: 6px;
        overflow-wrap: break-word;
    }

    .arrowIconExpandAll {
        height: 16px;
        width: 16px;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }

    .arrowIconExpandAllExpanded {
        height: 16px;
        width: 16px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .arrowIconExpandAllExpanded:hover,
    .arrowIconExpandAll:hover {
        cursor: pointer;
    }
}


@media only screen and (max-width: 1368px) {
    .selectionTable {
        display: flex;
        flex: 1;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
        flex-direction: column;
        overflow: hidden;
        width: 98%;

        .tableSection {
            word-wrap: break-word;
            padding: 6px 25px 6px 10px;
            position: relative;

            .externalIcon {
                height: 14px;
                width: 14px;
                margin-left: 5px;
                font-size: 18px;
            }
        }

        .td {
            max-width: 120px;
        }

        .tableContainer {
            flex: 1 1 100%;
            width: 0;
            max-width: max-content;
            min-width: 98%;
            height: 100%;
            overflow-x: auto;
            overflow-y: hidden;
        }

        .table {
            background: white;
            font-size: 13px;
            table-layout: auto;
            width: 100%;
        }

        .thead {
            border-bottom: 2px solid var(--primaryColor);
        }

        .tr {
            border-bottom: 1px solid var(--secondaryColor);
        }

        .tr:hover {
            background-color: var(--secondaryColor);
        }

        .trSelectable {
            border-bottom: 1px solid #f1f1f1;
            cursor: pointer;
        }

        .trSelectable:hover {
            background: #f1f1f1;
        }

        .inputDiv {
            margin: 0 12px 0 12px;
            height: 30px;
        }
    }
}

@media only screen and (max-width: 920px) {
    selectionTable {
        .inputDiv {
            margin: 0 6px 0 6px;
            height: 20px;
        }

        .resultsPerPageSelect {
            margin: 0 5px 0 5px;
            height: 20px;
        }

        .table {
            table-layout: fixed;
            border-top: 1px solid #ccc;
        }

        .thead,
        .tbody,
        .th,
        .td,
        .tr,
        .trSelectable {
            display: block;
        }

        .thead {
            display: none;
        }

        .tr {
            border-bottom: 1px solid var(--darkrey);
        }

        .trSelectable {
            width: 100%;
            border-bottom: 1px solid var(--darkgrey);
        }

        .td {
            border-bottom: 1px solid var(--secondaryColor);
            padding: 5px 0 5px 10px;
            max-width: 100%;
        }

        .td:before {
            content: attr(title);
            font-weight: bold;
        }

        .tableSection {
            padding-right: 50px;
            padding-left: 0
        }
    }
}

@media only screen and (max-width: 499px) {
    .selectionTable {
        .table {
            font-size: 9px;
        }
    }
}
