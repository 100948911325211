body {
    background:$main-background-color;
    padding-top:105px;
    overflow-x: hidden;
}

//CONTAINERS
.container-full {
  margin: 0 auto;
  width: 100%;
}
.flexContent{
    align-items: center;
    display: flex;
}

$b-radius-value:4px;

//CODE SECTIONS
code{
    background-color: $main-background-color;
    color: $primary-color;
}


//IMAGES
.img-fluid {
    max-width: 100%;
    height: auto;

}

//LINKS
a{
    cursor: pointer;
    color:$primary-color;
}

// divider
hr{
    margin-top:25px!important;
    margin-bottom:25px !important;
    border-top: 1px solid $lighGray !important; 
}
.verticalDivider{
    height: 20px;
    border-left: 1px solid $lighGray !important;
    margin-left:20px!important;
    margin-right:20px !important;
}


// Buttons
.button{
    border: none;
    border-radius:$b-radius-value;
    cursor: pointer;
    padding:12px 6px;
    height: auto !important;
    margin-top: 15px;
    letter-spacing: .5px;
    width: 100%;
    @include btnTransition;

    &:hover{
        @include btnTransition;
        @include shadow;
    }
    &.noMargin{
        margin-top:0 !important ;
    }
}

.editBtn{
    border-radius:$b-radius-value;
    background-color:transparent;
    color: $darkGray;

    &:hover{
        background-color: $darkGray;
        color: $white;
        @include shadow;
        @include btnTransition;
    }
    .glyphicon{
        margin: 0 3px;
    }
}
.position-right {
    right: -5px !important;
}

.refreshBtn {
    @extend .editBtn
}

.btn-confirm,
button.btn-confirm{
    white-space: pre-line;
}

.btn-defaultPrimary,
button.btn-defaultPrimary{
    @include gradientOne;
    color: $white;
    @include btnTransition;

    &:hover{
        @include gradientOneHover;
        @include btnTransition;
        
    }
    &:disabled{
        background: $disabled-color;
        
    }
    &:disabled:hover{
        box-shadow: none;
        @include btnTransition;
    };
}
.btn-defaultSecondary,
button.btn-defaultSecondary{
    border: 1px solid $darkGray;
    background: transparent;
    color:$darkGray;

    &:hover{
        background: $darkGray;
        color: $white;
        @include btnTransition;                
    }
    &:disabled{
        border: 1px solid $disabled-color;
        color:$disabled-color;

        &:hover{
        background: transparent;
        box-shadow: none;
        }
    }
}      
.splitButton{
    .button{
        border-radius:$b-radius-value!important;
        display: inline-block;
        margin-top: 0;
        padding: 10px 8px;
        width: auto;
        &.dropdown-toggle{
            width: 35px;

        }
    }
    .dropdown-menu{
        position: relative;
        float: none;
        width: auto;
        min-width: auto;
    }
    
}
.mergeButton,
button.btn-defaultSecondary.mergeButton {
    display: flex;
    margin-top: 1px;
    width: 70%;
}
    
//SHADOWS
.shadow{
    @include shadowinhover;
}

// Wrapper LOGIN PAGE
.wrapperLogin{
        position:relative;
        margin: auto;
        padding: 0 2rem;

        .bgLogin{
            @include gradientOne;
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
        .pageContent{
            .logo{
                text-align: center;
                border-bottom: 1px solid $color__border;
                padding: 2rem 15px;
                img{
                    margin: auto;
                }
            }
            h2{
                margin: 30px auto 25px;
                font-weight: 700;
                font-size: 22px
            }
        }        
    }
// Lists
ul{
    padding-left: 35px;

    li{
        margin-bottom:6px;
    }

}

// MARGINS
.margin-bottomSmall{
    margin-bottom: 10px;
}
.margin-bottomMedium{
    margin-bottom:25px;
}
.margin-topSmall{
    margin-top: 10px;
}
.margin-topMedium{
    margin-top:25px;
}
.jobsProcessModal{
    overflow-y: auto;
    min-height: 100px;
    max-height: 600px;
}

.jobsProgressHeader {
    background-color: white;
    color: #38429C;
    display: flex;
    border-radius: 3px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
}

//MODAL
.modal-dialog{
    &.codeModal{
        height: 90%; 

        .modal-content{
            height: 100%;
            .modal-header{
                button{
                    opacity: 1 !important;
                    .icon{
                        color: $primary-color!important;
                        opacity:1;
                    }
                }
            }

            .modal-body{
                height:93%;

                .codeContainer{
                    height: 100%;

                    .codeHeader{
                        align-items: center;
                        background-color:$lighGray;
                        display: flex; 
                        
                        .language{
                            flex-grow: 1;
                            padding: 5px 8px;
                        }
                        .action{
                            cursor: pointer;
                            font-size: 12px;;
                            display: flex;
                            padding: 5px 8px;
                            align-items: center;
                            .icon{
                                color: $primary-color;
                                font-size: 15px;;
                                padding: 0 5px;
                            }
                            &:hover{
                                background-color:$bgGray;
                            }
                        }
                    }

                    pre{
                        background-color: $bgGray;
                        border-radius:0!important;
                        border:0;
                        height:90%;
                    }
                }
            }
        }
    }
}
//COpy to Clipboard tooltip
.copyMessage {
    position: relative;
    display: inline-block;
    .tooltiptext {
        visibility: hidden;
        width: 140px;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: 120%;
        left: 50%;
        margin-left: -75px;
        opacity: 0;
        transition: opacity 0.3s;

            &::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: #555 transparent transparent transparent;
            }
    }
    &:hover{
        .tooltiptext{
            visibility: visible;
            opacity: 1;
        }

    }
      
}
//Drop Box
.dropBox{
    color: #c7c7c7;
    border-radius: 4px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 10px;

    .dropBoxContent{
        border-radius: 4px;
        border-style: dashed;
        padding: 60px;
        height: 100%;
        width: 100%;
        border-width: 4px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #f7f7f7;
        .dopBoxContent-Icon{
            font-size:3rem;
            margin-bottom:2rem;
        }
        .dopBoxContent-Heading{
            font-family: $font__bold;
            font-size: $font__size_large;
        }
    }
}