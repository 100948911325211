﻿.dataGridTableSection {
    display: block;
    height: 100%;
    width: 100%;
    font-size: 12px;
    height: 28px;
    line-height: 28px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
