  /* table */
  .tableHeader {
    border-bottom: 1px solid #38429C !important;
    font-family: "montserratmedium", sans-serif;
    white-space: nowrap;
  }

  .tableData {
    border-top: none !important;
  }

  .tableRow {
    border-bottom: 1px solid #e5e5e5;
  }

  .rowNotExpanded {
    max-width: 100px;
    overflow: hidden;
  }

  .arrowIconExpandAllNotExpanded {
    height: 16px;
    width: 16px;
    transform: rotate(-90deg);
  }
  
  .arrowIconExpandAllAreExpanded {
    height: 16px;
    width: 16px;
    transform: rotate(90deg);
  }
  
  .arrowIconExpandDivDisplay {
    width: 15%;
    display: flex;
    margin-left: 5px;
    margin-top: 8px;
    padding-right: 10px;
  }
  
  
  .arrowIconTableLine {
    height: 12px;
    width: 12px;
    margin-right: 5px;
  }

  /* formik */

.invalidForkmikField {
  border: 1px solid red;
  width: fit-content;
  border-radius: 3px;
}

.formItemsContainer {
  display: flex;
  flex-wrap: wrap;
}

.formItem {
  flex: 0 0 33.33%; 
  box-sizing: border-box;
}


.validationForkmikField {
  border: 1px solid red;
  border-radius: 3px;
}

.emptyValidationBorder {
  border: 1px;
}
.heightBox {
  height: 35px
}

.checkBoxSize {
  height: 28px;
  padding: 1px;
  width: 28px;
  padding-left: 25px !important;
  margin-bottom: 26px !important;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d1cfcf;
  border-radius: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "montserratregular",sans-serif !important;
}

.editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "montserratregular",sans-serif !important;
}

.checkBoxContainerSize {
  height: 28px;
  padding: 1px;
  width: 28px;
  margin: 0px !important;
}

.ant-input-number {
  height: 37px;
}

  /* view preference */
.compactView {
  .ant-input-number {
    height: 28px !important;
  }
    .form-group {
        margin-bottom:5px !important;
  
        label{
            line-height: 1;
        }
    }
    input[type="text"],
    input[type="date"],
    input[type="number"],
    input[type="time"],
    textarea,
    select{
        height: 28px !important;
    }
    .ms-ComboBox {
      height: 28px !important;
    }
    .checkOptions .radioBox {
      width: 28px !important;
      height: 28px !important;
    }
    .checkOptions .radioBox .checkmark {
      height: 28px !important;
      width: 28px !important;
    }
    .checkOptions .radioBox .checkmark:after {
      left: 10px;
      top: 3px;
      width: 10px;
      height: 18px;
    }
    .ant-picker {
      height: 28px !important;
    }
    .ms-Dropdown {
      height: 28px !important;
    }
    .ms-Dropdown-title {
      height: 28px !important;
    }
    .ant-select-selector {
      height: 28px !important;
      border-radius: 0px !important;
    }
    .treeVewSelector {
      height: 28px !important;
      border-radius: 0px !important;
    }
    .parent-table .ant-table-tbody .ant-table-cell {
      padding: 5px !important;
      line-height: 1.1;
  }
  /* Checkboxes */
  .checkboxContainer {      
    .subContainer {
      height: 15px;
      padding-left:20px;
       /* Custom checkbox */
      .inputCheckbox {
        height: 15px;
        width: 15px;
  
        &::after {
            left: 4px;
            top: 2px;
            width: 6px;
            height: 10px;
        }
      }
    }
    .formItemsContainer .flexRow label {
      margin: 0;
    }
  
  }
  .parent-table {
    .ant-table-content {
    .ant-table-thead {
      .ant-table-cell {
        padding: 5px !important;
      }
    }
  }
}
    .ant-table-tbody {
      .ant-table-tbody {
         tr {
          td {
            padding: 5px !important;
            line-height: 1.1;
        }
         }
      }
  
      .ant-table-tbody{
        padding: 0 !important;
      }
      .ant-table-tbody > tr > td{
        padding: 5px !important;
      }
  
    }
    .ant-select-selctor {
      border-radius: 0;
      height: 28px;
      line-height: 1.1;
    }
  
  
    .splitButton{
        .button{
            padding: 5px !important; 
        }
    }
    .tableContainer{
        .table{
            .tr{
                .td{
                    padding: 5px 5px !important;
                }
            }
        }
    }
    .button{
        padding: 6px !important;
    }
    .AccordionContainer{
        .itemAccordion{
        padding: 7px 0 7px 0 !important;
        }
    }
    .selectorView{
        .selectorRadioButtonsDiv{
            .selectorRadioButtonSelected, .selectorRadioButtonUnSelected {
                height: 28px !important;
                padding: 5px !important;
            }
        }
    }
    
  }

  .treeVewSelector {
    width: 100%;
    min-width: 200px;
    height: 38px !important;
  }

  .ant-select-selector {
    width: 100%;
    min-width: 200px;
    height: 38px !important;
    border-radius: 0px !important;
  }

  .ant-pagination-options .ant-select-selector {
    min-width: min-content;
    height: 28px !important;
    border-radius: 0px; }

  .parent-table {
    font-size: 13px;
    font-synthesis: none;
}

.expandCollapseButtonPosition {
  position: relative;
  top: 42px;
  z-index: 3000;
}

.scheduledJobView {
    overflow: hidden;
}

.focusedTableRow {
  background: #f5f5f5;
}
.modalLarge {
  width: 1300px;
}

.noTopCorrection {
  top: auto !important;
}

.datePickerSize {
  min-width: 200px;
  margin: 0 !important;
}

.hideCountryOrRegion {
  display: none !important;
}