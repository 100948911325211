/* layout classes */
  .flexRow {
    display: flex;
    flex-direction: row;
  }

  .flexWrapRow {
    display: flex;
    flex-wrap: wrap;
  }

  .flexColumn {
    display: flex;
    flex-direction: column;
  }

  .flexJustifyContentCenter {
    justify-content: center;
  }

  .flexJustifyContentRight {
    justify-content: right;
  }

  .flexJustifyContentLeft {
    justify-content: left;
  }

  .flexJustifyContentSpaceBetween {
    justify-content: space-between;
  }

  .flexJustifyContentSpaceAround {
    justify-content: space-around;
  }

  .flexAlignItemsStart {
    align-items: flex-start;
  }

  .flexAlignItemsEnd {
    align-items: flex-end;
  }

  .flexAlignItemsCenter {
    align-items: center;
  }

  /* padding */

  .paddingVerySmall {
    padding: 3px
  }

  .paddingSmall {
    padding: 6px
  }

  .paddingMedium {
    padding: 9px
  }

  .paddingLarge {
    padding: 12px
  }

  .paddingVeryLarge {
    padding: 15px
  }

  .paddingRightVerySmall {
    padding-right: 3px
  }

  .paddingRightSmall {
    padding-right: 6px
  }

  .paddingRightMedium {
    padding-right: 9px
  }

  .paddingRightLarge {
    padding-right: 12px
  }

  .paddingRightVeryLarge {
    padding-right: 15px
  }

  .paddingLeftVerySmall {
    padding-left: 3px
  }

  .paddingLeftSmall {
    padding-left: 6px
  }

  .paddingLeftMedium {
    padding-left: 9px
  }

  .paddingLeftLarge {
    padding-left: 12px
  }

  .paddingLeftVeryLarge {
    padding-left: 15px
  }

  .paddingVerticalVerySmall {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .paddingVerticalSmall {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .paddingVerticalMedium {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .paddingVerticalLarge {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .paddingVerticalVeryLarge {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .paddingHorizontalVerySmall {
    padding-right: 3px;
    padding-left: 3px;
  }

  .paddingHorizontalSmall {
    padding-right: 6px;
    padding-left: 6px;
  }

  .paddingHorizontalMedium {
    padding-right: 9px;
    padding-left: 9px;
  }

  .paddingHorizontalLarge {
    padding-right: 12px;
    padding-left: 12px;
  }

  .paddingHorizontalVeryLarge {
    padding-right: 15px;
    padding-left: 15px;
  }

    /* margin */

    .marginVerySmall {
      margin: 3px
    }
  
    .marginSmall {
      margin: 6px
    }
  
    .marginMedium {
      margin: 9px
    }
  
    .marginLarge {
      margin: 12px
    }
  
    .marginVeryLarge {
      margin: 15px
    }

    .marginVerticalVerySmall {
      margin-top: 3px;
      margin-bottom: 3px;
    }
  
    .marginVerticalSmall {
      margin-top: 6px;
      margin-bottom: 6px;
    }
  
    .marginVerticalMedium {
      margin-top: 9px;
      margin-bottom: 9px;
    }
  
    .marginVerticalLarge {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  
    .marginVerticalVeryLarge {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .marginHorizontalVerySmall {
      margin-right: 3px;
      margin-left: 3px;
    }
  
    .marginHorizontalSmall {
      margin-right: 6px;
      margin-left: 6px;
    }
  
    .marginHorizontalMedium {
      margin-right: 9px;
      margin-left: 9px;
    }
  
    .marginHorizontalLarge {
      margin-right: 12px;
      margin-left: 12px;
    }
  
    .marginHorizontalVeryLarge {
      margin-right: 15px;
      margin-left: 15px;
    }

    // modal scroll results

    .resultsScrollSection {
      overflow-y: initial !important;
    }

    .resultsScrollSectionArea {
      height: 35vh;
      overflow-y: scroll;
    }
    .widthFull {
      width: 100%;
    }
  
    .widthHalf {
      width: 50%;
    }

/* LOADER  */
.loaderWrapper {
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  z-index: 3000;
  position: fixed;
  background: rgba(0, 0, 0, 0.05);
}

.spinner-dualball {
  width:20%;
  height: 20%;
  position: absolute;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
  margin: auto auto;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;

}
.spinner-dualball div { box-sizing: content-box; }

@keyframes spinner-dualball-o {
  0%    { opacity: 1; transform: translate(0 0) }
 50% { opacity: 1; transform: translate(70px,0) }
 50%    { opacity: 0; transform: translate(70px,0) }
100%    { opacity: 0; transform: translate(0,0) }
}
@keyframes spinner-dualball {
  0% { transform: translate(0,0) }
 50% { transform: translate(70px,0) }
100% { transform: translate(0,0) }
}
.spinner-dualball {
 div{
      position: absolute;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      top: 0;
      bottom: 0;
      right: 0;
      left:-70px;
      margin: auto;
  }
  span{
      &.text{
          text-align: center;
          position: absolute;
          bottom: 10%;
          right: 0;
          left: 0
      }
  }

}
.spinner-dualball div:nth-child(1) {
background: rgba($primary-color, 0.836682607421875);
animation: spinner-dualball 0.9345794392523364s  linear infinite;
animation-delay: -0.4672897196261682s;;
}
.spinner-dualball div:nth-child(2) {
background: rgba($secondary-color, 0.836682607421875);
animation: spinner-dualball 0.9345794392523364s linear infinite;
animation-delay: 0s;
}
.spinner-dualball div:nth-child(3) {
background: rgba($primary-color, 0.836682607421875);
animation: spinner-dualball-o 0.9345794392523364s linear infinite;
animation-delay: -0.4672897196261682s;
}

/* ant style adaptions  */
.ant-picker-large .ant-picker-input>input {
  font-size: 13px !important;
}

.borderTopGreyLine {
  border-top: 1px solid #c7c7c7;
}

.messageBox {
  position: absolute; 
  left: -150px; 
  opacity: 0.7px;
  background-color: #F6F7FA; 
  border: 1px solid #65C36C;;
  padding: 6px; 
  border-radius: 4px; 
  font-size: 12px; 
  color: #65C36C;;
  z-index: 10; 
  width: 140px; 
  text-align: center; 
}


.addtopackagesuccessMessage {
  border: 1px solid #65C36C; 
  color: #65C36C; 
}

.addtopackageerrorMessage {
  border: 1px solid #D9534F;
  color: #D9534F; 
}

.package-form {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #3d3d3d;
  border-radius: 8px;
  background-color: #F6F7FA;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-row label {
  font-weight: bold;
  margin-right: 10px;
}

.form-row span {
  font-size: 16px;
  color: #555;
}

.form-row select,
.form-row input {
  padding: 5px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
}

.form-actions button {
  padding: 10px 20px;
  margin-left: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-actions button:first-child {
  background-color: #f0f0f0;
  color: #555;
}

.form-actions button:last-child {
  background-color: #5b6ef5;
  color: white;
}

.button-row {
  display: flex; /* Space between the buttons */
  justify-content: flex-start; /* Align buttons to the start (left) */
  align-items: center; /* Align items vertically in the center */
  margin-top: 10px; /* Optional: Add margin to the top for spacing */
}
.btn-outline-defaultPrimary {
  background-color: white;
  color: #38429C;
  border: 1px solid #38429C;
}
.info-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; /* Align vertically in the center */
}
.info-item {
  display: flex;
  flex-direction: column;
  margin-right: 20px; /* Space between items */
}
.bold-label label {
  font-weight: bold;
}

.TabsViewSplitScreen {
  max-width: 50%;
}