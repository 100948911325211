.dropdownDataInput {
    select {
        &.showAsCode {
            min-width: 80px;
            max-width: 120px;
        }
    }
}

@media only screen and (max-width: 920px) {
    .dropdownDataInput {
        select {
            &.showAsCode {
                position: relative;
                width: 100%;
                max-width:inherit;
            }
        }
    }
}

.ms-Dropdown, .ms-Dropdown-items, .ms-Button-flexContainer, .ms-Dropdown-label, .ms-Dropdown-title {
    font-size: 13px;
    font-family: "montserrat_mediumregular", sans-serif;
  }
  .ms-Dropdown, .ms-Dropdown-items, .ms-Button-flexContainer, .ms-Dropdown-label {
    font-weight: normal;
  }
  .ms-Dropdown-label {
    padding: 0;
    margin: 8px 0 5px 0;
    font-family: "montserratmedium",sans-serif;
    color: #202020;
  }
  .ms-Dropdown {
    font-weight: 400;
    color: #202020;
    text-rendering: optimizeLegibility;
  
    background: #f5f5f5;
    height: 38px;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  .ms-Dropdown.is-open > .ms-Dropdown-title {
    background: #fff;
    border: 1px solid #38429C;
    outline: none;
  }
  .ms-Dropdown:focus::after {
    border: none;
  }
  .ms-Dropdown-title { 
    background: #f5f5f5;
    border: 1px solid #e5e5e5;
    line-height: 1.2;
    height: 38px;
    width: 100%;
    padding: 8px;
    margin: 0;
    transition: all 0.3s;
  }

  .ms-ComboBox, .ms-ComboBox-items, .ms-Button-flexContainer, .ms-Label, .ms-ComboBox-title {
    font-size: 13px;
    
    font-family: "montserrat_mediumregular", sans-serif;
  }
  .ms-ComboBox, .ms-ComboBox-items, .ms-Button-flexContainer, .ms-Label {
    font-weight: normal;
  }
  .ms-Label {
    margin: 8px 0px 5px 0px;
    font-family: "montserratmedium", sans-serif;
    color: #202020;
    padding: 0 0 0 0;
  }
  .ms-ComboBox {
    font-weight: 400;
    color: #202020;
    text-rendering: optimizeLegibility;
  
    background: #f5f5f5;
    height: 38px;
    margin: 0;
    width: 100%;
  }
  .ms-ComboBox.is-open > .ms-ComboBox-title {
    background: #fff;
    border: 1px solid #38429C;
    outline: none;
  }
  .ms-ComboBox:focus::after {
    border: none;
  }
  .ms-ComboBox-title {
    background: #f5f5f5;
    border: 1px solid #e5e5e5;
    line-height: 1.2;
    height: 38px;
    width: 100%;
    padding: 8px;
    margin: 0;
    transition: all 0.3s;
  }

.ms-Button-flexContainer .ms-ComboBox-optionText {
    overflow: visible;
    white-space: normal;
    text-overflow: ellipsis;
    display: inline-block;
    padding: 3px 0;
}
  
  