#DragAndDropArea {
    table.dataTable tbody tr td div {
        padding: 12px 2px !important;
    }
}

.dataTable {
	width: 100%;

	thead {
		tr {
			border-bottom: 1px solid $primary-color;
			font-family: $font__medium;
			font-size: $font__size_small;

			td {
				div {
					padding: 5px 0 5px 10px;
				}

				.headerCell {
					display: flex;
					font-family: $font__medium;
					display: flex;
					flex-direction: row;
					align-items: center;

					&.headerCellSortable {
						&:hover {
							cursor: pointer;

							img.sortIcon {
								visibility: visible;
							}
						}

						img.sortIcon {
							height: 15px;
							width: 20px;
							visibility: hidden;
						}

						.sortAscendingIcon {
							height: 10px;
							width: 20px;
						}
					}
				}
			}
		}
	}

	tbody {
		tr {
			border-bottom: 1px solid $color__border;
			cursor: pointer;


			td {
				div {
					padding: 12px 10px;
					display: flex;
					align-items: center;

					.icon {
						color: $primary-color;
						font-size: $font__size_large;
						margin: 0 8px 0 0;
						
						&.iconHover{
							&:hover{
								background-color: $primary-color;
								border-radius: 10px;
								color: $white;
							}
						}
					}

					select, input {
						max-width: max-content;
					}
				}

				.tableCell {

					&.hasIcon {
						display: flex;
						padding: 0 18px 0 0;
						flex-direction: row;
						align-items: normal;
						position: relative;

						input[type="text"], input[type="number"],input[type="time"], select {
							width: auto;
							max-width: 110px;
						}

						.icon {
							align-self: center;
							cursor: pointer;
							font-size: 18px;
							height: auto;
							width: 14px;
							right: 0;
							margin-left: 5px;
						}
					}
				}

				.showMore {
					display: flex;
					padding: 0 18px 0 0;
					flex-direction: row;
					align-items: normal;
					position: absolute;

					.popUp {
						align-items: center;
						right: 30px;
						display: block;
						padding: 0px;
						left: unset;
						bottom: unset;

						.popUpItem {
							i {
								margin-left: 3px;
								color: #1a7ec5
							}
						}
					}
				}
			}

			&:hover {
				background-color: $bgGray;
			}

			&:last-child {
				border-bottom: none;
			}
		}
	}
}

.unableMinWidth{
	min-width: 0 !important;
}

.noPadding{
	padding: 0 0 0 0 !important;
}

.inheritPosition{
	position:inherit !important;
}

.minWidth{
	min-width: 100% !important;
}

.row {
    .headerContainer{
        flex: 1;
        display: flex;
        flex-direction: row;

        .headerSection{
            flex: 1;
            font-family: $font__medium;
            font-size: $font__size_large;
            padding: 0 0 5px 10px;
            border-bottom:3px solid $color__border;
            cursor: pointer;

            &.activeSection{
                border-bottom:3px solid $primary-color;
            }          
        }
    }
}

@media only screen and (max-width: 920px){
	.dataTable{
		border-top: 1px solid $primary-color;

		thead {
			display: none;
		}

		tbody,
		th,
		td,
		tr,
		.trSelectable {
		  display: block;
		}
		tr{
			border-bottom: 1px solid $primary-color !important;

			td {
				border-top: none; 
				padding: 12px 10px;
				max-width: 100% !important;

				&:before {
					content: attr(title);
					font-family: $font__medium;
				}
				div{
					padding: 0 !important;
					select, input{
						width: 100% !important;
					}
				}
			}
		}
	}

}

.territoryContainer{
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}