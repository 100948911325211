﻿.paginationDiv {
    font-size: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 20px;
    justify-content: flex-end;
    border-radius:4px;

    .showPerPageDiv {
        margin-right: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .resultsNumberDiv {
            font-weight: bold;
            white-space: nowrap;
            margin-right: 10px;
        }

        .resultsPerPageSelect {
            margin: 0 5px 0 5px;
            height: 30px;
            width: auto;
        }
    }

    .pageNumberDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
        border-radius:4px;

        .pageNumberButton {
            display: flex;
            border: 1px solid $primary-color;
            color: $primary-color;
            padding: 0 5px 0 5px;
            height: 30px;
            align-items: center;
            white-space: nowrap;
            cursor: pointer;
            flex-direction: row;

            .pageNumberPrevIcon {
                width: 14px;
                height: 14px;
                margin-right: 2px;
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
                fill: $primary-color;
            }

            .pageNumberNextIcon {
                width: 14px;
                height: 14px;
                margin-left: 2px;
                fill: $primary-color;
            }
        }

        .inputDiv {
            margin: 0 12px 0 12px;
            height: 30px;

            .pageNumberInput {
                width: 30px;
                text-align: center;
                margin-right: 5px;
                height: 30px;
            }
        }
    }
}

.ant-pagination-next, .ant-pagination-prev {
  .pageNumberButton {
      display: flex;
      color: $primary-color;
      padding: 0 5px 0 5px;
      height: 30px;
      align-items: center;
      white-space: nowrap;
      cursor: pointer;
      flex-direction: row;
  }
}

@media only screen and (max-width: 600px) {
    .paginationDiv {
        flex-direction: column;
        justify-content: flex-end;

        .showPerPageDiv {
            margin: 0 0 5px 0;
        }
    }
}

@media only screen and (max-width: 920px) {
    .paginationDiv {
        flex-direction: column;
        justify-content: flex-end;

        .showPerPageDiv {
            margin: 0 0 25px 0;

            .resultsPerPageSelect {
                margin: 0 5px 0 5px;
            }
        }

        .pageNumberDiv {
            .pageNumberButton {
                padding: 0 5px 0 5px;
            }

            .inputDiv {
                margin: 0 6px 0 6px;
            }
        }
    }
}
