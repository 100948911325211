.mapSearch {
    box-sizing: border-box;
    border: 1px solid #e5e5e5;
    width: 1000px;
    height: 40px;
    padding: 0 12px;
    margin-bottom: 8px;
    margin-top: 8px;
    border-radius: 3px;
    font-size: 14px;
    outline: none;
    text-overflow: ellipses
}

.mapFrame {
    border: 0;
    margin-bottom: 40px;
}