.versionHistoryPointer{
    pointer-events: none;
}

.pointerEnable{
    pointer-events: all;
}

.topMarginRepertoireAttachment{
    padding-top: 25px
}

.newJobButton {
    position: absolute;
    right: 20px;
    top: 5px;
}

.isMandatory::after {
    content: "*";
    color: #cd0303;
    margin-left: 5px;
    font-weight: 600;
}

.disabledItem {
    color: #8e8d8d;
}

.ms-ComboBox-container {
    &.required {
        .ms-Label::after {
            content: "*";
            color: #cd0303;
            margin-left: 5px;
            font-weight: 600;
        }
    }

    &.disabledItem {
        .ms-Label {
            color: #8e8d8d;
            cursor: not-allowed !important;
        }
        .ms-ComboBox {
            .ms-ComboBox-Input {
                color: #8e8d8d;
                cursor: not-allowed !important;
            }
        }
    }
}

.ms-Dropdown-container {
    &.required {
        .ms-Label::after {
            content: "*";
            color: #cd0303;
            margin-left: 5px;
            font-weight: 600;
        }
    }

    &.disabledItem {
        .ms-Label {
            color: #8e8d8d;
            cursor: not-allowed !important;
        }

        .ms-Dropdown {
            .ms-Dropdown-title {
                color: #8e8d8d;
                cursor: not-allowed !important;
            }
        }
    }
    .ms-Label {
        padding: 0;
    }
}

.selected-item-container{
    width: 100%;
    padding: 0px;
    overflow:auto;
    margin-top:50px;
}

.selected-items-list{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.selected-item{
    background-color: #f1f1f1;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 5px 10px;
  display: inline-block;
  font-size: 14px;
}

.close-icon {
    cursor: pointer;
    font-size: 16px;
  }

  .ms-Callout-container.container-175 {
    position: relative;
    top: 610px;
    right: 613px;
}
