.checkboxContainer {
    display: flex;
    flex-direction: row;

    &.inline {
        margin-top: 25px;
    }

    .subContainer {
        display: flex;
        position: relative;
        padding-left: 30px;
        margin: 0;
        cursor: pointer;
        height: 25px;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        /* Hide default checkbox */
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked ~ .inputCheckbox {
                background-color: $green-color !important;
            }
        }
        /* Custom checkbox */
        .inputCheckbox {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: $lighGray;
            border-radius: 4px;

            &::after {
                content: "";
                position: absolute;
                left: 9px;
                top: 2px;
                width: 8px;
                height: 16px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);                
            }
        }

        .checkboxLabel {
            margin-left: 4px;
            padding-top: 4px;
        }

        &:hover {

            input ~ .inputCheckbox {
                background-color: $mediumGray;
            }
        }
    }
}
