.dragContainer {
  margin: 0 0 1em 0;
  padding: 1em;
  border: 2px solid transparent;
}

.dragContainer .daggableItem {
  padding: 15px 2em;
  
  &:last-of-type { 
    margin-bottom: 0;
  }
  
  &:before {
    content: '⇅';
    //content:"\e150";
    position: absolute;
    left: 10px;
    font-size: 1.8em;
    cursor: move;
        &:hover{
            color:$primary-color;
        }
  }
  &:hover {
    background-color: #fafafa;
  }
  
  &.js-active {
    border: 1px dashed $white;
    background-color: $primary-color;
  }
}  