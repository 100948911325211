.contextualMenu{
    position: relative;
    z-index: 100;
    display: none;

    &.show{
        display: block;

       .toolbarWrapper{
        -webkit-transition: -webkit-transform .3s ease-out;
        -o-transition: -o-transform .3s ease-out;
        transition: transform .3s ease-out;
        -webkit-transition: -webkit-transform .3s ease-out;
        -o-transition: -o-transform .3s ease-out;
        transition: transform .3s ease-out;
        -webkit-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        opacity: 1;
        }
    }

    .toolbarWrapper{
        display: block;
        height: auto;
        position:absolute;
        width: 180px;
        @include shadow;
        background-color: $white;
        
        -webkit-transition: -webkit-transform .3s ease-out;
        -o-transition: -o-transform .3s ease-out;
        transition: transform .3s ease-out;
        -webkit-transform: translate(0%, -10%);
        -ms-transform: translate(0%, -10%);
        -o-transform: translate(0%, -10%);
        transform: translate(0%, -10%);
        opacity: 0;

        .toolbarButton{

            .toolbarButtonContainer{
                padding: 11px;

                &:hover{
                    background-color: $bgGray;
                }

                &.disabled {
                    color: $mediumGray !important;
                    cursor: not-allowed;
                }
            }
        }
    }
}
