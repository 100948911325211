.pageContent {
    padding: 30px 30px;

    .navbar-toggle.btnLateralmenu {
        float: none;
        margin: 0 0 20px;
        height: 45px;
        border: none;

        .glyphicon {
            margin-right: 10px;
        }
    }

    .dataRow {
        margin: auto; 
        display: flex; 
        flex-direction: row; 
        width: 100%;
        justify-content: space-between;
        margin-bottom: 50px;
    }

    .breadcrumb {
        margin: 0;
        line-height: normal;
        padding: 0 0 8px;
        margin: 0 0 1.5rem;
        border-bottom: 1px solid $lighGray;
        border-radius: 0;
        background-color: transparent;
        display: flex;
        align-items: center;

        .glyphicon {
            color: $mediumGray;
            margin: 0 5px;
            font-size: 12px;
        }

        .icon {
            color: $primary-color;
            font-size: $font__size_large;
            margin: 0 4px 0 0;

            &-separator {
                color: $mediumGray;
                font-size: 10px;
                margin: 0 4px;
            }
        }

        a {
            display: flex;
            align-items: center;

            &:hover {
                color: $color__text-main;
                text-decoration: none;
            }
        }
    }

    p {
        margin: 0 0 18px;
    }

    .editBtn {
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 25px;
        border: none;
        font-size: 15.5px;
        height: 35px;
        width: 35px;
        padding: 0;
        text-align: center;
        -webkit-transition: all 0.3s; /* Safari */
        transition: all 0.3s;

        .glyphicon {
            line-height: inherit;
        }
    }

    .contributorGearBtn {
        @extend .editBtn;
        position: relative;
    }

    .ant-row-editBtn {
        border-radius: 25px;
        border: none;
        font-size: 15.5px;
        height: 35px;
        width: 35px;
        padding: 0;
        text-align: center;
        -webkit-transition: all 0.3s; /* Safari */
        transition: all 0.3s;
        background-color: $white;
    }

    .flex-end-Content {
        display: flex;
        justify-content: flex-end;
    }

    .refreshBtn {
        @extend .editBtn;
        right: 30px;
    }

    .navbar-toggle.btnLateralmenu {
        color: $white;
        background-color: $primary-color;
    }

    .membersPortalContainer {
        position: relative;
        max-height: 370px;
     }
     .membersPortalPanel {
        float: left;
        position: absolute;
        left: 7%;
        top: 39%;
        width: 86%;
        height: 40%;
        z-index: 1000;
        background-color: $white;
        padding: 5px;
        font-weight: bold;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;
            bottom: 2.0vw;

            .cardSubText {
                font-family: "montserrat";
                font-size: 2.0vw;
                position: relative;
                right: 4vw;
            }

            .cardMainText {
                font-size: 2.2vw;
                font-family: "montserratbold";
                position: relative;
                right: 4vw;
            }

            .cardSideText {
                font-family: "montserrat";
                position: relative;

                @media only screen and (min-width: 768px) {
                    padding: 1.1vw;
                }

                .sideDesc {
                    font-size: 11px;
                }

                .sideViewName {
                    font-size: 1.2vw;
                    font-family: "montserratbold";
                }

                .sideIPNumber {
                    font-size: 11px;
                }
            }

            .hidding {
                visibility: hidden;
            }  
        }
     }
     .membersPortalBgImg {
        margin: -30px;
        position: relative;
        top: -105px;
        max-height: 500px;
        width: 104%;
        overflow: hidden;
        object-fit: cover;
        object-position: 0 10%;
     }


    /* Lists boxes */
    .cardsList {
        display: flex;
        margin-bottom: 3rem;

        div {
            .formBox {
                height: 100%;

                .heading {
                    .icon {
                        font-size: 25px;
                        margin-bottom: 1rem;
                        color: $primary-color;
                    }
                }

                ul {
                    li {
                        margin-bottom: 12px;

                        a {
                            color: $color__text-main;

                            &:hover {
                                color: $primary-color;
                                text-decoration: none;
                                border-bottom: 2px solid;
                            }
                        }
                    }
                }
            }
        }

        .disabledItem {
            .formBox {
                background-color: $bgGray;
                cursor: not-allowed;

                .heading {
                    .icon {
                        color: $disabled-color;
                    }
                }
            }
        }
    }

    .alignFlexSB {
        display: flex;
        justify-content: space-between;
    }
    /*Headings */
    h1 {
        &.borderBootom {
            border-bottom: 3px solid $primary-color;
            padding-bottom: 5px;
            font-weight: 700;
            cursor: pointer;

            .icon {
                margin-right: 8px;

                &.expanded {
                    -webkit-transform: rotate(90deg);
                    -moz-transform: rotate(90deg);
                    -o-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    transform: rotate(90deg);
                }
            }
        }
    }

    .editableSectionHeading {
        .editBtn {
            right: 5px;
        }

        .refreshBtn {
            right: 45px;
        }
    }
}

    .wrapperPage {
        position: relative;

        .pageContent {
            width: calc(100% - 265px);
            min-height: 100vh;
            transition: all 0.3s;
            position: absolute;
            top: 0;
            right: 0;
        }

        .pageContent.active {
            width: 100%;
        }
    }
    .wrapperPageLateralmenu {
        position: relative;

        .pageContent {
            width: calc(100% - 265px);
            min-height: 100vh;
            transition: all 0.3s;
            position: absolute;
            top: 0;
            right: 0;
        }

        .pageContent.active {
            width: 100%;
        }
        /* Sidebar navigation */
        .sidebarWrapper {
            overflow: auto;
            .btnClose {
                border-radius: 0;
                border-width: 0 0 1px 0;
                border-style: solid;
                border-color: #E5E5E5;
                font-size: 13px;
                float: none;
                margin: 3px 0 10px;
                padding: 5px 0 8px;
                width: 100%;
                z-index: 99;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                background-image: none;

                .glyphicon {
                    margin-right: 5px;
                }

                .icon {
                    font-size: 11px;
                    margin-right: 5px;
                }
            }
        }

        .sidebarWrapper.active {
            margin-left: -265px;
        }
        /* /. Sidebar navigation */
    }

    .disabled {
        cursor: not-allowed;
    }

    // delete the extra classes

    .sideBarSettingWrapper {
        padding-top: 10px;
        float: right;

        i {
            padding-right: 5px;
        }
    }

    .textCenter {
        text-align: center;
    }

    .topMargin {
        margin-top: 25px;
    }

    @media only screen and (max-width: 768px) {
        /* Lists boxes */
        .cardsList {
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 10vh;

            .form-group {
                flex: 1;
            }
        }

        .wrapperPage {
            .pageContent {
                width: 100%;
                padding-top: 60px;

                .navbar-toggle {
                    &.btnLateralmenu {
                        display: block;
                    }
                }
            }
        }
        .wrapperPageLateralmenu {
            .pageContent {
                width: 100%;
                padding-top: 60px;

                .navbar-toggle {
                    &.btnLateralmenu {
                        display: block;
                    }
                }
            }
            /* Sidebar navigation */
            .sidebarWrapper {
                margin-left: -265px;

                h4 {
                    padding: 45px 0 15px;
                }
            }

            .sidebarWrapper.active {
                margin-left: 0;
                width: 100%; /* to make menu full width when active*/
            }

            #sidebarCollapse {
                span {
                    display: none;
                }
            }
            /* /. Sidebar navigation */
        }
    }

    @media only screen and (min-width:769px) {
        .wrapperPageLateralmenu {
            .sidebarWrapper {

                .btnClose {
                    display: none;
                }
            }
        }
    }

    /* Extra Small Devices, Phones */
    @media only screen and (max-width :480px) {
        .pageContent {
            padding: 30px 20px;
        }

        h2 {
            font-size: 25px !important;
        }


        .membersPortalContainer {
            .membersPortalPanel {
                position: absolute;
                height: 50%;
                top: -5vh;
                margin-top: 10vh;
    
                .row {
                    display: block;
                    text-align: left;
                    bottom: 5vh;
                    left: 4vw;
    
                    .cardSubText {
                        font-size: 13px !important;
                        font-family: "montserrat" !important;
                    }
    
                    .cardMainText {
                        font-size: 15px !important;
                        font-family: "montserratbold" !important;
                        bottom: 1vh;
                    }

                    .cardSideText {
                        right: 4vw;
                        top: 1vh;

                        .sideDesc {
                            font-size: 11px !important;
                        }

                        .sideViewName {
                            position: absolute;
                            font-size: 10px !important;
                            top: 0px;
                        }

                        .sideIPNumber {
                            font-size: 10px !important;
                        }
                    }
                    
                }
            }
            .container {
                padding: 4vh;
            }
        }

        .membersPortalBgImg {
            margin-top: -50px;
        }

        .wrapperLogin {

            .pageContent {
                h2 {
                    margin: 20px auto;
                }
            }
        }
    }

    .ms-Icon--SyncStatus {
        width: fit-content;
        height: fit-content;

        &.live {
            animation-name: spin;
            animation-duration: 5000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }


    .wrapperLookup {
        position: relative;

        input[type="text"] {
            padding-right: 70px !important;
        }

        button {
            border: none !important;
            height: 100% !important;
            padding: 10px !important;
            position: absolute !important;
            right: 0 !important;
            @include gradientOne;
            color: $white !important;
            @include btnTransition;

            &:hover {
                @include gradientOneHover;
                @include btnTransition;
            }
        }
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
    .footer-container{
        display:flex;
        flex-direction: column;
        gap:15px;
        align-items: center;
        padding-bottom: 5vh;
      }
      .footer{
        padding: 0px 40px;
        bottom: 0;
        width: 100%;
      }
      .footer-links{
        display:flex;
        flex-direction: row;
        gap:25px;
      }
      .powered-by-text{
        font-size: 11px;
        color:grey;
      }
      hr {
        width:100%;
      }
      .important-label{
        content:"*";
        height:20px;
        width:200px;
        color:red;
      }
      .timeLabel {
        margin-top: 5px;
        font-weight: 600; }
      
      .timeInput {
        height: 38px;
        width: 230px; }
      
      .timeInputContainer {
        display: flex;
        flex-direction: column; }
      
      .timeInputSubContainer {
        font-family: "montserratmedium", sans-serif;
        font-weight: normal;
        margin: 0; }
        
      .gridContainer{
        display: flex;
        flex-direction: row;
        gap:18px;
      }
      //territorySelection//
    .territoryDropdown {
        position: relative;
        display: inline-block;
        width: 100%;
        padding: 13px;
    }
    
    .territoryDropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 300px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        max-height: 200px;
        overflow-y: auto;
    }
    
    .territoryDropdown-content .territoryDropdown-item {
        display: flex;
        align-items: center;
        padding: 1px;
        justify-content: space-between;
    }
    
    .territoryDropdown-content .territoryDropdown-item span {
        flex-grow: 1;
        margin-right: 20px;
    }
    
    .territoryDropdown-content label {
        margin-left: 10px;
    }
    
    .territoryDropdown-content.show {
        //display: block;
        width: 100%;
    position: relative;
    max-height: 560px;
        @media only screen and (max-width: 500px) {
            width: 54%;
        }

    }
    .arrow-icon {
        font-weight: 600;
        position: absolute;
        right: 3%;
        top: 43%;
        transform: translateY(-50%);
        transition: transform 0.2s ease;
}
    
    
    .arrow-icon.up {
        position: absolute;
        font-weight: 600;
        right: 3%;
        top: 13%;
        transform: translateY(-50%) rotate(180deg);
    }

    .selectTerritoryOptions{
        margin-top: 201px;
        position: absolute;
        left: 66%;
    }

    .searchIpOptions{
        top: 34%; 
        right: 66.5%;
        position: absolute;
    }
    .fullWidth{
        width:100%;
    }
    .UsageMaintenanceMatchingWorksToolbar {
        height: auto;
        width: 100%;
        padding: 0 !important;
      }
      .UsageMaintenanceMatchingWorksToolbar .toolbarWrapper {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #f5f5f5;
        text-align: center;
        gap:12px;
      }