.workBatchInput {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;

    .textInput {
        flex: 1 0 0;
        margin-right: 5%;
        min-width: 100px;
    }

    button {
        border: none;
        flex: 0 0 0;
        white-space: nowrap;
        align-items: center;
        height: 100%;
        padding: 8px 11px;
        margin: 5px;

        &.disabledButton {
            background-color: transparent;
            color: $mediumGray;
        }

        &.enabledButton {
            background-color: $primary-color;
            color: $white;
        }
    }
    
    .buttonText {
        border: none;
        white-space: nowrap;
        font-size: $font__size_small;
        text-align: center;
        width: 100%;
    }

    button[disabled] {
        cursor: default;
    }
}
