.CarouselScrollTabs {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .CarouselContainer {
        width: 95%;
        display: flex;
        flex-direction: row;
        overflow-x: hidden;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
        overflow-y: hidden;
        margin: 0 3px;
        z-index: 0;

        .CarouselContents {
            width: 95%;
            display: flex;
            flex-direction: row;
            overflow-x: auto;
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;
            overflow-y: hidden;
            margin: 0 3px;
        }
    }

    button.buttonContainer {
        width: 35px;
        height: 30px;
        z-index: 2;
        border: none;
        background-color: $white;

        &.leftShadow {
            box-shadow: 2px -1px 2px lightgrey;

            .leftScrollButton {
                width: 15px;
                height: 20px;
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }

        &.rightShadow {
            box-shadow: -2px -1px 2px lightgrey;

            .rightScrollButton {
                width: 15px;
                height: 20px;
            }
        }

        &:focus {
            outline: 0;
        }

        &:active {
            background-color: $primary-color;
        }

        &::-webkit-scrollbar {
            height: 0;
        }
    }
}
