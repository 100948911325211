   /* font */

   .standardText {
    color: #202020;
    font-weight: 400;
    font-family: "montserratregular",sans-serif;
   }

   .overflowEllipsis {
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .fontHeader {
    color: #202020;
    font-family: "montserratmedium", sans-serif;
    font-size: 16px;
    font-weight: 400;
  }