/* top Main nav */
header {
    .navbar {
        border-width: 0;

        .topSection {
            background-color: $white;
            height: 60px;
            @include shadow;

            .navbar-header {
                height: 60px;

                .navbar-brand {
                    height: 100%;
                    padding: 8px 30px 8px 20px;

                    img {
                        height: 100%;
                        width: auto;
                    }
                }

                .navbar-toggle {
                    padding: 9px 5px;
                    width: 40px;
                    height: 40px;
                    margin-top: 10px;
                    margin-right: 0px;

                    .icon-bar {
                        background-color: $primary-color;
                        height: 3px;
                        width: 100%;
                    }
                }
            }

            .navbar-collapse {
                background-color: $white !important;
                max-height: inherit;
                z-index: 1000;

                .navbar-nav {
                    margin: 0 -15px;
                    z-index: 1000;

                    li {
                        background-color: $white;
                        font-size: $font__size_base;

                        a {
                            color: $color__text-main;
                            background-color: $white;
                            display: flex;
                            align-items: center;
                            padding-top: 20px;
                            padding-bottom: 10px;

                            svg {
                                height: 15px;
                                margin: 0 5px 0 0;
                                fill: #38429C;
                            }


                            &:hover {
                                color: $primary-color;
                            }

                            .glyphicon {
                                color: $primary-color;
                                font-size: 16px;
                                margin: 0 5px 0 0;
                            }

                            .icon {
                                color: $primary-color;
                                font-size: 18px;
                                margin: 0 5px 0 0;
                            }

                            &.active {
                                color: $primary-color;
                            }
                        }
                    }

                    li.dropdown {

                        a {
                            background-color: transparent !important;
                            color: $color__text-main;
                            background-image: none !important;
                            z-index: 1000;

                            &:hover {
                                color: $primary-color;
                            }
                        }

                        a.active {
                            color: $primary-color
                        }

                        ul {
                            li {
                                a {
                                    padding: 4px 20px;
                                }
                            }
                        }

                        .divider {
                            background-color: $lighGray;
                        }

                        &:hover > .dropdown-menu {
                            border-radius: 4px;
                            display: block;
                        }
                    }
                }
            }
        }

        .titleSection {
            background-color: $primary-color;
            color: $white;
            height: 45px;
            font-size: 16px;
            line-height: 40px;
            display: flex;
            align-items: center;

            span {
                display: flex;
                align-items: center;
            }

            .glyphicon {
                margin: 0 15px 0 0;
                font-size: 24px;
            }

            .icon {
                margin: 0 15px 0 0;
                font-size: 25px;
            }
        }
    }
}
//Bootstrap OVERRIDE BREAKPOINT COLLAPSE NAVBAR
@media (min-width: 1101px) {
    .navbar-header {
        .navbar-toggle {
            display: none !important;
        }
    }

    .navbar-collapse {
        &.collapse {
            display: block !important;
            height: auto !important;
            padding-bottom: 0;
            overflow: visible !important;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .navbar-header {
        width: 100% !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
        float: none !important;

        .navbar-toggle {
            display: block !important;
        }
    }

    .navbar-collapse {
        background-color: $white !important;
        @include shadow;
        margin-right: -15px !important;
        margin-left: -15px !important;
        padding-right: 15px !important;
        padding-left: 15px !important;
        border-top: 1px solid transparent;

        .navbar-nav {
            float: none !important;

            li {
                float: none !important;

                .dropdown-menu {
                    display: block !important;
                    position: relative !important;
                    box-shadow: none !important;
                    border: none !important;
                    width: 100% !important;
                    padding: 0 0 15px !important;
                }
            }
        }

        &.collapse {
            display: none !important;
            overflow-y: auto !important;

            &.in {
                display: block !important;
            }
        }
    }
}

/* Small Devices, Tablets LOGIN PAGE */
@media only screen and (max-width: 767px) {
    /* /. top Main nav */
    .wrapperLogin {
        padding: 0 1.5rem;
    }
}

.titleSection {
    padding-right: 15px;
    padding-left: 15px;
  }