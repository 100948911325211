.TabsView {
    .tabsDiv {
        padding-top: 0px;
        display: flex;
        flex-direction: row;
        background: transparent;
        /*border-bottom:1px solid $primary-color;*/
        width: 100%;
        overflow-x: auto;

        .tab {
            align-items: center;
            border-radius: 4px 4px 0 0;
            display: flex;
            justify-content: center;
            padding: 5px;
            background-color: $white;
            border-bottom: none;
            margin-right: 10px;
            font-size: 12px;
            white-space: nowrap;
            cursor: pointer;
            @include shadow;

            .removeTabDiv {
                padding-left: 10px;
                font-size: 8px;
                cursor: pointer;

                &.closeDisabled {
                    cursor: progress;
                }
            }

            .tabIconType {
                fill-rule: evenodd;
            }
        }

        .activeTab {
            align-items: center;
            border-radius: 4px 4px 0 0;
            background-color: $primary-color;
            color: $white;
            display: flex;
            justify-content: center;
            padding: 10px 6px;
            margin-right: 10px;
            font-size: 12px;
            white-space: nowrap;
            @include shadow;

            .removeTabDiv {
                padding-left: 10px;
                font-size: 8px;
                cursor: pointer;

                &.closeDisabled {
                    cursor: progress;
                }
            }

            .tabIconType {
                fill: #ffffff;
                fill-rule: evenodd;
            }
        }
    }

    .tab-content {
        .newWorkButton {
            position: absolute;
            right: 20px;
            top: 5px
        }

        .isReadonly {
            cursor: not-allowed;
        }

        .newPaymentRunButton { 
            position: absolute;
            right: 20px;
            top: 5px
        }
    }
}
