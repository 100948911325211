.IconTextButton{
    display: flex;
    align-items: center;
    white-space: normal;
    cursor: pointer;

    .icon {
        font-size: 15px;
        margin-right: 5px;
        color: $primary-color;
    }
    .disabledIcon {
        color: grey;
    } 
    .disabled {
        color: grey;
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: auto;
        font-size: 10px;
        font-weight: bold;
    }
    &.isIconHidden{
        .icon{
           visibility: hidden;
        }
        &:hover{
            .icon{
                visibility: visible;
            }
        }
    }
    &:hover{
        color: $primary-color;
    }
}
@media only screen and (max-width: 768px){
    .IconTextButton{
        white-space: normal;
        margin-right: 4px;
        
    } 
}
