.GridsView{
    flex: 1;
    margin:20px 0 20px;
    .headerContainer{
        flex: 1;
        display: flex;
        flex-direction: row;

        .headerSection{
            flex: 1;
            font-family: $font__medium;
            font-size: $font__size_large;
            padding: 0 0 5px 10px;
            border-bottom:3px solid $color__border;
            cursor: pointer;

            &.activeSection{
                border-bottom:3px solid $primary-color;
            }          
        }
    }
}
.AccordionContainer{
    flex: 1;

    .itemAccordion{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: $font__medium;
        padding: 15px 0 15px 0;
        border-bottom: 1px solid $color__border;
        cursor: pointer;

        .icon{
            margin-right: 8px;

            &.expanded{
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -o-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                transform: rotate(90deg);
            }
        }
        &.activeItem{
            color: $primary-color;
            border-bottom: none;
        }
        .workMessageBanner{
            flex: 1;
            margin-left: 30px;
        }
    }
    .contentAccordion{
        margin: 0 0 20px 0;
        border-bottom: 1px solid $color__border;
        width: 100%;
        padding: 0 0 15px;

        .tableContainer{
            margin-bottom: 5px;
            margin-top: 5px;
        }
    }

    .contentAccordionAgreement{
        margin: 0 15px 20px 15px;
        border-bottom: 1px solid $color__border;
        max-width: 100%;
        padding: 0 0 15px;

        .tableContainer{
            margin-bottom: 5px;
            margin-top: 5px;
        }
    }
}