@font-face {
    font-family: 'montserratbold';
    src: url('styles/fonts/montserrat-bold-webfont.woff2') format('woff2'),
         url('styles/fonts/montserrat-bold-webfont.woff') format('woff');
    font-style: normal;
}

@font-face {
    font-family: 'montserratmedium';
    src: url('styles/fonts/montserrat-medium-webfont.woff2') format('woff2'),
         url('styles/fonts/montserrat-medium-webfont.woff') format('woff');
    font-style: normal;
}

@font-face {
    font-family: 'montserratregular';
    src: url('styles/fonts/montserrat-regular-webfont.woff2') format('woff2'), 
         url('styles/fonts/montserrat-regular-webfont.woff') format('woff');
    font-style: normal;
}

$font__body:'montserratregular', sans-serif;
$font__medium:'montserratmedium', sans-serif;
$font__bold:'montserratbold', sans-serif;

$font__size_xsmall:10px;
$font__size_small:12px;
$font__size_base:13px;
$font__size_large:16px;
$font__size_xlarge:20px;

$font__line-height-body: 1.2;

.title{
    display:block;
    font-family:$font__bold;
    font-size: $font__size_xlarge;
    margin: 0 0 1rem;

}

.subtitle {
    display:block;
    font-family:$font__medium;
    font-size: $font__size_xlarge;
    margin: 2rem 0 2rem;
}
.heading{
    font-family:$font__bold;
    margin: 0 0 15px;
}
h1{
    font-size:18px;
    font-family: $font__medium;
}
h3{
    font-family:$font__medium;
    font-size: $font__size_large;
}

.text-danger {
    font-size:$font__size_xsmall;
    margin: 5px 0 0 0;
}
