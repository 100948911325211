div.ms-ComboBox {
    background-color: $white;
    input, input[type="text"] {
        background-color: $white;
        border: none !important;
    }
    height: 38px !important;
}
div.ms-ComboBox:after {
    border: 1px solid #e5e5e5 !important;
}

td { div { div { div { div.ms-ComboBox {
    button.ms-Button  {
        position: relative !important;
        padding: 14px !important;
    }
}}}}}