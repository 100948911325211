﻿.workflowInfo {
    display: inline-flex;
    margin: 30px 0 10px;

    .icon {
        color: #ff9800;
        margin-right: 10px;
        font-size: 20px;
        border-left: 3px solid;
        padding-left: 10px;
    }

    span:last-of-type {
        flex-grow: 1;
    }

    .workflowLabel {
        font-family: "montserrat_mediumregular", sans-serif;
        margin-bottom: 5px !important;
    }

    .row {
        margin-bottom: -20px;
    }

    .col-sm-12 {
        margin-bottom: -10px;
    }

    .date {
        float: left;
        width: revert;
    }

    .assinged {
        float: left;
        width: revert;
    }

    .message {
        float: left;
        width: revert;
    }    
}

.col-md-12 .row-workflow {
    .arrowIconExpandDiv {
        display: flex;
        position: absolute;
        margin-left: 5px;
        margin-top: 8px;
    }

    .arrowIconExpandText {
        margin-left: 6px;
        overflow-wrap: break-word;
    }

    .arrowIconExpandAll {
        height: 16px;
        width: 16px;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }

    .arrowIconExpandAllExpanded {
        height: 16px;
        width: 16px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .arrowIconExpandAllExpanded:hover,
    .arrowIconExpandAll:hover {
        cursor: pointer;
    }
}

.col-md-12 .row-workflow-empty{
    margin-bottom:15px;
}

.selectAllCheckBox{
    transform: rotate(0deg) !important
}

.selectAllCheckBoxText{
    margin-top: 3px !important;
}

