.alert{
	border-width: 0 0 0 6px;
	border-style: solid;
	@include shadowinhover;
	background-color: $white;
	background-image: none;
	margin-top: 8px;
	white-space: pre-wrap;

	.alert-content{
		display: flex;

		.icon{
			font-size: $font__size_xlarge;
			margin: 0 10px 0 0;

			&.info{
				color:$secondary-color !important;

			}
			&.info{
				color:$secondary-color !important;

			}
			&.sucess{
				color:$color-alert-success;
			}
			&.danger{
				color:$color-alert-danger;
			}
			&.warning{
				color:$color-alert-warning;
			}
		}
		.text{
			margin-top:4px;
			ul{
				margin: 10px 0 10px 0;
				padding:0 0 0 25px;
				li{
					margin: 0 0 4px 0;

					&:last-child{
						margin:0;
					}
				}
			}
		}
	}
	&-info{
		border-color:$secondary-color; 
		color:$color__text-main;
	}
	&-success{
		border-color:$color-alert-success;
		color:$color__text-main;
	}
	&-danger{
		border-color:$color-alert-danger;
		color:$color__text-main;
	}
	&-warning{
		border-color:$color-alert-warning;
		color:$color__text-main;
	}
	
	
}