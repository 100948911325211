
/* Shadows */
@mixin shadow {
    -webkit-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, .132), 0 .6px 1.8px 0 rgba(0, 0, 0, .108);
    -moz-box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, .132), 0 .6px 1.8px 0 rgba(0, 0, 0, .108);
    box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, .132), 0 .6px 1.8px 0 rgba(0, 0, 0, .108);
}

@mixin shadowinhover {
    -webkit-box-shadow: 0px 9px 27px -6px rgba(0, 0, 0, .4);
    -moz-box-shadow: 0px 9px 27px -6px rgba(0, 0, 0, .4);
    box-shadow: 0px 9px 27px -6px rgba(0, 0, 0, .4);
}

@mixin btnTransition {
    -webkit-transition: all 0.3s ease; /* Safari */
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

@mixin bkgTransition {
    -webkit-transition: all 0.3s ease; /* Safari */
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}
