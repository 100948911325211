.searchView {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;

    .searchFieldsGrid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        column-gap: 25px;
        row-gap: 10px;
        width: 100%;
    }

    .searchFieldsGridUsageModal {
        width: 100%;

        .row {
            margin-left: 20px;
        }
    }

    .headerSection {
        flex: 1 1;
        font-family: "montserratmedium", sans-serif;
        font-size: 18px;
        padding: 10px 0 5px 15px;
        margin-bottom: 10px;
        border-bottom: 3px solid #2e6bcd;

    }

    .searchButton {
        width: 250px;
        height: 28px;
        margin-bottom: 25px;
        color: white;
        margin-top: 25px;
        outline: none;
        align-self: flex-end;
        font-size: 12px;
        border: none;
        background-color: var(--primaryColor);
    }

    .searchButton:hover {
        -webkit-transition: all 0.3s;
        /* Safari */
        transition: all 0.3s;
        -webkit-box-shadow: 0px 9px 27px -6px rgba(43, 43, 43, 0.42);
        -moz-box-shadow: 0px 9px 27px -6px rgba(43, 43, 43, 0.42);
        box-shadow: 0px 9px 27px -6px rgba(43, 43, 43, 0.42);
    }

    .searchResultsDiv {
        display: flex;
        margin-top: 10px;
        width: 100%;
        overflow: hidden;

        .tr .rowExpanded {
            max-width: 500px;
        }

        .tr .rowUnexpanded {
            max-width: 500px;
            height: 18px;
            overflow: hidden;
        }
    }

    .centerTextNotFound {
        text-align: center;
        margin-top: 30px;
    }

    @media only screen and (max-width: 768px) {
        .searchButton {
            height: 20px;
            width: 30%;
            min-width: 120px;
            font-size: calc(6px + 1vw);
        }
    }
}
